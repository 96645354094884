import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import ClearIcon from '@material-ui/icons/Clear';
import timeUtils from '../../utils/timeUtils';
import styles from './CutSlider.module.scss';
import IconButton from '../buttons/IconButton';
import { logMessage } from '../../utils/logUtils';

const msInSec = 1000;

function valuetext(value) {
  return timeUtils.formatDuration(value * msInSec);
}

const CutSlider = ({
  max,
  type,
  initialValue,
  onChange,
  onDeleteCut
}) => {
  const [value, setValue] = React.useState(initialValue);
  // TODO: Phase2: Translate
  const label = (type === 'cut') ? 'Cut' : 'Trim';
  const track = (type === 'cut') ? 'normal' : 'inverted';
  const classes = (type === 'cut') ? {
    root: styles.sliderRoot,
    rail: styles.rail,
    track: styles.track,
    thumb: styles.thumb
  } : {};

  const handleChangeCommitted = (event, newValue) => {
    logMessage('handleChangeCommitted()', event.target);
    let currentElement = event.target;

    // Find what value the user touched, to scroll the video
    let valueTouched;
    try {
      /*
      TODO: Phase2: This is quite brittle since it goes up the DOM looking for any
       element with ariaValueNow. Refactor so it 1) looks at the class? of the
       element with ariaValueNow and 2) looks at the class of the root slider
       container and doesn't go up the tree beyond that.
       */
      while (currentElement && !currentElement.ariaValueNow) {
        currentElement = currentElement.parentElement;
      }
      valueTouched = currentElement
        ? Number(currentElement.ariaValueNow)
        : null;
    } catch (err) {
      valueTouched = null;
    }

    onChange(newValue, valueTouched);
  };

  return (
    <div>
      <Typography
        id="range-slider"
        gutterBottom
        classes={{ root: styles.label }}
      >
        <span>{label}</span>
        {(type === 'cut' && (
          <IconButton
            onClick={onDeleteCut}
            titleId="cutRemove"
            icon={ClearIcon}
            size="small"
            padding="none"
            className={styles.deleteButton}
          />
        ))}
      </Typography>
      <Slider
        value={value}
        max={max}
        track={track}
        onChange={(event, newValue) => setValue(newValue)}
        onChangeCommitted={handleChangeCommitted}
        valueLabelDisplay="on"
        aria-labelledby="range-slider"
        getAriaValueText={valuetext}
        valueLabelFormat={valuetext}
        classes={classes}
      />
    </div>
  );
};

CutSlider.propTypes = {
  type: PropTypes.oneOf(['trim', 'cut']).isRequired,
  max: PropTypes.number.isRequired,
  initialValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
  onDeleteCut: PropTypes.func
};

CutSlider.defaultProps = {
  onDeleteCut: null
};

export default CutSlider;
