import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { localeFrCa } from '../../constants';
import styles from './TextLink.module.scss';

const TextLink = ({
  labelId,
  src,
  srcFr,
  className,
  newTab,
  locale
}) => (
  <a
    href={(locale === localeFrCa && srcFr) ? srcFr : src}
    className={`${styles.link} ${className}`}
    target={newTab ? '_blank' : undefined}
    rel={newTab ? 'noopener noreferrer' : undefined}
  >
    <FormattedMessage id={labelId} />
  </a>
);

TextLink.propTypes = {
  labelId: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  srcFr: PropTypes.string,
  className: PropTypes.string,
  newTab: PropTypes.bool,
  locale: PropTypes.string.isRequired
};

TextLink.defaultProps = {
  srcFr: undefined,
  className: '',
  newTab: undefined
};

const mapStateToProps = (state) => ({
  locale: state.locale.locale
});

export default connect(mapStateToProps, null)(TextLink);
