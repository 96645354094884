import React from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import { intlType } from '../types';
import InlineError from '../components/error/InlineError';
import styles from './NotFound.module.scss';

const NotFound = ({ intl }) => (
  <>
    <Helmet>
      <title>{intl.formatMessage({ id: 'pageNotFound' })}</title>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    <div className={styles.container}>
      <InlineError messageId="pageNotFound" />
    </div>
  </>
);

NotFound.propTypes = {
  intl: intlType.isRequired
};

export default injectIntl(NotFound);
