import React from 'react';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { userContainerType } from '../../types';
import { isUserSignedIn } from '../../utils/userUtils';
import SignOutDialog from './SignOutDialog';
import styles from './SignOutButton.module.scss';

const SignOutButton = ({ userContainer, className }) => {
  const isSignedIn = isUserSignedIn(userContainer);
  const [open, setOpen] = React.useState(false);

  if (!isSignedIn) {
    return null;
  }

  return (
    <>
      <Button
        className={className}
        classes={{ root: styles.container }}
        type="button"
        onClick={() => setOpen(true)}
      >
        <FormattedMessage id="userSignOut" />
      </Button>

      <SignOutDialog
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

SignOutButton.propTypes = {
  userContainer: userContainerType.isRequired,
  className: PropTypes.string
};

SignOutButton.defaultProps = {
  className: ''
};

const mapStateToProps = (state) => ({
  userContainer: state.user
});

export default connect(mapStateToProps, null)(SignOutButton);
