import videoService from '../services/videoService';
import {
  VIDEO_FETCH,
  VIDEO_FETCH_FAILURE,
  VIDEO_FETCH_SUCCESS,
  VIDEO_SIMILAR_FETCH,
  VIDEO_SIMILAR_FETCH_FAILURE,
  VIDEO_SIMILAR_FETCH_SUCCESS,
  VIDEO_UPDATE_SUCCESS,
  VIDEO_UPDATE_FAILURE,
  VIDEO_UPDATE,
  VIDEO_REQUEST_ACCESS,
  VIDEO_REQUEST_ACCESS_SUCCESS,
  VIDEO_REQUEST_ACCESS_FAILURE,
  VIDEO_EXTEND,
  VIDEO_EXTEND_REQUESTED,
  VIDEO_EXTEND_SUCCESS,
  VIDEO_EXTEND_FAILURE,
  VIDEO_EXTEND_CANCEL,
  VIDEO_EXTEND_COMPLETE
} from '../actionTypes';

const fetchVideoStart = (videoId) => ({
  type: VIDEO_FETCH,
  payload: {
    videoId
  }
});

const fetchVideoSuccess = (video) => ({
  type: VIDEO_FETCH_SUCCESS,
  payload: {
    video
  }
});

const fetchVideoFailure = (error) => ({
  type: VIDEO_FETCH_FAILURE,
  payload: {
    error
  }
});

export const fetchVideo = (videoId) => (dispatch) => {
  dispatch(fetchVideoStart(videoId));
  videoService.fetchVideo(videoId)
    .then((results) => dispatch(fetchVideoSuccess(results)))
    .catch((err) => dispatch(fetchVideoFailure(err)));
};

const updateVideoStart = () => ({
  type: VIDEO_UPDATE,
  payload: {}
});

const updateVideoSuccess = (video) => ({
  type: VIDEO_UPDATE_SUCCESS,
  payload: {
    video
  }
});

const updateVideoFailure = (error) => ({
  type: VIDEO_UPDATE_FAILURE,
  payload: {
    error
  }
});

export const updateCurrentVideo = () => (dispatch, getState) => {
  const { video } = getState();
  if (!video || !video.currentVideo || video.isUpdating) {
    return;
  }
  const videoId = video.currentVideo.id;
  dispatch(updateVideoStart());
  videoService.fetchVideo(videoId)
    .then((results) => dispatch(updateVideoSuccess(results)))
    .catch((err) => dispatch(updateVideoFailure(err)));
};

const fetchSimilarStart = (videoId) => ({
  type: VIDEO_SIMILAR_FETCH,
  payload: {
    videoId
  }
});

const fetchSimilarSuccess = (videoId, list) => ({
  type: VIDEO_SIMILAR_FETCH_SUCCESS,
  payload: {
    videoId,
    list
  }
});

const fetchSimilarFailure = (videoId, error) => ({
  type: VIDEO_SIMILAR_FETCH_FAILURE,
  payload: {
    videoId,
    error
  }
});

export const fetchSimilar = (videoId) => (dispatch) => {
  dispatch(fetchSimilarStart(videoId));
  videoService.fetchSimilar(videoId)
    .then((results) => dispatch(fetchSimilarSuccess(videoId, results.value)))
    .catch((err) => dispatch(fetchSimilarFailure(videoId, err)));
};

const requestAccessStart = (videoId) => ({
  type: VIDEO_REQUEST_ACCESS,
  payload: {
    videoId
  }
});

const requestAccessSuccess = (videoId) => ({
  type: VIDEO_REQUEST_ACCESS_SUCCESS,
  payload: {
    videoId
  }
});

const requestAccessFailure = (videoId, error) => ({
  type: VIDEO_REQUEST_ACCESS_FAILURE,
  payload: {
    videoId,
    error
  }
});

const extendStart = (videoId) => ({
  type: VIDEO_EXTEND,
  payload: {
    videoId
  }
});

const extendRequested = (videoId) => ({
  type: VIDEO_EXTEND_REQUESTED,
  payload: {
    videoId
  }
});

const extendSuccess = (videoId) => ({
  type: VIDEO_EXTEND_SUCCESS,
  payload: {
    videoId
  }
});

const extendFailure = (videoId, error) => ({
  type: VIDEO_EXTEND_FAILURE,
  payload: {
    videoId,
    error
  }
});

const extendCancel = (videoId, error) => ({
  type: VIDEO_EXTEND_CANCEL,
  payload: {
    videoId,
    error
  }
});

const extendComplete = (videoId, error) => ({
  type: VIDEO_EXTEND_COMPLETE,
  payload: {
    videoId,
    error
  }
});

export const requestAccess = (videoId) => (dispatch) => {
  dispatch(requestAccessStart(videoId));
  videoService.requestAccess(videoId)
    .then(() => dispatch(requestAccessSuccess(videoId)))
    .catch((err) => dispatch(requestAccessFailure(videoId, err)));
};

export const extendRequestStart = (videoId) => (dispatch) => {
  dispatch(extendRequested(videoId));
};

export const extendRequestCancel = (videoId) => (dispatch) => {
  dispatch(extendCancel(videoId));
};

export const extendRequestComplete = (videoId) => (dispatch) => {
  dispatch(extendComplete(videoId));
};

export const extend = (videoId) => (dispatch) => {
  dispatch(extendStart(videoId));
  videoService.extend(videoId)
    .then(() => dispatch(extendSuccess(videoId)))
    .catch((err) => dispatch(extendFailure(videoId, err)));
};
