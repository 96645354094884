import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingWrapper from '../loading/LoadingWrapper';
import { searchResultsType } from '../../types';
import { executeSearch, searchVideoNextPage } from '../../store/actions/searchActions';
import VideoCard from '../videos/VideoCard';

// TODO: Set key properly
// Infinite Scroll Component: https://www.npmjs.com/package/react-infinite-scroll-component
const SearchResultsList = ({
  searchResults,
  executeSearchAction,
  searchVideoNextPageAction
}) => (
  <InfiniteScroll
    dataLength={searchResults.loadedCount}
    next={searchVideoNextPageAction}
    hasMore={searchResults.loadedCount < searchResults.count}
    refreshFunction={executeSearchAction}
    pullDownToRefresh
    pullDownToRefreshThreshold={50}
    pullDownToRefreshContent={
      <div>&nbsp;</div>
    }
    releaseToRefreshContent={
      <div>&nbsp;</div>
    }
  >
    {searchResults.pages.map((page, index) => (
      // TODO: The div below is a hack to defeat the children prop being required. Fix properly.
      // eslint-disable-next-line react/no-array-index-key
      <LoadingWrapper key={index} isLoading={page.isLoading} error={page.error} errorMessageId="searchError">
        {page.list ? page.list.map((video) => (
          <VideoCard
            key={video.id}
            video={video}
            displayStyle="list"
          />
        )) : (<div>&nbsp;</div>)}
      </LoadingWrapper>
    ))}
  </InfiniteScroll>
);

SearchResultsList.propTypes = {
  searchResults: searchResultsType.isRequired,
  searchVideoNextPageAction: PropTypes.func.isRequired,
  executeSearchAction: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  searchVideoNextPageAction: searchVideoNextPage,
  executeSearchAction: executeSearch
};

export default connect(null, mapDispatchToProps)(SearchResultsList);
