import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { fetchUser } from 'store/actions/userActions';
import { userContainerType } from '../types';
import urlUtils from '../utils/urlUtils';
import Loading from '../components/loading/Loading';
import InlineError from '../components/error/InlineError';
import styles from './LoginCallback.module.scss';

/*
 * - After a successful login this is called with the actual Auth Token in the URL parameter
 * - It then uses the token to fetch the user details, set them in state, and redirect to root
 * - All subsequent MT requests will include this token in the header
 */
class LoginCallback extends Component {
  componentDidMount() {
    const { token } = this.props;
    const { fetchUserAction } = this.props;
    if (token) {
      fetchUserAction(token);
    }
  }

  render() {
    const {
      user,
      appState,
      error,
      errorDescription,
      token
    } = this.props;

    const redirectToPreviousState = () => {
      // appState holds url where user was pre login
      const redirectUrl = appState ? atob(appState) : '/';
      return <Redirect to={redirectUrl} />;
    };

    if (error === 'user_cancelled_login') {
      return redirectToPreviousState();
    }
    if (!token) {
      const tokenError = (errorDescription)
        ? new Error(errorDescription)
        : new Error('Missing token in callback.');
      return (
        <div className={styles.container}>
          <InlineError messageId="signInError" error={tokenError} />
        </div>
      );
    }

    if (!user.isLoading && user.user) {
      return redirectToPreviousState();
    }

    return (
      <>
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <div className={styles.container}>
          <Loading />
        </div>
      </>
    );
  }
}

LoginCallback.propTypes = {
  fetchUserAction: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  appState: PropTypes.string,
  error: PropTypes.string,
  errorDescription: PropTypes.string,
  user: userContainerType
};

LoginCallback.defaultProps = {
  appState: null,
  error: null,
  errorDescription: null,
  user: null
};

const mapStateToProps = (state, ownProps) => {
  const { user } = state;
  const { location } = ownProps;
  const urlParams = urlUtils.parseParams(location.search);
  const {
    token,
    appstate,
    error,
    // eslint-disable-next-line camelcase
    error_description
  } = urlParams;

  return {
    user,
    token,
    appState: appstate,
    error,
    errorDescription: error_description
  };
};

const mapDispatchToProps = {
  fetchUserAction: fetchUser
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginCallback);
