import React from 'react';
import PropTypes from 'prop-types';
import { allSortOptions } from 'constants/index';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { searchVideoOrder } from 'store/actions/searchActions';
import { searchParamsType } from '../../types';
import styles from './SortOptions.module.scss';

const SortOptions = ({ searchParams, searchVideoOrderAction }) => {
  const history = useHistory();
  const defaultValue = 'default';

  const handleChange = (event) => {
    const fieldName = (event.target.value === defaultValue) ? null : event.target.value;
    searchVideoOrderAction(fieldName, history);
  };

  return (
    <FormControl classes={{ root: styles.container }}>
      <InputLabel id="sortByLabel">
        <FormattedMessage id="sortBy" />
      </InputLabel>
      <Select
        id="sortBySelect"
        value={searchParams.order || defaultValue}
        onChange={handleChange}
        labelId="sortByLabel"
        inputProps={{
          'aria-labelledby': 'sortByLabel'
        }}
      >
        {allSortOptions.map((option) => (
          <MenuItem key={option.labelId} value={option.fieldName || defaultValue}>
            <FormattedMessage id={option.labelId} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SortOptions.propTypes = {
  searchParams: searchParamsType.isRequired,
  searchVideoOrderAction: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { search } = state;
  return {
    searchParams: search.searchParams
  };
};

const mapDispatchToProps = {
  searchVideoOrderAction: searchVideoOrder
};

export default connect(mapStateToProps, mapDispatchToProps)(SortOptions);
