import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import urlUtils from '../utils/urlUtils';
import { matchType, userContainerType } from '../types';
import SignInDialog from '../components/user/SignInDialog';
import LoadingWrapper from '../components/loading/LoadingWrapper';
import { addChannelToProfile, guestSignIn } from '../store/actions/userActions';
import { isUserSignedIn } from '../utils/userUtils';
import { logError, logMessage } from '../utils/logUtils';
import styles from './Share.module.scss';

/*
Share Flow
- When video is shared, link to this page.
- Option 1: View as Guest
- Option 2: Log In + Add channel to profile

Producer / Admin:
- GET /channel/[id]
- Returns channel details including a secret “code”
- Build a link to the FE with that code in the URL (link to this page)

Sharee:
- Open that link.
- Option 1: View as guest:
  - Switch channel code to temp token (use the token as as you do now for the “real” tokens - no code changes).
- Option 2: View as Logged In User:
  - Log in
  - Call API with the channel code - it will add channel to my profile

TODO: P1: Test how the share link will behave if a video associated to a channel becomes public
 (looses channel association). The old share link should still work.
 */
const Share = ({
  code,
  user,
  match,
  addChannelToProfileAction,
  guestSignInAction
}) => {
  const history = useHistory();
  const { videoId } = match.params;

  if (!code) {
    logError('Share: Missing code. Redirecting to video page.');
    return <Redirect to={`/videos/${videoId}`} />;
  }

  /*
  TODO: P1: This gets called twice. Problem is in addChannelToProfileAction()
  - Channel is added to profile
  - User object is updated
  - This component reloads with the new user object
  - The logic below is triggered again
   */
  if (isUserSignedIn(user)) {
    logMessage(`Adding share to user. Code: ${code}`);
    addChannelToProfileAction(code, videoId, history);
  }

  const handleGuestSignIn = () => {
    logMessage(`Signing in as guest. Code: ${code}`);
    guestSignInAction(code, videoId, history);
  };

  const { isLoading, error } = user;
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className={styles.container}>
        <div className={styles.playerSection}>
          <LoadingWrapper isLoading={isLoading} error={error} errorMessageId="shareViewFailed">
            <SignInDialog open={!user.user || user.user.isGuest} onGuestSignIn={handleGuestSignIn} privateWebcast />
          </LoadingWrapper>
        </div>
      </div>
    </>
  );
};

Share.propTypes = {
  addChannelToProfileAction: PropTypes.func.isRequired,
  guestSignInAction: PropTypes.func.isRequired,
  match: matchType.isRequired,
  code: PropTypes.string.isRequired,
  user: userContainerType
};

Share.defaultProps = {
  user: null
};

const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps;
  const urlParams = urlUtils.parseParams(location.search);
  const { code } = urlParams;
  const { user } = state;

  return {
    code,
    user
  };
};

const mapDispatchToProps = {
  addChannelToProfileAction: addChannelToProfile,
  guestSignInAction: guestSignIn
};

export default connect(mapStateToProps, mapDispatchToProps)(Share);
