import { apiUrls, defaultPageSize } from 'constants/index';
import { apiGet } from './apiService';
import searchUtils from '../../utils/searchUtils';
import { logMessage } from '../../utils/logUtils';

/*
API Notes:
- ?search=<keyword>&filter=presenters|Dr. Jones|Dr. Smith&order=scheduledStart
 - filter format is <fieldName>|value,<fieldName>|value,<fieldName|Value|Value|etc>
 - you also have a page and pageSize parameter to let you control the paging of the results

New (Sept 16):
- scheduledStartMin and scheduledStartMax are now available to use for a date range
- scheduledStart/end can be used to match specific dates
- All these will need to be added to the filters param
 */

const fetchSuggestions = (query) => {
  logMessage(`fetchSuggestions() called with query: ${query}`);

  const urlParams = {
    suggest: query
  };

  return apiGet(`${apiUrls.root}video`, urlParams);
};

const searchVideo = (searchParams, page = 0, pageSize = defaultPageSize) => {
  logMessage(`searchVideo() called with searchParams: ${JSON.stringify(searchParams)}, page: ${page}, pageSize: ${pageSize}`);
  const {
    query,
    filters,
    order,
    queryType
  } = searchParams;

  const urlParams = {
    query,
    queryType,
    filters: searchUtils.filtersObjectToUrl(filters) || undefined,
    order,
    page,
    pageSize
  };

  return apiGet(`${apiUrls.root}video`, urlParams);
};

export default {
  fetchSuggestions,
  searchVideo
};
