import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { suggestionsContainerType } from 'types';
import { localeEnCa } from 'constants/index';
import { injectIntl } from 'react-intl';
import VideoSection from './VideoSection';

const HotTopics = ({ suggestionsContainer, locale, intl }) => {
  const { hotTopics } = suggestionsContainer;

  if (!hotTopics || !hotTopics.list) {
    return null;
  }
  return hotTopics.list.map((topic) => {
    const videoContainer = suggestionsContainer[`hotTopic_${topic.id}`];
    if (!videoContainer) {
      return null;
    }

    const isEnglish = (locale === localeEnCa);
    const title = ((isEnglish) ? topic.name_en : topic.name_fr);
    const heading = intl.formatMessage({ id: 'hotTopicsPrefix' }, { title });

    return (
      <VideoSection
        key={topic.id}
        heading={heading}
        videoContainer={videoContainer}
        hideIfEmpty
      />
    );
  });
};

HotTopics.propTypes = {
  suggestionsContainer: suggestionsContainerType,
  locale: PropTypes.string.isRequired
};

HotTopics.defaultProps = {
  suggestionsContainer: {}
};

const mapStateToProps = (state) => ({
  locale: state.locale.locale
});

export default connect(mapStateToProps, {})(injectIntl(HotTopics));
