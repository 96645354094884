import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  FormLabel,
  FormControl
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '../buttons/Button';
import styles from './FilterContainer.module.scss';

const FilterContainer = ({
  fieldName,
  defaultExpanded,
  showApply,
  onApply,
  children
}) => {
  const [isExpanded, setExpanded] = React.useState(defaultExpanded);
  const [isTouched, setTouched] = React.useState(false);

  useEffect(() => {
    if (defaultExpanded && !isTouched) {
      setExpanded(true);
    }
  });

  return (
    <FormControl component="fieldset" aria-labelledby={`${fieldName}-filter-header`} className={styles.container}>
      <Accordion
        square
        classes={{ root: styles.accordionRoot }}
        expanded={isExpanded}
        onChange={
          (event, expanded) => {
            setExpanded(expanded);
            setTouched(true);
          }
        }
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${fieldName}-filter-content`}
          id={`${fieldName}-filter-header`}
        >
          <FormLabel component="legend">
            <FormattedMessage id={`searchFilter_${fieldName}`} />
          </FormLabel>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup classes={{ root: styles.formGroup }}>
            {children}
            {showApply && (
              <Button onClick={onApply} labelId="apply" />
            )}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    </FormControl>
  );
};

FilterContainer.propTypes = {
  fieldName: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  defaultExpanded: PropTypes.bool,
  showApply: PropTypes.bool,
  onApply: PropTypes.func
};

FilterContainer.defaultProps = {
  defaultExpanded: false,
  showApply: false,
  onApply: () => {}
};

export default FilterContainer;
