import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styles from './ResultsCount.module.scss';
import Text from '../containers/Text';
import { maxLength } from '../../constants';

const ResultsCount = ({ count, query }) => {
  if (count === null || count === undefined) {
    return null;
  }

  let message;
  if (query && count === 1) {
    message = (
      <FormattedMessage
        id="searchResultsCountForQuerySingular"
        values={{
          count,
          query: <Text value={query} maxLength={maxLength.searchQuery} />
        }}
      />
    );
  } else if (query && count !== 1) {
    message = (
      <FormattedMessage
        id="searchResultsCountForQuery"
        values={{
          count,
          query: <Text value={query} maxLength={maxLength.searchQuery} />
        }}
      />
    );
  } else {
    message = (
      <FormattedMessage
        id="searchResultsCount"
        values={{
          count
        }}
      />
    );
  }

  return (<h1 className={styles.container}>{message}</h1>);
};

ResultsCount.propTypes = {
  count: PropTypes.number,
  query: PropTypes.string
};

ResultsCount.defaultProps = {
  count: null,
  query: null
};

export default ResultsCount;
