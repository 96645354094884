import React from 'react';
import PropTypes from 'prop-types';
import ResultsCount from './ResultsCount';
import SortOptions from './SortOptions';
import styles from './SearchResultsHeader.module.scss';

const SearchResultsHeader = ({ resultsCount, searchQuery }) => (
  <div className={styles.container}>
    <div className={`${styles.countContainer}`}>
      <ResultsCount count={resultsCount} query={searchQuery} />
    </div>
    <SortOptions />
  </div>
);

SearchResultsHeader.propTypes = {
  resultsCount: PropTypes.number,
  searchQuery: PropTypes.string
};

SearchResultsHeader.defaultProps = {
  resultsCount: null,
  searchQuery: null
};

export default SearchResultsHeader;
