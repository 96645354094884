import React from 'react';
import { MenuItem, Select, InputLabel } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { setLocale } from 'store/actions/localeActions';
import PropTypes from 'prop-types';
import Tooltip from '../containers/Tooltip';
import { locales } from '../../constants';
import styles from './LanguageSelector.module.scss';
import ConditionalWrapper from '../utils/ConditionalWrapper';

const LanguageSelector = ({
  locale,
  theme,
  setLocaleAction,
  className,
  showTooltip
}) => {
  const [isTooltipOpen, setTooltipOpen] = React.useState(false);

  const handleSelectLanguage = (event) => {
    const language = event.target.value;
    setLocaleAction(language);
  };

  const handleOpenTooltip = () => {
    setTooltipOpen(true);
  };

  const handleCloseTooltip = () => {
    setTooltipOpen(false);
  };

  return (
    <div className={className}>
      <InputLabel id="changeLanguageLabel" className={styles.label}>
        <FormattedMessage id="changeLanguage" />
      </InputLabel>
      <ConditionalWrapper
        condition={showTooltip}
        wrapper={(children) => (
          <Tooltip titleId="changeLanguage" open={isTooltipOpen}>{children}</Tooltip>
        )}
      >
        <Select
          id="changeLanguageSelect"
          disableUnderline
          value={locale}
          onChange={handleSelectLanguage}
          onMouseEnter={handleOpenTooltip}
          onMouseLeave={handleCloseTooltip}
          onOpen={handleCloseTooltip}
          classes={{
            root: (theme === 'dark') ? styles.dark : styles.normal,
            icon: (theme === 'dark') ? styles.dark : styles.normal
          }}
          labelId="changeLanguageLabel"
          inputProps={{
            'aria-labelledby': 'changeLanguageLabel'
          }}
        >
          {locales.map((option) => (
            <MenuItem key={option.code} value={option.code}>
              <FormattedMessage id={option.nameId} />
            </MenuItem>
          ))}
        </Select>
      </ConditionalWrapper>
    </div>
  );
};

LanguageSelector.propTypes = {
  setLocaleAction: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(['normal', 'dark']),
  className: PropTypes.string,
  showTooltip: PropTypes.bool
};

LanguageSelector.defaultProps = {
  theme: 'normal',
  className: '',
  showTooltip: true
};

const mapStateToProps = (state) => ({
  locale: state.locale.locale
});

export default connect(mapStateToProps, { setLocaleAction: setLocale })(LanguageSelector);
