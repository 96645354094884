import React, { Component } from 'react';
import PropTypes from 'prop-types';

/*
TODO: This is a modified version of this library: https://github.com/SidKH/react-azure-mp
 If this works as a POC, consider where to place this library.

Changes:
- Fixed ESLint errors
- Added propTypes, defaultProps
- Removed "adaptRatio" prop
- Added onDurationChanged prop
- Added currentTime prop
- Added AzureMP GA plugin
- Changed width/height to relative, not absolute
- Removed onInstanceCreated - not very Reactful to return an instance of the player to the parent. Should be controlled via props only.
 */
class AzureMP extends Component {
  constructor(props) {
    super(props);
    this.videoNode = React.createRef();
  }

  /*
  TODO: This used to call "loadLibrary" to dynamically load dependencies (amp js, css files). This is no longer used
   since there I could not find a way to dynamically load the amp dependencies (from CDN) and the amp plugin dependency
   locally, in that order, so I moved the dependencies to index.html. Ideally, all those dependencies need to be
   lazy-loaded here.
   */
  componentDidMount() {
    if (window.amp) {
      this.createPlayer();
      this.setVideo();
    } else {
      console.error('Azure MP not loaded!');
    }

    if (!window.ga) {
      console.error('Google Analytics not loaded!');
    }
  }

  componentDidUpdate(prevProps) {
    const { src, currentTime } = this.props;
    if (src && prevProps.src !== src) {
      this.setVideo();
    } else if (prevProps.currentTime !== currentTime) {
      this.player.currentTime(currentTime);
    }
  }

  componentWillUnmount() {
    this.destroyPlayer();
  }

  setVideo() {
    const { src, textTracks } = this.props;
    if (this.player) {
      this.player.src(src, textTracks);
    }
  }

  createPlayer() {
    const {
      language,
      options,
      onStart,
      onResume,
      onError,
      onPlaybackState,
      onFullScreenState,
      onDurationChanged,
      onTimeUpdate,
      timeUpdateIntervalSec
    } = this.props;
    const defaultOptions = {
      autoplay: false,
      controls: true,
      language,
      logo: { enabled: false },
      width: '100%',
      height: '300px',
      plugins: {
        ga: {
          eventsToTrack: ['playerConfig', 'loaded', 'playTime', 'percentsPlayed', 'start', 'end', 'play', 'pause', 'error', 'buffering', 'fullscreen', 'seek', 'bitrate'],
          debug: false
        }
      }
    };

    this.player = window.amp(
      this.videoNode.current,
      {
        ...defaultOptions,
        ...options
      }
    );

    // All AMP events: https://amp.azure.net/libs/amp/latest/docs/index.html#static-amp.eventname.firstquartile

    // Start only called once on the first play, not called if video is resumed after pause
    this.player.addEventListener(window.amp.eventName.start, onStart);

    this.player.addEventListener(window.amp.eventName.resume, onResume);

    this.player.addEventListener(window.amp.eventName.error, () => onError(this.player.error()));

    const playbackStates = ['firstquartile', 'midpoint', 'thirdquartile', 'ended'];
    playbackStates.forEach((state) => {
      this.player.addEventListener(window.amp.eventName[state], () => onPlaybackState(state, this.player.currentTime()));
    });

    const fullScreenStates = ['fullscreen', 'exitfullscreen'];
    fullScreenStates.forEach((state) => {
      this.player.addEventListener(window.amp.eventName[state], () => onFullScreenState(state));
    });

    this.player.addEventListener(window.amp.eventName.durationchange, () => {
      const lengthOfSource = this.player.duration();
      onDurationChanged(lengthOfSource);
    });

    // The timeupdate event fires non-stop. This throttles it to the given interval
    if (onTimeUpdate && timeUpdateIntervalSec) {
      this.lastTimeUpdateSec = 0;
      this.player.addEventListener(window.amp.eventName.timeupdate, () => {
        const currentTimeSec = this.player.currentTime();
        if (currentTimeSec < this.lastTimeUpdateSec) {
          // Reset on rewind
          this.lastTimeUpdateSec = 0;
        }
        if (currentTimeSec > (this.lastTimeUpdateSec + timeUpdateIntervalSec)) {
          this.lastTimeUpdateSec = currentTimeSec;
          onTimeUpdate(Math.floor(currentTimeSec));
        }
      });
    }
  }

  destroyPlayer() {
    if (this.player) {
      this.player.dispose();
    }
  }

  render() {
    const {
      className,
      skin,
      cornerPlayBtn
    } = this.props;
    return (
      <>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          ref={this.videoNode}
          className={`azuremediaplayer ${skin}-skin ${cornerPlayBtn ? '' : 'amp-big-play-centered'} ${className}`}
        />
      </>
    );
  }
}

// Track (textTracks prop): http://amp.azure.net/libs/amp/latest/docs/index.html#amp.player.track
AzureMP.propTypes = {
  src: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  })),
  textTracks: PropTypes.arrayOf(PropTypes.shape({
    kind: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    srclang: PropTypes.string
  })),
  options: PropTypes.shape({
    autoplay: PropTypes.bool,
    ampAds: PropTypes.shape({
      preRoll: PropTypes.shape({
        sourceUri: PropTypes.string,
        options: PropTypes.shape({
          skipAd: PropTypes.shape({
            enabled: PropTypes.bool,
            offset: PropTypes.number
          })
        })
      }),
      mainProgram: PropTypes.shape({})
    })
  }),
  currentTime: PropTypes.number,
  onStart: PropTypes.func,
  onResume: PropTypes.func,
  onError: PropTypes.func,
  onPlaybackState: PropTypes.func,
  onFullScreenState: PropTypes.func,
  onDurationChanged: PropTypes.func,
  onTimeUpdate: PropTypes.func,
  timeUpdateIntervalSec: PropTypes.number,
  className: PropTypes.string,
  skin: PropTypes.oneOf(['amp-flush', 'amp-default']), // TODO: Only amp-default CSS is imported currently
  cornerPlayBtn: PropTypes.bool,
  language: PropTypes.oneOf(['en', 'fr'])
};

AzureMP.defaultProps = {
  src: undefined,
  textTracks: [],
  options: {},
  currentTime: 0,
  onStart: () => {},
  onResume: () => {},
  onError: () => {},
  onPlaybackState: () => {},
  onFullScreenState: () => {},
  onDurationChanged: () => {},
  onTimeUpdate: undefined,
  timeUpdateIntervalSec: undefined,
  className: null,
  skin: 'amp-default',
  cornerPlayBtn: false,
  language: 'en'
};

export default AzureMP;
