import { routes } from '../constants';
import searchUtils from './searchUtils';

// eslint-disable-next-line import/prefer-default-export
export const presenterToUrl = (presenter) => {
  const searchParams = {
    query: null,
    filters: {
      presenters: [presenter.presenter]
    }
  };
  return routes.results + searchUtils.searchParamsToUrl(searchParams);
};
