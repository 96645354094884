import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import { FileCopyRounded, Share } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import Button from './Button';
import { videoType } from '../../types';
import IconButton from './IconButton';
import styles from './ShareButton.module.scss';
import { addNotification } from '../../store/actions/notificationsActions';
import { logError } from '../../utils/logUtils';

const ShareButton = ({ video, visible, addNotificationAction }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!video || !video.channelCode) {
    return null;
  }

  const url = `${window.location.origin}/videos/${video.id}/share?code=${video.channelCode}`;
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const copyUrl = () => {
    navigator.clipboard.writeText(url).then(() => {
      addNotificationAction('copySuccess', 'success');
    }).catch((err) => {
      logError('Failed to copy text to clipboard.', err);
      addNotificationAction('copyFailure', 'error');
    });
  };

  return (
    <>
      <Button
        icon={Share}
        labelId="videoShare"
        onClick={handleClick}
        visible={visible}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        classes={{
          paper: styles.popover
        }}
      >
        <p className={styles.heading}>
          <FormattedMessage id="videoShare" />
        </p>
        <input
          type="text"
          readOnly
          value={url}
          className={styles.url}
        />
        <IconButton
          icon={FileCopyRounded}
          size="small"
          padding="none"
          onClick={copyUrl}
          titleId="copy"
        />
      </Popover>
    </>
  );
};

ShareButton.propTypes = {
  video: videoType.isRequired,
  visible: PropTypes.bool,
  addNotificationAction: PropTypes.func.isRequired
};

ShareButton.defaultProps = {
  visible: true
};

const mapDispatchToProps = {
  addNotificationAction: addNotification
};

export default connect(null, mapDispatchToProps)(ShareButton);
