import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import VideoSection from 'components/videos/VideoSection';
import MarketingBanner from 'components/banner/MarketingBanner';
import PresenterSection from 'components/presenters/PresenterSection';
import ChannelSection from 'components/channels/ChannelSection';
import {
  fetchUpcomingLiveEvents,
  fetchMyPrivateWebcasts,
  fetchTopWebcasts,
  fetchHotTopics,
  fetchPopularPresenters
} from 'store/actions/suggestionsActions';
import { suggestionsContainerType, userContainerType, matchType } from 'types';
import { homeMaxCards } from '../constants';
import HotTopics from '../components/videos/HotTopics';
import { getAllUserChannels, userChannelsToCardData, userHasChannels } from '../utils/userUtils';
import { isContainerNotInitialized } from '../utils/objectUtils';
import styles from './Home.module.scss';
import SignInDialog from '../components/user/SignInDialog';

const Home = ({
  userContainer,
  suggestionsContainer,
  fetchMyPrivateWebcastsAction,
  fetchTopWebcastsAction,
  fetchUpcomingLiveEventsAction,
  fetchHotTopicsAction,
  fetchPopularPresentersAction,
  extend,
  match
}) => {
  const [openSignIn, setOpenSignIn] = React.useState(false);
  const [redirectTo, setRedirectTo] = React.useState(null);
  const {
    videoId
  } = match.params;
  const {
    user
  } = userContainer;
  const {
    myPrivateWebcasts,
    topWebcasts,
    upcomingLiveEvents,
    hotTopics,
    popularPresenters
  } = suggestionsContainer;
  const isUserAuthenticated = !!user;
  const redirectToVideo = (url) => <Redirect to={url} />;

  const fetchDataIfNeeded = () => {
    // Ensures data is reloaded after sign out (when global state is reset)
    if (userHasChannels(user) && isContainerNotInitialized(myPrivateWebcasts)) {
      // TODO: For phase 1 we'll put channels shared with me and channels I own in one bucket. For phase 2 we'll split them (WEBCAST-306)
      fetchMyPrivateWebcastsAction(getAllUserChannels(user));
    }
    if (isContainerNotInitialized(topWebcasts)) {
      fetchTopWebcastsAction();
    }
    if (isContainerNotInitialized(upcomingLiveEvents)) {
      fetchUpcomingLiveEventsAction();
    }
    if (isContainerNotInitialized(hotTopics)) {
      fetchHotTopicsAction();
    }
    if (isContainerNotInitialized(popularPresenters)) {
      fetchPopularPresentersAction();
    }
  };

  // Similar to componentDidMount and componentDidUpdate
  // https://reactjs.org/docs/hooks-effect.html

  useEffect(() => {
    fetchDataIfNeeded();
  });

  useEffect(() => {
    if (extend) {
      if (!user) {
        setOpenSignIn(true);
      } else {
        setOpenSignIn(false);
        setRedirectTo(`/videos/${videoId}`);
      }
    }
  });

  // TODO: For phase 1 we'll put channels shared with me and channels I own in one bucket. For phase 2 we'll split them (WEBCAST-306)
  const channelsCardData = userChannelsToCardData(user);

  if (redirectTo) {
    return redirectToVideo(redirectTo);
  }

  return (
    <div className={styles.container}>
      <MarketingBanner />
      {isUserAuthenticated && (
        <>
          <VideoSection headingId="privateWebcasts" videoContainer={myPrivateWebcasts} hideIfEmpty />
          <ChannelSection headingId="privateChannels" dataList={channelsCardData} hideIfEmpty />
        </>
      )}
      <VideoSection headingId="upcomingLiveEvents" videoContainer={upcomingLiveEvents} hideIfEmpty />
      <VideoSection headingId="topWebcasts" videoContainer={topWebcasts} hideViewAll />
      <PresenterSection headingId="popularPresenters" displayStyle="dark" presenterContainer={popularPresenters} max={homeMaxCards} />
      <HotTopics suggestionsContainer={suggestionsContainer} />
      <SignInDialog
        open={openSignIn}
        onClose={() => setOpenSignIn(false)}
      />
    </div>
  );
};

Home.propTypes = {
  userContainer: userContainerType.isRequired,
  suggestionsContainer: suggestionsContainerType.isRequired,
  fetchMyPrivateWebcastsAction: PropTypes.func.isRequired,
  fetchTopWebcastsAction: PropTypes.func.isRequired,
  fetchUpcomingLiveEventsAction: PropTypes.func.isRequired,
  fetchHotTopicsAction: PropTypes.func.isRequired,
  fetchPopularPresentersAction: PropTypes.func.isRequired,
  extend: PropTypes.bool,
  match: matchType.isRequired
};

Home.defaultProps = {
  extend: null
};

const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps;

  return {
    userContainer: state.user,
    suggestionsContainer: state.suggestions,
    extend: location.pathname.endsWith('/extend')
  };
};

const mapDispatchToProps = {
  fetchMyPrivateWebcastsAction: fetchMyPrivateWebcasts,
  fetchTopWebcastsAction: fetchTopWebcasts,
  fetchUpcomingLiveEventsAction: fetchUpcomingLiveEvents,
  fetchHotTopicsAction: fetchHotTopics,
  fetchPopularPresentersAction: fetchPopularPresenters
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
