import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FacetFilter from './FacetFilter';
import { facetsType } from '../../types';
import { combineFacetsAndFilters } from '../../utils/searchUtils';

const FacetFilters = ({ facets, onApply }) => (
  facets && Object.entries(facets).map(([fieldName, values]) => (
    <FacetFilter
      key={fieldName}
      fieldName={fieldName}
      values={values}
      onApply={onApply}
    />
  ))
);

FacetFilters.propTypes = {
  facets: facetsType.isRequired,
  onApply: PropTypes.func
};

FacetFilters.defaultProps = {
  onApply: () => {}
};

/*
We combine facets (search.results) and filters (search.searchParams) such that pre-determined searches like
Chronic Health Management show all their selected filters, not just ones that are returned as facets.
 */
const mapStateToProps = (state) => {
  const { search } = state;
  const { facets } = search.results;
  const { filters } = search.searchParams;
  const combinedList = combineFacetsAndFilters(facets, filters);
  return {
    facets: combinedList
  };
};

export default connect(mapStateToProps, {})(FacetFilters);
