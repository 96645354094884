import React from 'react';
import PropTypes from 'prop-types';
import { presentersType } from '../../types';
import Text from '../containers/Text';
import { maxLength } from '../../constants';

const VideoPresenters = ({ presenters, className }) => (
  <Text
    value={presenters.join(', ')}
    maxLength={maxLength.presenters}
    className={className}
  />
);

VideoPresenters.propTypes = {
  presenters: presentersType.isRequired,
  className: PropTypes.string
};

VideoPresenters.defaultProps = {
  className: ''
};

export default VideoPresenters;
