import { suggestionIds } from 'constants/index';

import {
  HOT_TOPICS_FETCH,
  HOT_TOPICS_FETCH_SUCCESS,
  HOT_TOPICS_FETCH_FAILURE,
  POPULAR_PRESENTERS_FETCH,
  POPULAR_PRESENTERS_FETCH_FAILURE,
  POPULAR_PRESENTERS_FETCH_SUCCESS,
  SUGGESTIONS_FETCH_SUCCESS,
  SUGGESTIONS_FETCH_FAILURE,
  SUGGESTIONS_FETCH,
  USER_UPDATE_SUCCESS
} from 'store/actionTypes';

const initialContainer = {
  list: null,
  isLoading: false,
  error: null
};

const initialSuggestionsContainer = {
  ...initialContainer,
  searchParams: null
};

const initialState = {
  [suggestionIds.upcomingLiveEvents]: initialSuggestionsContainer,
  [suggestionIds.myPrivateWebcasts]: initialSuggestionsContainer,
  [suggestionIds.topWebcasts]: initialSuggestionsContainer,
  popularPresenters: initialContainer,
  hotTopics: initialContainer
};

const applyHotTopicsFetch = (state) => ({
  ...state,
  hotTopics: {
    list: null,
    error: null,
    isLoading: true
  }
});

const applyHotTopicsFetchSuccess = (state, action) => ({
  ...state,
  hotTopics: {
    list: action.payload.topics,
    error: null,
    isLoading: false
  }
});

const applyHotTopicsFetchFailure = (state, action) => ({
  ...state,
  hotTopics: {
    list: null,
    error: action.payload.error,
    isLoading: false
  }
});

function applySuggestionsFetch(state, action) {
  // Expect id to be one of "upcomingEvents" or "topWebcasts" or "hotTopics_[blah]"
  const { id, searchParams } = action.payload;

  return {
    ...state,
    [id]: {
      list: null,
      error: null,
      isLoading: true,
      searchParams
    }
  };
}

function applySuggestionsFetchSuccess(state, action) {
  const { id, list } = action.payload;

  return {
    ...state,
    [id]: {
      ...state[id],
      isLoading: false,
      list
    }
  };
}

function applySuggestionsFetchFailure(state, action) {
  const { id, error } = action.payload;

  return {
    ...state,
    [id]: {
      ...state[id],
      isLoading: false,
      error
    }
  };
}

const applyPopularPresentersFetch = (state) => ({
  ...state,
  popularPresenters: {
    ...state.popularPresenters,
    isLoading: true
  }
});

const applyPopularPresentersFetchSuccess = (state, action) => ({
  ...state,
  popularPresenters: {
    ...state.popularPresenters,
    isLoading: false,
    list: action.payload.presenters
  }
});

const applyPopularPresentersFetchFailure = (state, action) => ({
  ...state,
  popularPresenters: {
    ...state.popularPresenters,
    isLoading: false,
    error: action.payload.error
  }
});

// If user is updated, so is the list of their channels. Therefore myPrivateWebcasts needs to be cleared and reloaded.
const applyUserUpdateSuccess = (state) => ({
  ...state,
  [suggestionIds.myPrivateWebcasts]: initialSuggestionsContainer
});

export default (state = initialState, action) => {
  switch (action.type) {
    case HOT_TOPICS_FETCH:
      return applyHotTopicsFetch(state, action);
    case HOT_TOPICS_FETCH_SUCCESS:
      return applyHotTopicsFetchSuccess(state, action);
    case HOT_TOPICS_FETCH_FAILURE:
      return applyHotTopicsFetchFailure(state, action);
    case SUGGESTIONS_FETCH:
      return applySuggestionsFetch(state, action);
    case SUGGESTIONS_FETCH_SUCCESS:
      return applySuggestionsFetchSuccess(state, action);
    case SUGGESTIONS_FETCH_FAILURE:
      return applySuggestionsFetchFailure(state, action);
    case POPULAR_PRESENTERS_FETCH:
      return applyPopularPresentersFetch(state, action);
    case POPULAR_PRESENTERS_FETCH_SUCCESS:
      return applyPopularPresentersFetchSuccess(state, action);
    case POPULAR_PRESENTERS_FETCH_FAILURE:
      return applyPopularPresentersFetchFailure(state, action);
    case USER_UPDATE_SUCCESS:
      return applyUserUpdateSuccess(state);
    default:
      return state;
  }
};
