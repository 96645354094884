import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { searchVideoChangeFilter, searchVideoApplyFilters } from 'store/actions/searchActions';
import DatePicker from '../form/DatePicker';
import {
  endOfDay,
  isSameDate,
  startOfDay,
  stringToDate
} from '../../utils/dateUtils';
import { filtersType } from '../../types';
import FilterContainer from './FilterContainer';

const parseFilter = (filters, fieldName) => (
  filters[fieldName] ? filters[fieldName][0] : null
);

const DateFilter = ({
  fieldName,
  filters,
  formFilters,
  searchVideoChangeFilterAction,
  searchVideoApplyFiltersAction,
  onApply
}) => {
  const history = useHistory();

  if (!filters || !formFilters) {
    return null;
  }

  // TODO: Figure out a way to refactor this
  const fieldNameMin = `${fieldName}Min`;
  const fieldNameMax = `${fieldName}Max`;

  const minDateStr = parseFilter(filters, fieldNameMin);
  const maxDateStr = parseFilter(filters, fieldNameMax);
  const formMinDateStr = parseFilter(formFilters, fieldNameMin);
  const formMaxDateStr = parseFilter(formFilters, fieldNameMax);

  const formMinDate = stringToDate(formMinDateStr);
  const formMaxDate = stringToDate(formMaxDateStr);

  const isAnyValueSet = !!(minDateStr || maxDateStr);

  const handleFromDateChange = (date) => {
    /*
    TODO: P2: This takes too long. Ideas:
     1) Put the Date object in the form as-is. I'll save time on conversions. - Tried this, didn't seem to help.
     2) Split this into min/max components, so only one field is re-rendered on change.
     */
    searchVideoChangeFilterAction(fieldNameMin, startOfDay(date));
  };

  const handleToDateChange = (date) => {
    searchVideoChangeFilterAction(fieldNameMax, endOfDay(date));
  };

  const handleApply = () => {
    searchVideoApplyFiltersAction([fieldNameMin, fieldNameMax], history);
    onApply();
  };

  const isDateChanged = () => (
    !isSameDate(formMinDateStr, minDateStr)
    || !isSameDate(formMaxDateStr, maxDateStr)
  );

  return (
    <FilterContainer
      fieldName={fieldName}
      defaultExpanded={isAnyValueSet}
      showApply={isDateChanged()}
      onApply={handleApply}
    >
      <DatePicker
        date={formMinDate}
        onChange={handleFromDateChange}
        labelId="searchFilter_scheduledStartFrom"
        ariaLabelId="searchFilter_scheduledStartFromDetails"
      />

      <DatePicker
        date={formMaxDate}
        onChange={handleToDateChange}
        labelId="searchFilter_scheduledStartTo"
        ariaLabelId="searchFilter_scheduledStartToDetails"
      />
    </FilterContainer>
  );
};

DateFilter.propTypes = {
  fieldName: PropTypes.string.isRequired,
  filters: filtersType,
  formFilters: filtersType,
  searchVideoChangeFilterAction: PropTypes.func.isRequired,
  searchVideoApplyFiltersAction: PropTypes.func.isRequired,
  onApply: PropTypes.func
};

DateFilter.defaultProps = {
  filters: null,
  formFilters: null,
  onApply: () => {}
};

const mapStateToProps = (state) => {
  const { searchParams, searchParamsForm } = state.search;
  return {
    filters: searchParams.filters,
    formFilters: searchParamsForm.filters
  };
};

const mapDispatchToProps = {
  searchVideoChangeFilterAction: searchVideoChangeFilter,
  searchVideoApplyFiltersAction: searchVideoApplyFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(DateFilter);
