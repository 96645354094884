import React from 'react';
import PropTypes from 'prop-types';
import searchUtils from '../../utils/searchUtils';
import styles from './TextWithEm.module.scss';

const TextWithEm = ({ value }) => {
  const parsedText = searchUtils.suggestionParseText(value);
  return (
    <>
      {parsedText.map((element, index) => (
        element.isEmphasized ? (
          // eslint-disable-next-line react/no-array-index-key
          <em key={index} className={styles.emphasized}>{element.value}</em>
        ) : (
          // eslint-disable-next-line react/no-array-index-key
          <span key={index}>{element.value}</span>
        )
      ))}
    </>
  );
};

TextWithEm.propTypes = {
  value: PropTypes.string.isRequired
};

export default TextWithEm;
