import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { videoType } from 'types';
import { localeEnCa } from 'constants/index';
import moment from 'moment';
import styles from './ExpiryBadge.module.scss';
import Image from '../containers/Image';
import restoringIcon from '../../assets/images/Restoring-Icon.svg';
import expiringIcon from '../../assets/images/Expiring-Icon.svg';
import expiredIcon from '../../assets/images/Expired-Icon.svg';
import Tooltip from '../containers/Tooltip';

const ExpiryBadge = ({ video, locale }) => {
  let iconUrl;
  let expiryClass;
  let expiryLabelClass;
  let labelCode;
  const icons = {
    restoring: restoringIcon,
    expiring: expiringIcon,
    expired: expiredIcon
  };
  const isEnglish = (locale === localeEnCa);

  switch (video.expiryType) {
    case 'expired':
      iconUrl = icons.expired;
      expiryClass = styles.expired;
      expiryLabelClass = styles.expiredLabel;
      labelCode = 'expired';
      break;
    case 'expiring':
      iconUrl = icons.expiring;
      expiryClass = styles.expiring;
      expiryLabelClass = styles.expiringLabel;
      labelCode = 'expiring';
      break;
    case 'restoring':
      iconUrl = icons.restoring;
      expiryClass = styles.restoring;
      expiryLabelClass = styles.restoringLabel;
      labelCode = 'restoring';
      break;
    default:
      break;
  }

  if (expiryClass) {
    return (
      <Tooltip titleId={`expiryBadgeTooltip_${video.expiryType}`}>
        <div className={`${styles.container} ${expiryClass}`}>
          <Image className={styles.icon} altId={`expiryBadgeIcon_${labelCode}`} src={iconUrl} ariaHidden />
          <span className={`${styles.label} ${expiryLabelClass}`}>
            <FormattedMessage
              id={`expiryBadgeLabel_${labelCode}`}
              values={{
                date: () => (
                  <span>{video && video.expires ? moment.utc(video.expires).locale(isEnglish ? 'en' : 'fr').format(isEnglish ? 'MMM. DD, YYYY' : 'DD MMMM YYYY') : ''}</span>
                )
              }}
            />
          </span>
        </div>
      </Tooltip>
    );
  }

  return (
    <div />
  );
};

ExpiryBadge.propTypes = {
  video: videoType.isRequired,
  locale: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  locale: state.locale.locale
});

export default connect(mapStateToProps)(ExpiryBadge);
