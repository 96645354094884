import React from 'react';
import PropTypes from 'prop-types';
import ReactTimeAgo from 'react-time-ago';
import { connect } from 'react-redux';
import { videoType } from '../../types';
import VideoPresenters from './VideoPresenters';
import Text from '../containers/Text';
import { maxLength } from '../../constants';
import VideoBadges from './VideoBadges';
import styles from './VideoDetailsShort.module.scss';

const VideoDetailsShort = ({
  video,
  locale,
  titleId,
  descriptionId
}) => {
  const {
    name,
    presenters,
    scheduledStart
  } = video;

  return (
    <div className={styles.container}>
      <h3 className={styles.title} id={titleId}>
        <Text value={name} maxLength={maxLength.videoName} />
      </h3>
      <div id={descriptionId}>
        <VideoPresenters presenters={presenters} className={styles.presenters} />
        <div className={styles.gridFooter}>
          <ReactTimeAgo
            date={new Date(scheduledStart)}
            locale={locale}
            className={styles.date}
            timeStyle="twitter"
          />
          <VideoBadges video={video} />
        </div>
      </div>
    </div>
  );
};

VideoDetailsShort.propTypes = {
  video: videoType.isRequired,
  locale: PropTypes.string.isRequired,
  titleId: PropTypes.string,
  descriptionId: PropTypes.string
};

VideoDetailsShort.defaultProps = {
  titleId: '',
  descriptionId: ''
};

const mapStateToProps = (state) => ({
  locale: state.locale.locale
});

export default connect(mapStateToProps, {})(VideoDetailsShort);
