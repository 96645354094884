import React from 'react';
import PropTypes from 'prop-types';
import { childrenType, errorType } from 'types';
import Loading from './Loading';
import InlineError from '../error/InlineError';

/**
 * Use this whenever loading data to render a loading indicator while the data loads, the data itself (child element)
 * when it does load, or an error message if it fails to load.
 */
const LoadingWrapper = ({
  isLoading,
  children,
  errorMessageId,
  error
}) => {
  const isError = !isLoading && error;
  const isSuccess = !isLoading && !isError;

  return (
    <>
      {isLoading && <Loading />}
      {isSuccess && children}
      {isError && <InlineError messageId={errorMessageId} error={error} />}
    </>
  );
};

LoadingWrapper.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  errorMessageId: PropTypes.string.isRequired,
  error: errorType,
  children: childrenType.isRequired
};

LoadingWrapper.defaultProps = {
  error: null
};

export default LoadingWrapper;
