import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert, AlertTitle } from '@material-ui/lab';
import styles from './ExpiryError.module.scss';

const ExpiryError = () => (
  <div className={styles.container}>
    <Alert
      severity="error"
      classes={{
        standardError: styles.alertContainer,
        icon: styles.iconContainer,
        message: styles.alertMessage
      }}
    >
      <AlertTitle>
        <FormattedMessage id="error" />
      </AlertTitle>
      <FormattedMessage id="extendVideo_error">
        {(txt) => <p className={styles.alertMessageContent}>{txt}</p>}
      </FormattedMessage>
      <FormattedMessage id="extendVideo_error_secondary">
        {(txt) => <p className={styles.alertMessageContentSecondary}>{txt}</p>}
      </FormattedMessage>
      <FormattedMessage id="extendVideo_error_contact">
        {(txt) => (
          <p className={styles.alertMessageContent}>
            {txt}
            <a href="webcasting@otn.ca" className={styles.alertLink}> webcasting@otn.ca</a>
          </p>
        )}
      </FormattedMessage>
    </Alert>
  </div>
);

export default ExpiryError;
