import { encode, decode } from 'ent';
import { contains } from './arrayUtils';

// TODO: Refactor to use separate export for each function (as in isAbsoluteUrl below)
export default {
  /**
   * Convert URL param string to a JSON object
   *
   * Note: Could have parsed using URLSearchParams object, but it's not supported in IE11
   *
   * @param params String of URL params (i.e. "?param=value&param2=value2")
   * @param paramNames Optional. List of param names to return, if available.
   * @returns {{}}
   */
  parseParams(params, paramNames) {
    const paramsObject = {};

    const splitParams = params.replace('?', '').split('&');
    if (splitParams.length === 1 && splitParams[0] === '') {
      return paramsObject;
    }

    splitParams.forEach((param) => {
      const keyValue = param.split('=');
      if (!paramNames || contains(paramNames, keyValue[0])) {
        const [key, value] = keyValue;
        paramsObject[key] = decodeURIComponent(value);
      }
    });

    return paramsObject;
  },

  /**
   * Convert URL param JSON object to string
   *
   * @param paramsObject
   * @returns {string}
   */
  paramsToString(paramsObject) {
    if (!paramsObject) {
      return '';
    }
    let paramsString = '?';
    if (paramsObject) {
      Object.entries(paramsObject).forEach(([key, value]) => {
        if (key && value) {
          paramsString += `${key}=${value}&`;
        }
      });
    }
    return paramsString.substring(0, paramsString.length - 1);
  },

  /**
   * Updates the given URL param string with the new key / value
   *
   * @param params Current URL params string
   * @param key New param key
   * @param value New param value
   * @returns {string}
   */
  setParam(params, key, value) {
    const parsedParams = this.parseParams(params);
    parsedParams[key] = value;
    return this.paramsToString(parsedParams);
  },

  removeParam(params, key) {
    const parsedParams = this.parseParams(params);
    delete parsedParams[key];
    return this.paramsToString(parsedParams);
  },

  isHttps(url) {
    return url && url.indexOf('https') === 0;
  }
};

const encodeOpts = {
  named: true,
  special: {
    ',': true,
    '=': true,
    '&': true,
    '?': true
  }
};

export const encodeUrlValue = (value) => encode(`${value}`, encodeOpts);

export const decodeUrlValue = (value) => decode(`${value}`, encodeOpts);

export const isAbsoluteUrl = (url) => (
  url && (url.indexOf('http') === 0 || url.indexOf('mailto'))
);

export const buildMailToLink = (email, subject) => (
  `mailto:${email}?subject=${encodeURIComponent(subject)}`
);
