import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../buttons/Button';
import SignInDialog from './SignInDialog';
import { userContainerType } from '../../types';
import { isUserSignedIn } from '../../utils/userUtils';
import styles from './SignInButton.module.scss';

const SignInButton = ({
  theme,
  variant,
  userContainer,
  className
}) => {
  const { isLoading } = userContainer;
  const isSignedIn = isUserSignedIn(userContainer);
  const [open, setOpen] = React.useState(false);
  const classNameTheme = (theme === 'dark') ? styles.dark : '';

  if (isSignedIn) {
    return null;
  }

  return (
    <>
      <Button
        variant={variant}
        onClick={() => setOpen(true)}
        className={`${className} ${classNameTheme}`}
        disabled={isLoading}
        labelId="userSignIn"
      />

      <SignInDialog
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

SignInButton.propTypes = {
  theme: PropTypes.oneOf(['normal', 'dark']),
  variant: PropTypes.oneOf(['primary', 'outlined']),
  userContainer: userContainerType.isRequired,
  className: PropTypes.string
};

SignInButton.defaultProps = {
  theme: 'normal',
  variant: 'primary',
  className: ''
};

const mapStateToProps = (state) => ({
  userContainer: state.user
});

export default connect(mapStateToProps, {})(SignInButton);
