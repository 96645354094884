import React from 'react';
import { Helmet } from 'react-helmet';
import preval from 'preval.macro';
import packageJson from '../../package.json';

const buildTimestamp = preval`module.exports = new Date().toLocaleString();`;

const About = () => (
  <>
    <Helmet>
      <title>About</title>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    <div>
      <h2>About</h2>
      <p>{`Version: ${packageJson.version}`}</p>
      <p>{`Build Date: ${buildTimestamp}`}</p>
    </div>
  </>
);

export default About;
