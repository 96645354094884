import React from 'react';
import Duration from 'components/duration/Duration';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { videoType } from 'types';
import Paper from '@material-ui/core/Paper';
import ExpiryBadge from 'components/badges/ExpiryBadge';
import VideoDetails from './VideoDetails';
import VideoThumbnail from './VideoThumbnail';
import ConditionalWrapper from '../utils/ConditionalWrapper';
import VideoDetailsShort from './VideoDetailsShort';
import LiveBadge from '../badges/LiveBadge';
import VideoBadges from './VideoBadges';
import TextWithEm from '../containers/TextWithEm';
import { getHighlights } from '../../utils/videoUtils';
import styles from './VideoCard.module.scss';

const VideoCard = ({
  displayStyle, video
}) => {
  const {
    id,
    name,
    scheduledStart,
    scheduledEnd,
    thumbnailSource
  } = video;

  /*
  TODO: P1: Consider refactoring this into 2 components based around the isList logic (1 for grid, 1 for list).
  1. Take out all common logic to separate components (i.e. VideoThumbnail with time overlay)
  2. Move LiveBadge to details (both Short and Full)
  3. Split into two components - VideoCard and VideoListElement
   */
  const isList = (displayStyle === 'list');
  const containerClass = isList
    ? `${styles.container} ${styles.containerList}`
    : styles.container;
  const nameEm = getHighlights(video, 'name');

  return (
    <Link
      to={`/videos/${id}`}
      className={containerClass}
      aria-labelledby={`video-title-${id}`}
      aria-describedby={`video-duration-${id} video-description-${id}`}
    >
      <ConditionalWrapper
        condition={!isList}
        wrapper={(children) => (
          <Paper elevation={3} classes={{ root: styles.paper }}>{children}</Paper>
        )}
      >
        <div className={styles.thumbnailContainer}>
          <VideoThumbnail
            srcList={thumbnailSource}
            className={styles.thumbnail}
            videoName={name}
          />
          <div className={styles.durationContainer}>
            <Duration start={scheduledStart} end={scheduledEnd} id={`video-duration-${id}`} />
          </div>
        </div>
        <div className={styles.descriptionContainer}>
          {isList ? (
            <>
              <LiveBadge video={video} />
              <h2 className={styles.title} id={`video-title-${id}`}>
                <TextWithEm value={nameEm} />
              </h2>
              <div id={`video-description-${id}`}>
                <VideoDetails video={video} />
                <ExpiryBadge video={video} />
                <VideoBadges video={video} />
              </div>
            </>
          ) : (
            <>
              <LiveBadge video={video} showEmptyContainer />
              <VideoDetailsShort
                video={video}
                titleId={`video-title-${id}`}
                descriptionId={`video-description-${id}`}
              />
            </>
          )}
        </div>
      </ConditionalWrapper>
    </Link>
  );
};

VideoCard.propTypes = {
  displayStyle: PropTypes.oneOf(['list', 'grid']),
  video: videoType.isRequired
};

VideoCard.defaultProps = {
  displayStyle: 'grid'
};

export default VideoCard;
