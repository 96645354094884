import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Button, Tooltip } from '@material-ui/core';
import { iconType, intlType } from 'types';
import ConditionalWrapper from '../utils/ConditionalWrapper';
import styles from './IconButton.module.scss';

const IconButton = ({
  type,
  size,
  padding,
  icon,
  titleId,
  labelId,
  onClick,
  disabled,
  className,
  tabIndex,
  ariaHidden,
  intl,
  variant
}) => {
  const IconTag = icon;
  const title = titleId ? intl.formatMessage({ id: titleId }) : null;
  const label = labelId && intl.formatMessage({ id: labelId });
  const paddingClass = (padding === 'none') ? styles.buttonNoPadding : '';
  const iconSize = (size === 'medium') ? 'default' : size;

  /*
  TODO: Consider refactoring this into two distinct components (IconButton and IconSubmitButton) to fix the eslint
   error below. See also: https://github.com/yannickcr/eslint-plugin-react/issues/1555
   */
  /* eslint-disable react/button-has-type */
  /*
  Note: StylesProvider used such that MaterialUI styles are injected into the document first, and can be overridden by
  our custom styles. See: https://material-ui.com/styles/advanced/#css-injection-order
   */
  return (
    <ConditionalWrapper
      condition={title && !disabled}
      wrapper={(children) => (<Tooltip title={title}>{children}</Tooltip>)}
    >
      <Button
        className={`${styles.button} ${paddingClass} ${className}`}
        type={type}
        size={size}
        aria-label={title}
        aria-hidden={ariaHidden}
        onClick={onClick}
        disabled={disabled}
        variant={variant}
        tabIndex={tabIndex}
      >
        <IconTag fontSize={iconSize} />
        {label && <span className={styles.label}>{label}</span>}
      </Button>
    </ConditionalWrapper>
  );
  /* eslint-enable react/button-has-type */
};

IconButton.propTypes = {
  icon: iconType.isRequired,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  padding: PropTypes.oneOf(['none', 'normal']),
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  titleId: PropTypes.string,
  labelId: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  tabIndex: PropTypes.string,
  ariaHidden: PropTypes.bool,
  intl: intlType.isRequired
};

IconButton.defaultProps = {
  type: 'button',
  size: 'medium',
  padding: 'normal',
  variant: 'text',
  labelId: null,
  titleId: null,
  onClick: undefined,
  disabled: false,
  tabIndex: null,
  ariaHidden: undefined,
  className: ''
};

export default injectIntl(IconButton);
