import React from 'react';
import PropTypes from 'prop-types';
import VideoThumbnail from './VideoThumbnail';
import styles from './VideoThumbnailOverlay.module.scss';

const VideoThumbnailOverlay = ({
  videoName,
  thumbnailSource,
  children
}) => (
  <div className={styles.container}>
    <div className={styles.innerContainer}>
      <VideoThumbnail
        srcList={thumbnailSource}
        className={styles.lockedThumbnail}
        videoName={videoName}
      />
      <div className={styles.overlay}>
        {children}
      </div>
    </div>
  </div>
);

VideoThumbnailOverlay.propTypes = {
  videoName: PropTypes.string.isRequired,
  thumbnailSource: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.shape({}).isRequired // TODO: Validate properly
};

export default VideoThumbnailOverlay;
