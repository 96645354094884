import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { intlType } from '../../types';
import { dateToStringFull } from '../../utils/dateUtils';
import styles from './LabelValue.module.scss';

const LabelValue = ({
  labelId,
  value,
  hideIfNoValue,
  formatDate,
  intl,
  locale
}) => {
  const isArray = (value && typeof value === 'object' && value.join);
  const noValue = (value === undefined || value === null || value === '' || (isArray && value.length === 0));
  if (noValue && hideIfNoValue) {
    return null;
  }

  let parsedValue;
  if (noValue) {
    parsedValue = '';
  } else if (typeof value === 'boolean') {
    parsedValue = value
      ? intl.formatMessage({ id: 'valueYes' })
      : intl.formatMessage({ id: 'valueNo' });
  } else if (formatDate) {
    parsedValue = dateToStringFull(value, locale);
  } else if (isArray) {
    parsedValue = value.join(', ');
  } else {
    parsedValue = value;
  }

  return (
    <p className={styles.container}>
      <span className={styles.label}>
        <FormattedMessage id={labelId} />
        :
      </span>
      <span className={styles.value}>
        {parsedValue}
      </span>
    </p>
  );
};

LabelValue.propTypes = {
  labelId: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.element,
    PropTypes.number
  ]),
  hideIfNoValue: PropTypes.bool,
  formatDate: PropTypes.bool,
  intl: intlType.isRequired,
  locale: PropTypes.string.isRequired
};

LabelValue.defaultProps = {
  value: null,
  hideIfNoValue: true,
  formatDate: false
};

const mapStateToProps = (state) => ({
  locale: state.locale.locale
});

export default connect(mapStateToProps, {})(injectIntl(LabelValue));
