import React from 'react';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { presenterType } from '../../types';
import peopleIcon from '../../assets/images/ContentCreator-Icon.svg';
import stringUtils from '../../utils/stringUtils';
import Image from '../containers/Image';
import { presenterToUrl } from '../../utils/presenterUtils';
import styles from './Presenter.module.scss';

const Presenter = ({
  presenter
}) => (
  <Link to={presenterToUrl(presenter)} className={styles.container}>
    <Paper elevation={3} classes={{ root: styles.paperContainer }}>
      <Image className={styles.iconContainer} altId="iconLabel_people" src={peopleIcon} />
      <h3 className={styles.title}>
        {stringUtils.splitToLines(presenter.presenter, 2).map((line) => (
          <span key={line} className={styles.line}>{line}</span>
        ))}
      </h3>
      <p className={styles.description}>
        <FormattedMessage id="presenterEventCount" values={{ count: presenter.numContent }} />
      </p>
    </Paper>
  </Link>
);

Presenter.propTypes = {
  presenter: presenterType.isRequired
};

export default Presenter;
