import React from 'react';
import { FormattedMessage } from 'react-intl';
import TuneRoundedIcon from '@material-ui/icons/TuneRounded';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Clear } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import DateFilter from './DateFilter';
import FacetFilters from './FacetFilters';
import Button from '../buttons/Button';
import { searchVideoClearFilters } from '../../store/actions/searchActions';
import { filtersType } from '../../types';
import objectUtils from '../../utils/objectUtils';
import IconButton from '../buttons/IconButton';
import styles from './SearchFilters.module.scss';

const SearchFilters = ({
  filters,
  formFilters,
  resultsCount,
  searchVideoClearFiltersAction,
  onApply,
  mobile
}) => {
  const history = useHistory();
  const isClearShown = !objectUtils.isEmptyObject(filters) || !objectUtils.isEmptyObject(formFilters);
  const isDateFiltersShown = !!resultsCount || formFilters.scheduledStartMin || formFilters.scheduledStartMax;

  const handleClear = () => {
    searchVideoClearFiltersAction(history);
    onApply();
  };

  // TODO: P2: The !!resultsCount logic below is wrong - the DateFilter disappears & reappears every time you update the
  //  search params. It needs to be more clever than that.
  return (
    <div className={styles.container}>
      <div className={styles.headingContainer}>
        <TuneRoundedIcon />
        <h2 className={styles.heading}><FormattedMessage id="searchFilters" /></h2>
        {mobile && (
          <IconButton icon={CloseIcon} padding="none" titleId="searchFiltersClose" onClick={onApply} />
        )}
      </div>
      <FacetFilters onApply={onApply} />
      {(isDateFiltersShown) && (
        <DateFilter fieldName="scheduledStart" onApply={onApply} />
      )}
      {isClearShown && (
        <Button
          variant="text"
          labelId="searchFiltersClear"
          icon={Clear}
          onClick={handleClear}
        />
      )}
    </div>
  );
};

SearchFilters.propTypes = {
  filters: filtersType,
  formFilters: filtersType,
  resultsCount: PropTypes.number,
  searchVideoClearFiltersAction: PropTypes.func.isRequired,
  onApply: PropTypes.func,
  mobile: PropTypes.bool
};

SearchFilters.defaultProps = {
  filters: null,
  formFilters: null,
  resultsCount: null,
  onApply: () => {},
  mobile: false
};

const mapStateToProps = (state) => {
  const { searchParams, searchParamsForm, results } = state.search;
  return {
    filters: searchParams.filters,
    formFilters: searchParamsForm.filters,
    resultsCount: results.count
  };
};

const mapDispatchToProps = {
  searchVideoClearFiltersAction: searchVideoClearFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchFilters);
