import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import styles from './LiveBadge.module.scss';
import { videoType } from '../../types';
import { isLiveNow } from '../../utils/videoUtils';

/**
 * Displays the "Live Now!" indicator to be used above the video title. If show is false, it will still render a
 * placeholder so that the card height is maintained.
 *
 * @param show
 * @returns {*}
 * @constructor
 */
const LiveBadge = ({ video, showEmptyContainer }) => {
  const liveNow = isLiveNow(video);
  if (!liveNow && !showEmptyContainer) {
    return null;
  }
  return (
    <div className={styles.container}>
      {liveNow && <FormattedMessage id="liveNow" />}
    </div>
  );
};

LiveBadge.propTypes = {
  video: videoType.isRequired,
  showEmptyContainer: PropTypes.bool
};

LiveBadge.defaultProps = {
  showEmptyContainer: false
};

export default LiveBadge;
