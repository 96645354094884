import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { localeEnCa } from 'constants/index';
import moment from 'moment';
import 'moment/locale/fr';
import expiringIcon from '../../assets/images/Expiring-Icon.svg';
import Button from '../buttons/Button';
import styles from './VideoExtend.module.scss';
import { extend, extendRequestCancel, extendRequestComplete } from '../../store/actions/videoActions';
import { extendRequestType } from '../../types';

const VideoExtend = ({
  videoId,
  extendRequest,
  extendAction,
  extendCancel,
  extendComplete,
  extendDate,
  expiryType,
  locale
}) => {
  const extendRequestParam = extendRequest;
  const isEnglish = (locale === localeEnCa);
  return (
    <div className={styles.container}>
      <>
        {(!extendRequestParam.isExtended && !extendRequestParam.error) && (
          <div>
            <img src={expiringIcon} alt={expiringIcon} className={styles.expiringImg} />
            <FormattedMessage id="extendVideo_extendYourWebcast">
              {(txt) => <p className={styles.expiringLabel}>{txt}</p>}
            </FormattedMessage>
            <p>
              <FormattedMessage id="extendVideo_requested" />
              <br />
              <b>{extendDate ? moment.utc(extendDate).locale(isEnglish ? 'en' : 'fr').format(isEnglish ? 'MMM. DD, YYYY' : 'DD MMMM YYYY') : ''}</b>
              <span>?</span>
            </p>
          </div>
        )}
        {(extendRequestParam.isExtended && !extendRequestParam.error && expiryType === 'expired') && (
          <p><FormattedMessage id="extendVideo_sent" /></p>
        )}
        {(extendRequestParam.isExtended && !extendRequestParam.error && expiryType !== 'expired') && (
          <p>
            <FormattedMessage id="extendVideo_sent_other">
              {(txt) => <p className={styles.expiringLabel}>{txt}</p>}
            </FormattedMessage>
          </p>
        )}
        {(!extendRequestParam.error && !extendRequestParam.isExtended) && (
          <div>
            <Button
              labelId="cancel"
              variant="secondary"
              className={styles.cancelBtn}
              onClick={() => extendCancel(videoId)}
            />
            {(!extendRequestParam.isExtending) && (
              <Button
                labelId="extendDate"
                variant="primary"
                onClick={() => extendAction(videoId)}
              />
            )}
            {(extendRequestParam.isExtending) && (
              <Button
                loading="true"
                variant="primary"
                onClick={() => extendAction(videoId)}
              />
            )}
          </div>
        )}
        {(!extendRequestParam.error && extendRequestParam.isExtended) && (
          <Button
            labelId="OK"
            variant="primary"
            onClick={() => extendComplete(videoId)}
          />
        )}
      </>
    </div>
  );
};

VideoExtend.propTypes = {
  videoId: PropTypes.string.isRequired,
  extendAction: PropTypes.func.isRequired,
  extendRequest: extendRequestType.isRequired,
  extendDate: PropTypes.string,
  extendCancel: PropTypes.func.isRequired,
  expiryType: PropTypes.string.isRequired,
  extendComplete: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired
};

VideoExtend.defaultProps = {
  extendDate: null
};

const mapStateToProps = (state) => ({
  extendRequest: state.video.extendRequest,
  locale: state.locale.locale
});

const mapDispatchToProps = {
  extendAction: extend,
  extendCancel: extendRequestCancel,
  extendComplete: extendRequestComplete
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoExtend);
