import {
  VIDEO_FETCH,
  VIDEO_FETCH_SUCCESS,
  VIDEO_FETCH_FAILURE,
  VIDEO_UPDATE,
  VIDEO_UPDATE_SUCCESS,
  VIDEO_UPDATE_FAILURE,
  VIDEO_SIMILAR_FETCH,
  VIDEO_SIMILAR_FETCH_FAILURE,
  VIDEO_SIMILAR_FETCH_SUCCESS,
  VIDEO_REQUEST_ACCESS,
  VIDEO_REQUEST_ACCESS_SUCCESS,
  VIDEO_REQUEST_ACCESS_FAILURE,
  VIDEO_EXTEND,
  VIDEO_EXTEND_REQUESTED,
  VIDEO_EXTEND_SUCCESS,
  VIDEO_EXTEND_FAILURE,
  VIDEO_EXTEND_CANCEL,
  VIDEO_EXTEND_COMPLETE
} from 'store/actionTypes';
import { UserError } from '../../types/errors';

const initialState = {
  currentVideoId: null,
  currentVideo: null,
  isLoading: false,
  isUpdating: false,
  error: null,
  similar: {
    isLoading: false,
    error: null,
    list: []
  },
  accessRequest: {
    isSending: false,
    isSent: false,
    error: null
  },
  extendRequest: {
    isExtending: false,
    isExtended: false,
    isRequested: false,
    isCompleted: false,
    error: null
  }
};

const processVideoError = (error) => (
  (error.response && error.response.status === 404)
    ? new UserError('videoNotFound')
    : error
);

function applyVideoFetch(state, action) {
  const { videoId } = action.payload;

  return {
    ...state,
    currentVideoId: videoId,
    currentVideo: null,
    isLoading: true,
    error: null,
    similar: {
      ...initialState.similar
    },
    accessRequest: {
      ...initialState.accessRequest
    },
    extendRequest: {
      ...initialState.extendRequest
    }
  };
}

const applyVideoFetchSuccess = (state, action) => ({
  ...state,
  currentVideo: action.payload.video,
  isLoading: false
});

const applyVideoFetchFailure = (state, action) => ({
  ...state,
  currentVideo: null,
  isLoading: false,
  error: processVideoError(action.payload.error)
});

const applyVideoUpdateStart = (state) => ({
  ...state,
  isUpdating: true
});

// TODO: Compare IDs to make sure the updated video matches the old one!
const applyVideoUpdateSuccess = (state, action) => ({
  ...state,
  currentVideo: {
    ...state.currentVideo,
    ...action.payload.video
  },
  isUpdating: false
});

const applyVideoUpdateFailure = (state, action) => ({
  ...state,
  error: processVideoError(action.payload.error),
  isUpdating: false
});

function applyVideoSimilarFetch(state, action) {
  const { videoId } = action.payload;

  return {
    ...state,
    similar: {
      isLoading: true,
      error: null,
      videoId
    }
  };
}

function applyVideoSimilarFetchSuccess(state, action) {
  const { videoId, list } = action.payload;

  return {
    ...state,
    similar: {
      isLoading: false,
      videoId,
      list
    }
  };
}

const applyVideoSimilarFetchFailure = (state, action) => ({
  ...state,
  similar: {
    isLoading: false,
    error: action.payload.error
  }
});

const applyVideoRequestAccessStart = (state) => ({
  ...state,
  accessRequest: {
    isSending: true
  }
});

const applyVideoRequestAccessSuccess = (state) => ({
  ...state,
  accessRequest: {
    isSending: false,
    isSent: true
  }
});

const applyVideoRequestAccessFailure = (state, action) => ({
  ...state,
  accessRequest: {
    isSending: false,
    error: action.payload.error
  }
});

const applyVideoExtendStart = (state) => ({
  ...state,
  extendRequest: {
    isExtending: true,
    isRequested: true,
    isExtended: false,
    isCompleted: false
  }
});

const applyVideoExtendSuccess = (state) => ({
  ...state,
  extendRequest: {
    isExtending: false,
    isExtended: true,
    isRequested: true,
    isCompleted: false
  }
});

const applyVideoExtendRequested = (state) => ({
  ...state,
  extendRequest: {
    isExtending: false,
    isExtended: false,
    isRequested: true,
    isCompleted: false
  }
});

const applyVideoExtendCancel = (state) => ({
  ...state,
  extendRequest: {
    isExtending: false,
    isExtended: false,
    isRequested: false,
    isCompleted: false
  }
});

const applyVideoExtendComplete = (state) => ({
  ...state,
  extendRequest: {
    isExtending: false,
    isExtended: false,
    isRequested: false,
    isCompleted: true
  }
});

const applyVideoExtendFailure = (state) => ({
  ...state,
  extendRequest: {
    isExtended: false,
    isExtending: false,
    isRequested: false,
    isCompleted: false,
    error: {
      isExtendError: true
    }
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case VIDEO_FETCH:
      return applyVideoFetch(state, action);
    case VIDEO_FETCH_SUCCESS:
      return applyVideoFetchSuccess(state, action);
    case VIDEO_FETCH_FAILURE:
      return applyVideoFetchFailure(state, action);
    case VIDEO_UPDATE:
      return applyVideoUpdateStart(state, action);
    case VIDEO_UPDATE_SUCCESS:
      return applyVideoUpdateSuccess(state, action);
    case VIDEO_UPDATE_FAILURE:
      return applyVideoUpdateFailure(state, action);
    case VIDEO_SIMILAR_FETCH:
      return applyVideoSimilarFetch(state, action);
    case VIDEO_SIMILAR_FETCH_SUCCESS:
      return applyVideoSimilarFetchSuccess(state, action);
    case VIDEO_SIMILAR_FETCH_FAILURE:
      return applyVideoSimilarFetchFailure(state, action);
    case VIDEO_REQUEST_ACCESS:
      return applyVideoRequestAccessStart(state, action);
    case VIDEO_REQUEST_ACCESS_SUCCESS:
      return applyVideoRequestAccessSuccess(state, action);
    case VIDEO_REQUEST_ACCESS_FAILURE:
      return applyVideoRequestAccessFailure(state, action);
    case VIDEO_EXTEND:
      return applyVideoExtendStart(state, action);
    case VIDEO_EXTEND_SUCCESS:
      return applyVideoExtendSuccess(state, action);
    case VIDEO_EXTEND_REQUESTED:
      return applyVideoExtendRequested(state, action);
    case VIDEO_EXTEND_FAILURE:
      return applyVideoExtendFailure(state, action);
    case VIDEO_EXTEND_CANCEL:
      return applyVideoExtendCancel(state, action);
    case VIDEO_EXTEND_COMPLETE:
      return applyVideoExtendComplete(state);
    default:
      return state;
  }
};
