export default {
  getDurationStr(startStr, endStr) {
    const startDate = new Date(startStr);
    const endDate = new Date(endStr);
    return this.formatDuration((endDate.getTime() - startDate.getTime()));
  },

  formatDuration(ms) {
    const msAbs = Math.abs(ms);
    const secs = Math.floor(msAbs / 1000);
    const hours = Math.floor(secs / 3600);
    const minutes = Math.floor(secs / 60) % 60;
    const seconds = secs % 60;
    const prefix = (ms < 0) ? '-' : '';

    return prefix + [hours, minutes, seconds]
      .map((v) => (v < 10 ? `0${v}` : v))
      .filter((v, i) => v !== '00' || i > 0)
      .join(':');
  }
};

export const minToSec = (min) => (
  min * 60
);

export const minToMs = (min) => (
  minToSec(min) * 1000
);

export const msToMin = (ms) => (
  ms / 1000 / 60
);
