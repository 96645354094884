import React from 'react';
import { videoType } from '../../types';
import VideoPresenters from './VideoPresenters';
import LabelValue from '../containers/LabelValue';
import { getVideoChannel } from '../../utils/videoUtils';
import styles from './VideoDetails.module.scss';
import VideoDescription from './VideoDescription';

const VideoDetails = ({ video }) => {
  const {
    presenters,
    scheduledStart,
    viewCount,
    accredited,
    organization
  } = video;

  return (
    <div className={styles.container}>
      <LabelValue labelId="channel" value={getVideoChannel(video)} />
      <LabelValue labelId="presenters" value={<VideoPresenters presenters={presenters} />} />
      <LabelValue labelId="date" value={scheduledStart} formatDate />
      <LabelValue labelId="organization" value={organization} />
      <LabelValue labelId="accredited" value={accredited} />
      <LabelValue labelId="viewCount" value={viewCount} />
      <LabelValue labelId="description" value={<VideoDescription video={video} />} />
    </div>
  );
};

VideoDetails.propTypes = {
  video: videoType.isRequired
};

export default VideoDetails;
