import React from 'react';
import PropTypes from 'prop-types';
import { videoType } from 'types';
import Carousel from 'components/containers/Carousel';
import VideoCard from './VideoCard';

const VideoCarousel = ({ videoList, videosPerPage }) => (
  <Carousel childrenPerPage={videosPerPage}>
    {videoList && videoList.map((video) => (
      <VideoCard
        key={video.id}
        video={video}
        displayStyle="grid"
      />
    ))}
  </Carousel>
);

VideoCarousel.propTypes = {
  videoList: PropTypes.arrayOf(videoType).isRequired,
  videosPerPage: PropTypes.number.isRequired
};

export default VideoCarousel;
