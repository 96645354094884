import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { facetValueType, intlType } from 'types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import styles from './FacetFilterValue.module.scss';
import { maxLength, translatedFacetKeys, translatedFacetValues } from '../../constants';
import Text from '../containers/Text';
import Tooltip from '../containers/Tooltip';

const FacetFilterValue = ({
  facetKey,
  facetValue,
  onChange,
  isSelected,
  intl
}) => {
  const isTranslated = (translatedFacetKeys.indexOf(facetKey) >= 0);
  const labelText = isTranslated
    ? intl.formatMessage({ id: `searchFilter_${facetValue.value}` })
    : facetValue.value;
  const isTooltipTranslated = translatedFacetValues.indexOf(facetValue.value) >= 0;
  const toolTipText = isTooltipTranslated ? `searchFilterTooltip_${facetValue.value}` : '';
  const valueText = facetValue.value;
  const ariaItemCount = intl.formatMessage({
    id: `searchFilter_itemCount${(facetValue.count === 1) ? 'Singular' : 'Plural'}`
  }, {
    count: facetValue.count
  });

  const childComponent = () => (
    <FormControlLabel
      className={styles.container}
      control={(
        <Checkbox
          checked={isSelected}
          onChange={onChange}
          name={valueText}
          color="primary"
        />
      )}
      label={(
        <>
          <Text value={labelText} maxLength={maxLength.facetValue} />
          {` (${facetValue.count})`}
        </>
      )}
      aria-label={`${labelText} (${ariaItemCount})`}
    />
  );

  const filterComponent = () => {
    if (toolTipText) {
      return (
        <Tooltip titleId={toolTipText} placement="top">
          <div>{childComponent()}</div>
        </Tooltip>
      );
    }

    return childComponent();
  };

  return (
    filterComponent()
  );
};

FacetFilterValue.propTypes = {
  facetKey: PropTypes.string.isRequired,
  facetValue: facetValueType.isRequired,
  onChange: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  intl: intlType.isRequired
};

export default injectIntl(FacetFilterValue);
