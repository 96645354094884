import { severityOptions } from 'constants/index';
import {
  NOTIFICATION_ADD,
  NOTIFICATION_HIDE,
  NOTIFICATION_REMOVE
} from '../actionTypes';

export const addNotification = (messageId, severity) => {
  // TODO: Should this kind of validation be in actions? Do we want to keep it only in non-prod builds?
  if (!messageId) {
    throw new Error(`addNotification: Invalid messageId: ${messageId}`);
  } else if (!severityOptions.includes(severity)) {
    throw new Error(`addNotification: Invalid severity: ${severity}`);
  }

  return {
    type: NOTIFICATION_ADD,
    payload: {
      messageId,
      severity
    }
  };
};

const hideNotification = (id) => ({
  type: NOTIFICATION_HIDE,
  payload: {
    id
  }
});

const removeNotification = (id) => ({
  type: NOTIFICATION_REMOVE,
  payload: {
    id
  }
});

/**
 * Call to hide a notification by ID.
 *
 * In order for notification transition animations to work, it is hidden in two steps. First it's hidden (isOpen prop
 * set to false triggering the animation start), and then it's purged from the notifications list completely so it
 * doesn't consume memory. The timeout delay below must be set higher than the animation duration.
 *
 * @param id
 * @returns {function(...[*]=)}
 */
export const hideAndRemoveNotification = (id) => (dispatch) => {
  dispatch(hideNotification(id));
  setTimeout(() => {
    dispatch(removeNotification(id));
  }, 5000);
};
