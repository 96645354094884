import {
  elementType,
  shape,
  number,
  string,
  bool,
  objectOf,
  oneOf,
  oneOfType,
  arrayOf,
  element,
  func
} from 'prop-types';
import { severityOptions } from '../constants';

/**
 * Defines common prop validation types to prevent duplication.
 */

export const cardDataType = shape({
  iconType: oneOf(['people', 'video', 'doc']),
  name: string,
  nameId: string,
  description: string,
  descriptionId: string,
  url: string
});

export const channelType = shape({
  name: string,
  url: string
});

export const childrenType = oneOfType([
  arrayOf(element),
  element
]);

export const errorType = shape({
  message: string.isRequired
});

export const errorTypeAxios = shape({
  message: string.isRequired,
  isAxiosError: bool.isRequired,
  config: shape({
    url: string.isRequired,
    method: string.isRequired,
    headers: shape({}).isRequired
  })
});

export const errorInfoType = shape({
  componentStack: string.isRequired
});

export const facetValueType = shape({
  count: number.isRequired,
  value: string.isRequired
});

export const facetsType = objectOf(
  arrayOf(facetValueType)
);

export const filtersType = objectOf(arrayOf(string.isRequired));

export const hotTopicType = shape({
  id: string.isRequired,
  keywordsType: arrayOf(string),
  name_en: string,
  name_fr: string
});

export const hotTopicsContainerType = shape({
  isLoading: bool.isRequired,
  error: errorType,
  list: arrayOf(hotTopicType)
});

export const iconType = elementType;

export const intlType = shape({
  formatMessage: func
});

export const keywordsType = arrayOf(string.isRequired);

export const locationType = shape({
  search: string.isRequired,
  pathname: string.isRequired
});

// Related to React Router's route params (i.e. match.params.[paramName])
export const matchType = shape({
  params: shape({})
});

export const notificationType = shape({
  id: number.isRequired,
  isOpen: bool.isRequired,
  messageId: string.isRequired,
  severity: oneOf(severityOptions)
});

export const userType = shape({
  email: string,
  otnguid: string,
  firstName: string,
  lastName: string,
  channels: arrayOf(string),
  ownedChannels: arrayOf(shape({
    id: string,
    code: string,
    description: string
  })),
  isAdmin: bool,
  isProducer: bool
});

export const userContainerType = shape({
  isLoading: bool.isRequired,
  error: errorType,
  user: userType
});

export const presentersType = arrayOf(string.isRequired);

// This should be as close as possible to the MT request format
export const searchParamsType = shape({
  query: string,
  filters: filtersType,
  order: string,
  page: number,
  pageSize: number
});

export const suggestionType = shape({
  '@search.text': string
});

export const videoType = shape({
  id: string.isRequired,
  name: string.isRequired,
  description: string.isRequired,
  type: oneOf(['live', 'archived', 'live_archived', 'cancelled']).isRequired,
  visibility: oneOf(['public', 'private', 'owner']).isRequired,
  accessLevel: oneOf(['producer', 'viewVideo', 'readDescription', 'none']),
  presenters: arrayOf(string.isRequired).isRequired,
  scheduledStart: string.isRequired,
  scheduledEnd: string.isRequired,
  thumbnailSource: arrayOf(string),
  closedCaptionsTTml: string,
  closedCaptionsVtt: string,
  organizerEmail: string,
  askAQuestionEmail: string,
  keywords: keywordsType,
  streamSource: arrayOf(shape({
    src: string.isRequired,
    type: string.isRequired
  })),
  viewCount: number,
  contentToken: string,
  channelCode: string,
  registrationDeadline: string,
  hostSite: string,
  hostCity: string,
  theraputicAreaOfCare: string,
  participantFee: bool,
  accredited: bool,
  additionalInstructions: string,
  eventAttachments: arrayOf(string),
  startBroadCast: bool,
  adUrl: string,
  skipAdOffset: number,
  language: oneOf(['en', 'fr']),
  expires: string
});

export const pageType = shape({
  error: errorType,
  isLoading: bool.isRequired,
  list: arrayOf(videoType)
});

export const presenterType = shape({
  presenter: string.isRequired,
  numContent: number.isRequired
});

export const presentersContainerType = shape({
  isLoading: bool.isRequired,
  error: errorType,
  list: arrayOf(presenterType)
});

export const searchResultsType = shape({
  pages: arrayOf(pageType),
  count: number,
  facets: facetsType
});

export const videoListContainerType = shape({
  isLoading: bool.isRequired,
  error: errorType,
  list: arrayOf(videoType),
  searchParams: searchParamsType
});

export const accessRequestType = shape({
  isSending: bool,
  isSent: bool,
  error: errorType
});

export const extendRequestType = shape({
  isRequested: bool,
  isExtending: bool,
  isExtended: bool,
  error: errorType
});

export const videoContainerType = shape({
  currentVideoId: string,
  currentVideo: videoType,
  isLoading: bool.isRequired,
  isUpdating: bool.isRequired,
  error: errorType,
  similar: videoListContainerType,
  accessRequest: accessRequestType,
  extendRequest: extendRequestType
});

export const suggestionsContainerType = shape({
  topWebcasts: videoListContainerType.isRequired,
  upcomingLiveEvents: videoListContainerType.isRequired,
  popularPresenters: presentersContainerType.isRequired,
  hotTopics: hotTopicsContainerType.isRequired
});
