import {
  USER_FETCH_START,
  USER_FETCH_SUCCESS,
  USER_FETCH_FAILURE,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_START,
  USER_UPDATE_FAILURE
} from 'store/actionTypes';

const initialState = {
  user: null,
  isLoading: false,
  error: null
};

const applyUserFetchStart = (state) => ({
  ...state,
  isLoading: true,
  error: null
});

const applyUserFetchSuccess = (state, action) => ({
  ...state,
  user: {
    ...action.payload.user
  },
  isLoading: false
});

const applyUserFetchFailure = (state, action) => ({
  ...state,
  error: action.payload.error,
  isLoading: false
});

const applyUserUpdateStart = (state) => ({
  ...state,
  isLoading: true
});

const applyUserUpdateSuccess = (state, action) => ({
  ...state,
  user: {
    ...state.user,
    ...action.payload.user
  },
  isLoading: false
});

const applyUserUpdateFailure = (state, action) => ({
  ...state,
  error: action.payload.error,
  isLoading: false
});

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_FETCH_START:
      return applyUserFetchStart(state);
    case USER_FETCH_SUCCESS:
      return applyUserFetchSuccess(state, action);
    case USER_FETCH_FAILURE:
      return applyUserFetchFailure(state, action);
    case USER_UPDATE_START:
      return applyUserUpdateStart(state);
    case USER_UPDATE_SUCCESS:
      return applyUserUpdateSuccess(state, action);
    case USER_UPDATE_FAILURE:
      return applyUserUpdateFailure(state, action);
    default:
      return state;
  }
};
