export const stringToDate = (string) => (
  string ? new Date(string) : null
);

export const dateToString = (date) => {
  try {
    return date ? date.toISOString() : '';
  } catch (err) {
    return '';
  }
};

export const startOfDay = (date) => {
  if (!date) {
    return '';
  }
  const start = new Date(date);
  start.setHours(0, 0, 0, 0);
  return dateToString(start);
};

export const endOfDay = (date) => {
  if (!date) {
    return '';
  }
  const end = new Date(date);
  end.setHours(23, 59, 59, 0);
  return dateToString(end);
};

/**
 * Returns true if the dates are the same, ignoring the time.
 *
 * @param dateStr1
 * @param dateStr2
 * @returns {boolean}
 */
export const isSameDate = (dateStr1, dateStr2) => {
  if (!dateStr1 || !dateStr2) {
    return dateStr1 === dateStr2;
  }
  const dropTime = (dateStr) => dateStr.substring(0, dateStr.indexOf('T'));
  return dropTime(dateStr1) === dropTime(dateStr2);
};

export const isDateBeforeNow = (dateStr) => {
  const date = stringToDate(dateStr);
  const now = new Date();
  return date.getTime() <= now.getTime();
};

export const isDateAfterNow = (dateStr) => {
  const date = stringToDate(dateStr);
  const now = new Date();
  return date.getTime() >= now.getTime();
};

export const dateToStringFull = (dateStr, locale) => (
  new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  }).format(stringToDate(dateStr))
);

export const dateToTimeStr = (date, locale) => (
  new Intl.DateTimeFormat(locale, {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  }).format(date)
);

export const getCurrentYear = () => (
  new Date().getFullYear()
);

// Converts UNIX timestamp (as used in JWT token) to JS Date object
export const utcSecondsToDate = (utcSeconds) => {
  const date = new Date(0);
  date.setUTCSeconds(utcSeconds);
  return date;
};
