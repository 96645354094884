import React from 'react';
import Badge from '../badges/Badge';
import { videoType } from '../../types';

const VideoBadges = ({ video }) => {
  const { type, audience, accessLevel } = video;
  return (
    <>
      {(type && type !== 'archived') && (
        <Badge type={type} />
      )}
      {(audience !== 'public') && (
        <Badge
          type={
            (accessLevel === 'viewVideo' || accessLevel === 'producer')
              ? 'privateUnlocked'
              : 'privateLocked'
          }
        />
      )}
    </>
  );
};

VideoBadges.propTypes = {
  video: videoType.isRequired
};

export default VideoBadges;
