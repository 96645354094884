import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

/**
 * Listens for changes to URL or URL params and scrolls to top.
 * https://reactrouter.com/web/guides/scroll-restoration
 *
 * @returns {null}
 * @constructor
 */
const ScrollToTop = () => {
  const { pathname } = useLocation();
  const params = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, params]);

  return null;
};

export default ScrollToTop;
