import { logError } from './logUtils';

const tokenKey = 'token';
const localeKey = 'locale';

export default {
  getUserToken() {
    return localStorage.getItem(tokenKey);
  },
  setUserToken(token) {
    if (!token) {
      logError('setUserToken called with empty token');
      return;
    }
    localStorage.setItem(tokenKey, token);
  },
  clearUserToken() {
    localStorage.removeItem(tokenKey);
  },
  getLocale() {
    return localStorage.getItem(localeKey);
  },
  setLocale(locale) {
    localStorage.setItem(localeKey, locale);
  }
};
