import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { severityOptions } from 'constants/index';

const Notification = ({
  open,
  messageId,
  severity,
  onClose
}) => (
  <Snackbar
    open={open}
    autoHideDuration={2000}
    onClose={onClose}
  >
    <Alert
      elevation={6}
      variant="filled"
      severity={severity}
      onClose={onClose}
    >
      <FormattedMessage id={messageId} />
    </Alert>
  </Snackbar>
);

Notification.propTypes = {
  open: PropTypes.bool.isRequired,
  messageId: PropTypes.string.isRequired,
  severity: PropTypes.oneOf(severityOptions),
  onClose: PropTypes.func.isRequired
};

Notification.defaultProps = {
  severity: 'info'
};

export default Notification;
