import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import SignInButton from './SignInButton';
import { userContainerType, intlType } from '../../types';
import SignOutButton from './SignOutButton';
import UserDetails from './UserDetails';
import styles from './UserMenu.module.scss';
import { isUserSignedIn } from '../../utils/userUtils';
import profileIcon from '../../assets/images/ContentCreator-Icon.svg';
import profileIconLoading from '../../assets/images/ContentCreator-Icon-Loading.svg';
import Image from '../containers/Image';

const UserMenu = ({
  userContainer,
  theme,
  intl
}) => {
  const { isLoading } = userContainer;
  const isSignedIn = isUserSignedIn(userContainer);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!isSignedIn && !isLoading) {
    return (<SignInButton theme={theme} />);
  }

  return (
    <>
      <Button
        aria-label={intl.formatMessage({ id: 'userProfile' })}
        onClick={handleMenu}
        disabled={isLoading}
      >
        <Image src={isLoading ? profileIconLoading : profileIcon} altId="userProfileIcon" className={styles.profileIcon} />
      </Button>
      <Popover
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
      >
        <UserDetails className={styles.userDetailsContainer} />
        <SignOutButton />
      </Popover>
    </>
  );
};

UserMenu.propTypes = {
  userContainer: userContainerType,
  theme: PropTypes.oneOf(['normal', 'dark']),
  intl: intlType.isRequired
};

UserMenu.defaultProps = {
  userContainer: {},
  theme: 'normal'
};

const mapStateToProps = (state) => ({
  userContainer: state.user
});

export default connect(mapStateToProps, {})(injectIntl(UserMenu));
