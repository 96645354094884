import { apiGet, apiPost } from './apiService';
import { apiUrls } from '../../constants';

export default {
  getMyUser: () => apiGet(
    `${apiUrls.root}authentication/me`
  ),
  addChannelToProfile: (channelCode) => apiPost(
    `${apiUrls.root}authentication/me/channel`, {
      channelCode
    }
  ),
  guestSignIn: (channelCode) => apiGet(
    `${apiUrls.root}authentication/guest/channel?channelCode=${channelCode}`
  )
};
