import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import IconButton from 'components/buttons/IconButton';
import { Alert, AlertTitle } from '@material-ui/lab';
import ErrorDetails from './ErrorDetails';
import { errorInfoType, errorType } from '../../types';
import styles from './InlineError.module.scss';

const InlineError = ({
  messageId,
  error,
  errorInfo
}) => {
  const [isExpanded, setExpanded] = React.useState(false);

  const toggleExpanded = () => {
    setExpanded(!isExpanded);
  };

  const isDetailsShown = error && !error.isUserError;
  const finalMessageId = (error && error.messageId) ? error.messageId : messageId;

  return (
    <div className={styles.container}>
      <Alert
        severity="error"
        classes={{
          standardError: styles.alertContainer,
          icon: styles.iconContainer,
          message: styles.alertMessage
        }}
      >
        <AlertTitle>
          <FormattedMessage id="error" />
        </AlertTitle>
        <FormattedMessage id={finalMessageId} />
        {isDetailsShown && (
          <div className={styles.details}>
            {isExpanded
              ? (
                <IconButton
                  icon={ExpandLess}
                  size="small"
                  onClick={toggleExpanded}
                  labelId="detailsHide"
                />
              )
              : (
                <IconButton
                  icon={ExpandMore}
                  size="small"
                  onClick={toggleExpanded}
                  labelId="detailsShow"
                />
              )}
            <Collapse in={isExpanded}>
              <ErrorDetails error={error} errorInfo={errorInfo} />
            </Collapse>
          </div>
        )}
      </Alert>
    </div>
  );
};

InlineError.propTypes = {
  messageId: PropTypes.string.isRequired,
  error: errorType,
  errorInfo: errorInfoType
};

InlineError.defaultProps = {
  error: undefined,
  errorInfo: undefined
};

export default InlineError;
