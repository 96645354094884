import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './Footer.module.scss';
import otnLogo from '../../assets/images/otn-logo-white.svg';
import ontarioLogo from '../../assets/images/ontario-logo.png';
import Image from '../containers/Image';
import TextLink from '../containers/TextLink';
import { links } from '../../constants';
import { getCurrentYear } from '../../utils/dateUtils';

const Footer = () => (
  <footer role="contentinfo" className={styles.container}>
    <div className={styles.innerContainer}>
      <ul className={styles.footerLinkContainer}>
        <li><TextLink src="/" labelId="menuHome" /></li>
        <li><TextLink src={links.help} srcFr={links.helpFr} labelId="menuHelp" newTab /></li>
        <li><TextLink src={links.otnCorporate} labelId="otnca" /></li>
        <li><TextLink src={links.otnHub} labelId="otnhub" /></li>
      </ul>
      <hr />
      <div className={styles.infoContainer}>
        <p>
          <FormattedMessage
            id="footerInfo"
            values={{
              year: getCurrentYear(),
              linkPolicy: () => (
                <TextLink src={links.policy} srcFr={links.policyFr} labelId="linkPolicy" />
              ),
              linkAcceptableUse: () => (
                <TextLink src={links.acceptableUse} srcFr={links.acceptableUseFr} labelId="linkAcceptableUse" />
              )
            }}
          />
        </p>
      </div>
      <hr />
      <div className={styles.bottomContainer}>
        <div className={`${styles.logoContainer} ${styles.otnLogo}`}>
          <Image src={otnLogo} altId="otnLogo" width="101" height="38" />
        </div>
        <p>
          <FormattedMessage id="footerDisclaimer" />
        </p>
        <div className={`${styles.logoContainer} ${styles.ontarioLogo}`}>
          <Image src={ontarioLogo} altId="ontarioLogo" width="108" height="43" />
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
