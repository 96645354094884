import React from 'react';
import PropTypes from 'prop-types';

const LinkArray = ({ array }) => (
  <>
    {array && array.map((element, index) => (
      <span key={element}>
        <a href={element} target="_blank" rel="noopener noreferrer">{element}</a>
        {index < array.length - 1 ? ', ' : ''}
      </span>
    ))}
  </>
);

LinkArray.propTypes = {
  array: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default LinkArray;
