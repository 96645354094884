import React from 'react';
import { videoType } from 'types';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import CutSlider from './CutSlider';
import VideoPlayer from '../videos/VideoPlayer';
import styles from './VideoEditor.module.scss';

const VideoEditor = ({ video }) => {
  const initCutLength = 10;
  const [currentTime, setCurrentTime] = React.useState(0);
  const [duration, setDuration] = React.useState(null);
  const [trim, setTrim] = React.useState(null);
  const [cuts, setCuts] = React.useState([]);

  const handleDurationChange = (newDuration) => {
    setDuration(newDuration);
    setTrim([initCutLength, newDuration - initCutLength]);
  };

  const handleAddCut = () => {
    const start = (cuts.length > 0) ? cuts[cuts.length - 1][1] : trim[0];
    const end = Math.min(start + initCutLength, trim[1]);
    setCuts([
      ...cuts,
      [start, end]
    ]);
  };

  const handleDeleteCut = (index) => {
    const newCuts = [...cuts];
    newCuts.splice(index, 1);
    setCuts(newCuts);
  };

  const handleChangeTrim = (newValue, valueTouched) => {
    setTrim(newValue);
    if (valueTouched) {
      setCurrentTime(valueTouched);
    }
  };

  const handleChangeCut = (index, newValue, valueTouched) => {
    const newCuts = [...cuts];
    newCuts[index] = newValue;
    setCuts(newCuts);
    if (valueTouched) {
      setCurrentTime(valueTouched);
    }
  };

  if (!video) {
    return null;
  }

  // TODO: Phase2: Set key properly
  return (
    <div className={styles.container}>
      <div className={styles.playerContainer}>
        <VideoPlayer
          currentTime={currentTime}
          onDurationChanged={handleDurationChange}
        />
      </div>

      {(duration && trim) && (
        <div className={styles.cutPanel}>
          <CutSlider
            type="trim"
            max={duration}
            initialValue={trim}
            onChange={handleChangeTrim}
          />
          {cuts.map((cutValue, index) => (
            /* eslint-disable react/no-array-index-key */
            <CutSlider
              key={index}
              type="cut"
              max={duration}
              initialValue={cutValue}
              onChange={(newValue, valueTouched) => handleChangeCut(index, newValue, valueTouched)}
              onDeleteCut={() => handleDeleteCut(index)}
            />
            /* eslint-enable react/no-array-index-key */
          ))}
          <div>
            <Button
              onClick={handleAddCut}
              className={styles.button}
              startIcon={<AddIcon />}
            >
              {/* TODO: Phase2: Translate */}
              Add Cut
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

VideoEditor.propTypes = {
  video: videoType
};

VideoEditor.defaultProps = {
  video: null
};

export default VideoEditor;
