import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import VideoPlayer from 'components/videos/VideoPlayer';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import { fetchVideo, fetchSimilar } from 'store/actions/videoActions';
import {
  fetchChannelVideos,
  fetchOrganizationVideos
} from 'store/actions/suggestionsActions';
import {
  matchType,
  suggestionsContainerType,
  videoContainerType
} from 'types';
import { Helmet } from 'react-helmet';
import ExpiryErrorBanner from 'components/banner/ExpiryErrorBanner';
import VideoSection from '../components/videos/VideoSection';
import {
  getVideoOrganization,
  getVideoChannel,
  selectThumbnail
} from '../utils/videoUtils';
import { channelToContainerName, organizationToContainerName } from '../utils/suggestionsUtils';
import { isContainerNotInitialized } from '../utils/objectUtils';
import { getAltLocale } from '../utils/localeUtils';
import videoService from '../store/services/videoService';
import { ontarioHealthTwitter } from '../constants';
import VideoDetailsFull from '../components/videos/VideoDetailsFull';
import VideoBadges from '../components/videos/VideoBadges';
import VideoButtons from '../components/videos/VideoButtons';
import styles from './Watch.module.scss';
import ExpiryBanner from '../components/banner/ExpiryBanner';

const Watch = ({
  fetchVideoAction,
  fetchSimilarAction,
  fetchChannelVideosAction,
  fetchOrganizationVideosAction,
  match,
  video,
  suggestionsContainer,
  locale
}) => {
  const {
    isLoading,
    currentVideo,
    error,
    similar
  } = video;
  const isSuccess = !isLoading && !error && !!currentVideo;
  const channelSuggestions = currentVideo ? suggestionsContainer[channelToContainerName(currentVideo.audience)] : undefined;
  const orgSuggestions = (currentVideo && currentVideo.organization) ? suggestionsContainer[organizationToContainerName(currentVideo.organization)] : undefined;
  const [viewId] = React.useState(`${Math.floor(Math.random() * 10000000000)}`);

  const fetchVideoIfNeeded = () => {
    const urlVideoId = match.params.videoId;
    if (video.currentVideoId !== urlVideoId) {
      fetchVideoAction(urlVideoId);
      fetchSimilarAction(urlVideoId);
    }
    const channel = getVideoChannel(currentVideo);
    const organization = getVideoOrganization(currentVideo);
    if (channel && isContainerNotInitialized(channelSuggestions)) {
      fetchChannelVideosAction(channel);
    }
    if (organization && isContainerNotInitialized(orgSuggestions)) {
      fetchOrganizationVideosAction(organization);
    }
  };

  // TODO: Is it acceptable to call a service directly and not through a Redux action? Or is there a cleaner way?
  // Note: This is called when the video is started and resumed, and it will force a token refresh (WEBCAST-400)
  const onStart = () => {
    videoService.logViewStart(video.currentVideoId, viewId);
  };

  // Called every quarter of video watched. Logs time watched in mins to MT.
  const onPlaybackState = (state, durationSecs) => {
    const durationMins = Math.floor(durationSecs / 60);
    if (durationMins > 0) {
      videoService.logViewDuration(video.currentVideoId, durationMins, viewId);
    }
  };

  const onTimeUpdate = (durationSecs) => {
    onPlaybackState(null, durationSecs);
  };

  // Called every time full screen is toggled. Logs this to MT.
  const onFullScreenState = (state) => {
    const isFullScreen = (state === 'fullscreen');
    videoService.logViewFullscreen(video.currentVideoId, isFullScreen, viewId);
  };

  useEffect(() => {
    fetchVideoIfNeeded();
  });

  return (
    <>
      {currentVideo && (
        <Helmet>
          <title>{currentVideo.name}</title>
          <meta name="description" content={currentVideo.description} />
          <meta name="robots" content={(currentVideo.audience === 'public') ? 'index, follow' : 'noindex, nofollow'} />

          <meta property="og:title" content={currentVideo.name} />
          <meta property="og:type" content="video.movie" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image" content={selectThumbnail(currentVideo.thumbnailSource)} />
          <meta property="og:site_name" content="Ontario Health (OTN) Webcast Centre" />
          <meta property="og:locale" content={locale} />
          <meta property="og:locale:alternate" content={getAltLocale(locale)} />

          <meta name="twitter:card" content={currentVideo.description} />
          <meta name="twitter:site" content={ontarioHealthTwitter} />
          <meta name="twitter:creator" content={ontarioHealthTwitter} />
        </Helmet>
      )}

      {(!error || !error.response || !error.response.data === 'expired') && (
        <div className={styles.playerSection}>
          <LoadingWrapper isLoading={isLoading} error={error} errorMessageId="videoLoadError">
            <div className={styles.playerContainer}>
              <VideoPlayer
                onStart={onStart}
                onResume={onStart}
                onPlaybackState={onPlaybackState}
                onTimeUpdate={onTimeUpdate}
                onFullScreenState={onFullScreenState}
              />
            </div>
          </LoadingWrapper>
        </div>
      )}
      {(error && error.response && error.response.data === 'expired') && (
        <div className={styles.expiryContainer}>
          <ExpiryErrorBanner />
        </div>
      )}
      {(currentVideo && currentVideo.accessLevel === 'producer') && (
        <div className={styles.expiryContainer}>
          <ExpiryBanner video={currentVideo} />
        </div>
      )}

      <div className={styles.detailsContainer}>
        {isSuccess && (
          <>
            <div className={styles.headingContainer}>
              <div className={styles.titleContainer}>
                <h1 className={styles.title}>
                  {currentVideo.name}
                </h1>
                <VideoBadges video={currentVideo} />
              </div>
              <VideoButtons video={currentVideo} match={match} />
            </div>
            <VideoDetailsFull video={currentVideo} />
          </>
        )}
      </div>

      {isSuccess && (
        <>
          <VideoSection
            headingId="moreFromThisOrganization"
            videoContainer={orgSuggestions}
            excludeVideoId={currentVideo.id}
            hideIfEmpty
          />
          <VideoSection
            headingId="moreFromThisChannel"
            videoContainer={channelSuggestions}
            excludeVideoId={currentVideo.id}
            hideIfEmpty
          />
          <VideoSection
            headingId="suggestedWebcasts"
            videoContainer={similar}
            excludeVideoId={currentVideo.id}
            hideIfEmpty
          />
        </>
      )}
    </>
  );
};

Watch.propTypes = {
  fetchVideoAction: PropTypes.func.isRequired,
  fetchSimilarAction: PropTypes.func.isRequired,
  fetchChannelVideosAction: PropTypes.func.isRequired,
  fetchOrganizationVideosAction: PropTypes.func.isRequired,
  match: matchType.isRequired,
  video: videoContainerType.isRequired,
  suggestionsContainer: suggestionsContainerType.isRequired,
  locale: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  video: state.video,
  user: state.user.user,
  suggestionsContainer: state.suggestions,
  locale: state.locale.locale
});

export default connect(mapStateToProps, {
  fetchVideoAction: fetchVideo,
  fetchSimilarAction: fetchSimilar,
  fetchChannelVideosAction: fetchChannelVideos,
  fetchOrganizationVideosAction: fetchOrganizationVideos
})(Watch);
