import React from 'react';
import { useHistory } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Search } from '@material-ui/icons';
import { searchVideoChangeQuery, searchVideoApplyQuery, searchVideoChangeQueryAndApply } from 'store/actions/searchActions';
import { intlType, suggestionType } from 'types';
import { suggestionRemoveTags, parseSuggestions } from 'utils/searchUtils';
import IconButton from '../buttons/IconButton';
import styles from './SearchBox.module.scss';
import SearchOption from './SearchOption';

const SearchBox = ({
  intl,
  formQuery,
  suggestions,
  changeQueryAction,
  executeSearchAction,
  changeQueryAndSearchAction,
  theme,
  onSearch,
  className
}) => {
  const history = useHistory();

  const unfocus = () => document.activeElement && document.activeElement.blur();

  // If you don't use typeahead, the option is a string. If you do, it's an object {id, text}.
  const getOptionLabel = (option) => {
    if (!option) {
      return '';
    }
    if (typeof option === 'string') {
      return option;
    }
    return option.id ? '' : suggestionRemoveTags(option.text);
  };

  const handleInputChange = (event, value) => {
    changeQueryAction(value);
  };

  const handleTypeaheadSelect = (event, value) => {
    // A video object is selected. Navigate to the video straight away
    if (value && value.id) {
      history.push(`/videos/${value.id}`);
      unfocus();
      return;
    }

    // If query is not empty, execute search right away
    const query = getOptionLabel(value);
    if (query) {
      changeQueryAndSearchAction(query, history);
      unfocus();
    }

    changeQueryAction(query);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    executeSearchAction(history);
    unfocus();
    onSearch();
  };

  const parsedSuggestions = parseSuggestions(suggestions);

  /*
  Because the MT implements a fuzzy search, we define a custom filter function (filterOptions prop) that always returns
  all options. Without it, Autocomplete will do FE filtering based on the optionLabel and our fuzzy results will get
  filtered out.
   */
  return (
    <form className={`${theme === 'dark' ? styles.containerDark : styles.container} ${className}`} onSubmit={handleSubmit}>
      <Autocomplete
        freeSolo
        size="small"
        options={parsedSuggestions}
        getOptionLabel={getOptionLabel}
        renderOption={(option) => (
          <SearchOption option={option} />
        )}
        filterOptions={(options) => [...options]}
        classes={{
          root: styles.inputContainer,
          inputRoot: styles.input
        }}
        onChange={handleTypeaheadSelect}
        onInputChange={handleInputChange}
        value={formQuery}
        renderInput={(params) => (
          <TextField
            {...params}
            label={intl.formatMessage({ id: 'search' })}
            variant="outlined"
          />
        )}
      />
      <IconButton
        type="submit"
        className={styles.button}
        icon={Search}
        titleId="searchDetails"
      />
    </form>
  );
};

SearchBox.propTypes = {
  theme: PropTypes.oneOf(['normal', 'dark']),
  formQuery: PropTypes.string.isRequired,
  suggestions: PropTypes.arrayOf(suggestionType),
  changeQueryAction: PropTypes.func.isRequired,
  executeSearchAction: PropTypes.func.isRequired,
  changeQueryAndSearchAction: PropTypes.func.isRequired,
  intl: intlType.isRequired,
  onSearch: PropTypes.func,
  className: PropTypes.string
};

SearchBox.defaultProps = {
  theme: 'normal',
  suggestions: [],
  onSearch: () => {},
  className: ''
};

const mapStateToProps = (state) => {
  const { search } = state || {};
  return {
    formQuery: search.searchParamsForm.query,
    suggestions: search.suggestions
  };
};

const mapDispatchToProps = {
  changeQueryAction: searchVideoChangeQuery,
  executeSearchAction: searchVideoApplyQuery,
  changeQueryAndSearchAction: searchVideoChangeQueryAndApply
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SearchBox));
