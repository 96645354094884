import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './ExpiryErrorBanner.module.scss';
import Image from '../containers/Image';
import expiredIcon from '../../assets/images/Expired-Icon.svg';

const ExpiryErrorBanner = () => {
  const iconUrl = expiredIcon;
  const expiryClass = styles.expired;
  const expiryLabelClass = styles.expiredLabel;
  const labelCode = 'expired';

  return (
    <div className={`${styles.container} ${expiryClass}`}>
      <Image className={styles.icon} altId={`expiryBadgeIcon_${labelCode}`} src={iconUrl} ariaHidden />
      <span className={`${styles.label} ${expiryLabelClass}`}>
        <FormattedMessage id="expiryErrorBannerLabel" />
      </span>
      <div className={styles.bodyContainer}>
        <span className={styles.infoContainer}>
          <FormattedMessage id="expiryErrorBannerBody" />
          <br />
          <FormattedMessage id="expiryErrorBannerBodySecondary" />
        </span>
      </div>
    </div>
  );
};

export default ExpiryErrorBanner;
