import { combineReducers } from 'redux';
import searchReducer from './searchReducer';
import suggestionsReducer from './suggestionsReducer';
import videoReducer from './videoReducer';
import notificationsReducer from './notificationsReducer';
import localeReducer from './localeReducer';
import userReducer from './userReducer';

const appReducer = combineReducers({
  locale: localeReducer,
  notifications: notificationsReducer,
  search: searchReducer,
  suggestions: suggestionsReducer,
  user: userReducer,
  video: videoReducer
});

// This is no longer needed, since logout is done as a redirect so the app state resets as a result of that
/*
const rootReducer = (state, action) => {
  // Resets state after logout (https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store)
  const appState = (action.type === USER_LOGOUT_SUCCESS) ? undefined : state;
  return appReducer(appState, action);
};
*/

export default appReducer;
