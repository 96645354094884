import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { dateToTimeStr } from '../../utils/dateUtils';
import timeUtils from '../../utils/timeUtils';
import styles from './VideoCountdown.module.scss';
import Loading from '../loading/Loading';

const VideoCountdown = ({ startDate, msToStart, locale }) => {
  const msToStartNormalized = Math.max(msToStart, 0);
  return (
    <div className={styles.container}>
      <h2><FormattedMessage id="liveCountdownHeading" /></h2>
      <p><FormattedMessage id="liveCountdownText" values={{ startTime: dateToTimeStr(startDate, locale) }} /></p>
      {(msToStartNormalized > 0) ? (
        <p className={styles.timeRemaining}>{timeUtils.formatDuration(msToStartNormalized)}</p>
      ) : (
        <Loading />
      )}
    </div>
  );
};

VideoCountdown.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  msToStart: PropTypes.number.isRequired,
  locale: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  locale: state.locale.locale
});

export default connect(mapStateToProps, {})(injectIntl(VideoCountdown));
