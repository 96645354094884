import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import LoadingWrapper from '../loading/LoadingWrapper';
import SearchRedirectButton from '../buttons/SearchRedirectButton';
import { videoListContainerType } from '../../types';
import { isEmpty } from '../../utils/arrayUtils';
import VideoCarousel from './VideoCarousel';
import styles from './VideoSection.module.scss';
import hooks from '../../hooks';
import { cssVariables } from '../../constants';
import stringUtils from '../../utils/stringUtils';

const VideoSection = ({
  headingId,
  heading,
  videoContainer,
  hideIfEmpty,
  hideViewAll,
  excludeVideoId
}) => {
  const [width] = hooks.useWindowSize();

  if (!videoContainer) {
    return null;
  }

  const screenSizeSmall = stringUtils.sizePxToNumber(cssVariables.screenSizeSmall);
  const screenSizeMedium = stringUtils.sizePxToNumber(cssVariables.screenSizeMedium);
  const screenSizeLarge = stringUtils.sizePxToNumber(cssVariables.screenSizeLarge);

  let videosPerPage = 4;
  if (width < screenSizeSmall) {
    videosPerPage = 1;
  } else if (width < screenSizeMedium) {
    videosPerPage = 2;
  } else if (width < screenSizeLarge) {
    videosPerPage = 3;
  }

  const {
    searchParams,
    isLoading,
    error,
    list
  } = videoContainer;

  const videoList = list
    ? list.filter((video) => video.id !== excludeVideoId)
    : [];

  /*
  TODO: If hideIfEmpty is true, the loading indicator will no longer be shown (to avoid the whole section appearing,
   then disappearing). Is this acceptable? Or is there a better way to handle this?
   */
  if (hideIfEmpty && isEmpty(videoList)) {
    return null;
  }

  const showViewAll = (
    searchParams
    && !hideViewAll
    && videoList
    && videoList.length > videosPerPage
  );

  return (
    <section className={styles.container}>
      <div className={styles.headingContainer}>
        <h2 className={styles.heading}>
          {headingId ? <FormattedMessage id={headingId} /> : heading}
        </h2>
        {(showViewAll) && (
          <SearchRedirectButton className={styles.viewAllButton} labelId="viewAll" searchParams={searchParams} />
        )}
      </div>
      <LoadingWrapper isLoading={isLoading} error={error} errorMessageId="suggestionsError">
        <VideoCarousel videoList={videoList || []} videosPerPage={videosPerPage} />
      </LoadingWrapper>
    </section>
  );
};

/*
- headingId: Used to fetch the translated string from messages files
- heading: Used to display as-is (if translated string comes from MT)
- excludeVideoId: Pass in video id to exclude from the section
 */
VideoSection.propTypes = {
  headingId: PropTypes.string,
  heading: PropTypes.string,
  videoContainer: videoListContainerType,
  hideIfEmpty: PropTypes.bool,
  hideViewAll: PropTypes.bool,
  excludeVideoId: PropTypes.string
};

VideoSection.defaultProps = {
  headingId: null,
  heading: null,
  videoContainer: null,
  hideIfEmpty: false,
  hideViewAll: false,
  excludeVideoId: null
};

export default VideoSection;
