import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import Badge from 'components/badges/Badge';
import { Button } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { addNotification } from 'store/actions/notificationsActions';
import { severityOptions } from 'constants/index';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '../components/buttons/IconButton';
import LoadingWrapper from '../components/loading/LoadingWrapper';
import styles from './UserInterface.module.scss';

const OptionContainer = ({
  displayStyle,
  example,
  usage,
  usageTooltip
}) => (
  <div className={`${styles.optionContainer} ${displayStyle === 'list' ? styles.optionContainerList : ''}`}>
    <div className={styles.example}>
      {example}
    </div>
    <Tooltip title={usageTooltip} placement="bottom-start">
      <div className={styles.usage}>
        {usage}
      </div>
    </Tooltip>
  </div>
);

OptionContainer.propTypes = {
  displayStyle: PropTypes.oneOf(['list', 'grid']).isRequired,
  example: PropTypes.shape({}).isRequired,
  usage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]).isRequired,
  usageTooltip: PropTypes.string.isRequired
};

const DemoVariables = ({ component, options, displayStyle }) => (
  options.map((option) => (
    <OptionContainer
      key={option.variable}
      displayStyle={displayStyle}
      example={React.cloneElement(component, { className: `${component.props.className} ${option.className}` })}
      usage={option.variable}
      usageTooltip="SASS Variable"
    />
  ))
);

DemoVariables.propTypes = {
  component: PropTypes.shape({
    props: PropTypes.shape({
      className: PropTypes.string
    })
  }).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  displayStyle: PropTypes.oneOf(['list', 'grid'])
};

DemoVariables.defaultProps = {
  displayStyle: 'grid'
};

const DemoPropOptions = ({ component, propOptions, displayStyle }) => (
  propOptions.map((option) => (
    <OptionContainer
      key={JSON.stringify(option)}
      displayStyle={displayStyle}
      example={React.cloneElement(component, option)}
      usage={Object.entries(option).map(([name, value]) => (
        <p key={name}>{`${name}=${value}`}</p>
      ))}
      usageTooltip="Component prop"
    />
  ))
);

DemoPropOptions.propTypes = {
  component: PropTypes.shape({}).isRequired,
  propOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  displayStyle: PropTypes.oneOf(['list', 'grid'])
};

DemoPropOptions.defaultProps = {
  displayStyle: 'grid'
};

/**
 * A page to demo all small User Interface components.
 *
 * Purpose:
 * 1) Quickly test core UI for styling issues
 * 2) Teach devs on components that are available and how to use them
 *
 * TODO: P1: Consider excluding this from the prod build if possible. At the least make sure this route isn't crawled.
 *  Long-term, this should probably be its own project.
 *
 * @param addNotificationAction
 * @returns {*}
 * @constructor
 */
const UserInterface = ({ addNotificationAction }) => (
  <div className={styles.container}>
    <Helmet>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>

    <h2 className={styles.heading}>UI Components Demo</h2>

    <section className={styles.section}>
      <h3>Colours</h3>
      <DemoVariables
        component={<div className={styles.colorContainer}>&nbsp;</div>}
        options={[{
          variable: '$color-primary',
          className: styles.colorPrimary
        }, {
          variable: '$color-active',
          className: styles.colorActive
        }, {
          variable: '$color-dark',
          className: styles.colorDark
        }, {
          variable: '$color-dark-active',
          className: styles.colorDarkActive
        }, {
          variable: '$color-error',
          className: styles.colorError
        }]}
      />
    </section>

    <section className={styles.section}>
      <h3>Typography</h3>
      <DemoVariables
        component={<span>Example of the font size</span>}
        options={[{
          variable: '$font-size-xlarge',
          className: styles.fontXlarge
        }, {
          variable: '$font-size-vlarge',
          className: styles.fontVlarge
        }, {
          variable: '$font-size-large',
          className: styles.fontLarge
        }, {
          variable: '$font-size-med',
          className: styles.fontMedium
        }, {
          variable: '$font-size-default',
          className: styles.fontDefault
        }, {
          variable: '$font-size-small',
          className: styles.fontSmall
        }]}
        displayStyle="list"
      />
    </section>

    <section className={styles.section}>
      <h3>Badges</h3>
      <DemoPropOptions
        component={<Badge />}
        propOptions={[
          { type: 'live' },
          { type: 'live_archived' },
          { type: 'privateLocked' },
          { type: 'privateUnlocked' },
          { type: 'cancelled' }
        ]}
      />
    </section>

    <section className={styles.section}>
      <h3>Buttons</h3>

      <h4>Label or Tooltip</h4>
      <DemoPropOptions
        component={<IconButton type="button" icon={Search} />}
        propOptions={[
          { labelId: 'search' },
          { titleId: 'search' }
        ]}
      />

      <h4>Style Variants</h4>
      <DemoPropOptions
        component={<IconButton type="button" icon={Search} labelId="search" />}
        propOptions={[
          { variant: 'contained' },
          { variant: 'outlined' },
          { variant: 'text' }
        ]}
      />

      <h4>Sizes</h4>
      <DemoPropOptions
        component={<IconButton type="button" icon={Search} labelId="search" />}
        propOptions={[
          { size: 'large' },
          { size: 'medium' },
          { size: 'small' }
        ]}
      />
    </section>

    <section className={styles.section}>
      <h3>Data Loading</h3>

      <DemoPropOptions
        component={(
          <LoadingWrapper isLoading error={null} errorMessageId="suggestionsError">
            <p>Data...</p>
          </LoadingWrapper>
        )}
        propOptions={[
          { isLoading: true, error: null },
          { isLoading: false, error: new Error('Oops') },
          { isLoading: false, error: null }
        ]}
        displayStyle="list"
      />
    </section>

    {/* TODO: Make this into a component like DemoPropOptions */}
    <section className={styles.section}>
      <h3>Notifications</h3>
      {severityOptions.map((severity) => (
        <OptionContainer
          key={severity}
          example={(
            <Button type="button" onClick={() => addNotificationAction('appName', severity)}>
              {severity}
            </Button>
          )}
          usage={`addNotification('msgKey', '${severity}')`}
          usageTooltip="Redux action call"
          displayStyle="grid"
        />
      ))}
    </section>
  </div>
);

UserInterface.propTypes = {
  addNotificationAction: PropTypes.func.isRequired
};

export default connect(null, { addNotificationAction: addNotification })(UserInterface);
