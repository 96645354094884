import { localeEnCa, localeFrCa, locales } from '../constants';
import localStorageUtils from './localStorageUtils';

/**
 * Returns the locale that most closely matches that of the browser.
 *
 * @param string
 * @returns {any}
 */
const getBrowserLocale = () => {
  let selectedLocale = locales[0];
  if (!navigator || !navigator.language) {
    return selectedLocale;
  }
  // Could be "fr-FR", etc. Ignore country code, match language.
  const browserLocale = navigator.language;
  const browserLanguage = browserLocale.split('-')[0];
  locales.forEach((locale) => {
    if (locale.code.indexOf(browserLanguage) === 0) {
      selectedLocale = locale;
    }
  });
  return selectedLocale;
};

/**
 * @returns {string} Preferred locale code (i.e. "en-CA")
 */
export const getPreferredLocale = () => {
  const storedLocaleCode = localStorageUtils.getLocale();
  const browserLocale = getBrowserLocale();

  if (storedLocaleCode) {
    return storedLocaleCode;
  }
  if (browserLocale && browserLocale.code) {
    return browserLocale.code;
  }
  return locales[0].code;
};

/**
 * @param localeCode
 * @returns {string} The "opposite" of the given locale. If given en - returns fr, and vide versa.
 */
export const getAltLocale = (localeCode) => ((localeEnCa === localeCode) ? localeFrCa : localeEnCa);
