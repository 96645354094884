import {
  SEARCH_VIDEO_CHANGE_QUERY,
  SEARCH_VIDEO_SUGGESTIONS_SUCCESS,
  SEARCH_VIDEO_SUGGESTIONS_FAILURE,
  SEARCH_VIDEO_CHANGE_FILTER,
  SEARCH_VIDEO_FAILURE,
  SEARCH_VIDEO,
  SEARCH_VIDEO_SUCCESS,
  SEARCH_VIDEO_NEXT_PAGE,
  SEARCH_VIDEO_NEXT_PAGE_SUCCESS,
  SEARCH_VIDEO_NEXT_PAGE_FAILURE,
  SEARCH_VIDEO_ORDER,
  SEARCH_VIDEO_FACET_ADD,
  SEARCH_VIDEO_FACET_REMOVE,
  SEARCH_VIDEO_APPLY_QUERY,
  SEARCH_VIDEO_APPLY_FILTERS, SEARCH_VIDEO_CLEAR_FILTERS
} from 'store/actionTypes';
import { throttle } from 'throttle-debounce';
import searchService from '../services/searchService';
import { apiThrottleDelay, routes } from '../../constants';
import searchUtils from '../../utils/searchUtils';

// TODO: Consider removing the searchVideo prefix or at least renaming to search
export const searchVideoChangeQueryStart = (query) => ({
  type: SEARCH_VIDEO_CHANGE_QUERY,
  payload: {
    query
  }
});

export const searchVideoSuggestionsSuccess = (query, results) => ({
  type: SEARCH_VIDEO_SUGGESTIONS_SUCCESS,
  payload: {
    query,
    results
  }
});

export const searchVideoSuggestionsFailure = (query, error) => ({
  type: SEARCH_VIDEO_SUGGESTIONS_FAILURE,
  payload: {
    query,
    error
  }
});

const fetchSuggestions = (query, dispatch) => {
  if (query) {
    searchService.fetchSuggestions(query)
      .then((results) => dispatch(searchVideoSuggestionsSuccess(query, results.value)))
      .catch((error) => dispatch(searchVideoSuggestionsFailure(query, error)));
  } else {
    dispatch(searchVideoSuggestionsSuccess(query, []));
  }
};

const fetchSuggestionsThrottled = throttle(apiThrottleDelay, fetchSuggestions);

export const searchVideoChangeQuery = (query) => (dispatch) => {
  dispatch(searchVideoChangeQueryStart(query));
  fetchSuggestionsThrottled(query, dispatch);
};

export const searchVideoChangeFilter = (fieldName, value) => ({
  type: SEARCH_VIDEO_CHANGE_FILTER,
  payload: {
    fieldName,
    value
  }
});

const searchVideoStart = (searchParams) => ({
  type: SEARCH_VIDEO,
  payload: {
    searchParams
  }
});

const searchVideoSuccess = (results) => ({
  type: SEARCH_VIDEO_SUCCESS,
  payload: {
    results
  }
});

const searchVideoFailure = (error) => ({
  type: SEARCH_VIDEO_FAILURE,
  payload: {
    error
  }
});

/**
 * Call with searchParams to update the params and search; Call without to re-run the same search
 * using searchParams in state.
 *
 * @param searchParams
 * @returns {function(...[*]=)}
 */
export const executeSearch = (searchParams) => (dispatch, getState) => {
  const newSearchParams = searchParams || getState().search.searchParams;
  dispatch(searchVideoStart(newSearchParams));
  searchService.searchVideo(newSearchParams)
    .then((results) => dispatch(searchVideoSuccess(results)))
    .catch((error) => dispatch(searchVideoFailure(error)));
};

const searchVideoNextPageStart = (searchParams) => ({
  type: SEARCH_VIDEO_NEXT_PAGE,
  payload: {
    searchParams
  }
});

const searchVideoNextPageSuccess = (results) => ({
  type: SEARCH_VIDEO_NEXT_PAGE_SUCCESS,
  payload: {
    results
  }
});

const searchVideoNextPageFailure = (error) => ({
  type: SEARCH_VIDEO_NEXT_PAGE_FAILURE,
  payload: {
    error
  }
});

export const searchVideoNextPage = () => (dispatch, getState) => {
  const { searchParams, results } = getState().search;
  dispatch(searchVideoNextPageStart(searchParams));
  searchService.searchVideo(searchParams, results.pages.length)
    .then((newResults) => dispatch(searchVideoNextPageSuccess(newResults)))
    .catch((error) => dispatch(searchVideoNextPageFailure(error)));
};

// TODO: Rename
const searchVideoApplyQuerySet = () => ({
  type: SEARCH_VIDEO_APPLY_QUERY
});

const searchVideoOrderSet = (fieldName) => ({
  type: SEARCH_VIDEO_ORDER,
  payload: {
    fieldName
  }
});

const searchVideoFacetAdd = (fieldName, value) => ({
  type: SEARCH_VIDEO_FACET_ADD,
  payload: {
    fieldName,
    value
  }
});

const searchVideoFacetRemove = (fieldName, value) => ({
  type: SEARCH_VIDEO_FACET_REMOVE,
  payload: {
    fieldName,
    value
  }
});

const searchVideoApplyFiltersSet = (fieldNames) => ({
  type: SEARCH_VIDEO_APPLY_FILTERS,
  payload: {
    fieldNames
  }
});

const searchVideoClearFiltersSet = () => ({
  type: SEARCH_VIDEO_CLEAR_FILTERS,
  payload: {}
});

const updateUrlAndSearch = (getState, history) => {
  const { searchParams } = getState().search;
  const urlParams = searchUtils.searchParamsToUrl(searchParams);
  history.push(routes.results + urlParams);
  return executeSearch(searchParams);
};

export const searchVideoApplyQuery = (history) => (dispatch, getState) => {
  // Note: dispatch() is synchronous. By the time it returns, the state will have updated
  dispatch(searchVideoApplyQuerySet());
  dispatch(updateUrlAndSearch(getState, history));
};

/**
 * Change query and execute search immediately.
 *
 * @param query
 * @returns {function(...[*]=)}
 */
export const searchVideoChangeQueryAndApply = (query, history) => (dispatch, getState) => {
  dispatch(searchVideoChangeQueryStart(query));
  dispatch(searchVideoApplyQuerySet());
  dispatch(updateUrlAndSearch(getState, history));
};

export const searchVideoOrder = (fieldName, history) => (dispatch, getState) => {
  dispatch(searchVideoOrderSet(fieldName));
  dispatch(updateUrlAndSearch(getState, history));
};

export const searchVideoAddFacetFilter = (fieldName, value) => (dispatch) => {
  dispatch(searchVideoFacetAdd(fieldName, value));
};

export const searchVideoRemoveFacetFilter = (fieldName, value) => (dispatch) => {
  dispatch(searchVideoFacetRemove(fieldName, value));
};

export const searchVideoApplyFilters = (fieldNames, history) => (dispatch, getState) => {
  dispatch(searchVideoApplyFiltersSet(fieldNames));
  dispatch(updateUrlAndSearch(getState, history));
};

export const searchVideoClearFilters = (history) => (dispatch, getState) => {
  dispatch(searchVideoClearFiltersSet());
  dispatch(updateUrlAndSearch(getState, history));
};
