import { defaultNumSuggestions, defaultPageSize, suggestionIds } from 'constants/index';
import searchService from '../services/searchService';
import videoService from '../services/videoService';
import {
  HOT_TOPICS_FETCH,
  HOT_TOPICS_FETCH_FAILURE,
  HOT_TOPICS_FETCH_SUCCESS,
  POPULAR_PRESENTERS_FETCH,
  POPULAR_PRESENTERS_FETCH_FAILURE,
  POPULAR_PRESENTERS_FETCH_SUCCESS,
  SUGGESTIONS_FETCH,
  SUGGESTIONS_FETCH_FAILURE,
  SUGGESTIONS_FETCH_SUCCESS
} from '../actionTypes';
import { startOfDay } from '../../utils/dateUtils';
import { channelToContainerName, organizationToContainerName } from '../../utils/suggestionsUtils';

const fetchSuggestionsStart = (id, searchParams) => ({
  type: SUGGESTIONS_FETCH,
  payload: {
    id,
    searchParams
  }
});

const fetchSuggestionsSuccess = (id, list) => ({
  type: SUGGESTIONS_FETCH_SUCCESS,
  payload: {
    id,
    list
  }
});

const fetchSuggestionsFailure = (id, error) => ({
  type: SUGGESTIONS_FETCH_FAILURE,
  payload: {
    id,
    error
  }
});

const fetchSuggestions = (id, searchParams, pageSize = defaultPageSize) => (dispatch) => {
  dispatch(fetchSuggestionsStart(id, searchParams));
  searchService.searchVideo(searchParams, 0, pageSize)
    .then((results) => dispatch(fetchSuggestionsSuccess(id, results.value)))
    .catch((err) => dispatch(fetchSuggestionsFailure(id, err)));
};

export const fetchUpcomingLiveEvents = () => fetchSuggestions(
  suggestionIds.upcomingLiveEvents,
  {
    query: null,
    filters: {
      type: ['live'],
      scheduledStartMin: [startOfDay(new Date())]
    },
    order: 'scheduledStart asc'
  },
  defaultNumSuggestions
);

/**
 * Pass in a list of channels you have access to, and it will fetch all webcasts in those channels. Used to render the
 * "Private webcasts shared with you" section on the home page.
 *
 * @param channels
 * @returns {function(...[*]=)}
 */
export const fetchMyPrivateWebcasts = (channels) => fetchSuggestions(
  suggestionIds.myPrivateWebcasts,
  {
    query: null,
    filters: {
      audience: channels
    },
    order: 'scheduledStart desc',
    queryType: 'trending'
  },
  defaultNumSuggestions
);

export const fetchChannelVideos = (channelName) => fetchSuggestions(
  channelToContainerName(channelName),
  {
    query: null,
    filters: {
      audience: [channelName]
    },
    order: 'viewCount desc',
    queryType: 'trending'
  },
  defaultNumSuggestions
);

export const fetchOrganizationVideos = (organizationName) => fetchSuggestions(
  organizationToContainerName(organizationName),
  {
    query: null,
    filters: {
      organization: [organizationName]
    },
    order: 'viewCount desc',
    queryType: 'trending'
  },
  defaultNumSuggestions
);

// TODO: Rename "Webcasts" to "Videos" everywhere for consistency
export const fetchTopWebcasts = () => fetchSuggestions(
  suggestionIds.topWebcasts,
  {
    query: null,
    filters: {
      type: ['archived']
    },
    order: 'viewCount desc',
    queryType: 'trending'
  },
  defaultNumSuggestions
);

const fetchHotTopicsStart = () => ({
  type: HOT_TOPICS_FETCH,
  payload: {}
});

const fetchHotTopicsSuccess = (topics) => ({
  type: HOT_TOPICS_FETCH_SUCCESS,
  payload: {
    topics
  }
});

const fetchHotTopicsFailure = (error) => ({
  type: HOT_TOPICS_FETCH_FAILURE,
  payload: {
    error
  }
});

export const fetchHotTopicVideo = (id, keywords) => fetchSuggestions(
  `hotTopic_${id}`,
  {
    query: keywords.join(' '),
    filters: {},
    order: 'scheduledStart desc'
  },
  defaultNumSuggestions
);

const fetchHotTopicsVideos = (topics) => (dispatch) => {
  topics.forEach((topic) => {
    dispatch(fetchHotTopicVideo(topic.id, topic.keywords));
  });
};

export const fetchHotTopics = () => (dispatch) => {
  dispatch(fetchHotTopicsStart());
  videoService.fetchHotTopics()
    .then((results) => {
      dispatch(fetchHotTopicsSuccess(results));
      dispatch(fetchHotTopicsVideos(results));
    })
    .catch((err) => dispatch(fetchHotTopicsFailure(err)));
};

const fetchPopularPresentersStart = () => ({
  type: POPULAR_PRESENTERS_FETCH,
  payload: {}
});

const fetchPopularPresentersSuccess = (presenters) => ({
  type: POPULAR_PRESENTERS_FETCH_SUCCESS,
  payload: {
    presenters
  }
});

const fetchPopularPresentersFailure = (error) => ({
  type: POPULAR_PRESENTERS_FETCH_FAILURE,
  payload: {
    error
  }
});

export const fetchPopularPresenters = () => (dispatch) => {
  dispatch(fetchPopularPresentersStart());
  videoService.fetchPopularPresenters()
    .then((results) => dispatch(fetchPopularPresentersSuccess(results)))
    .catch((err) => dispatch(fetchPopularPresentersFailure(err)));
};
