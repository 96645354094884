import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { extendRequestType, videoType } from 'types';
import { Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../buttons/Button';
import TextLink from '../containers/TextLink';
import { links, localeEnCa } from '../../constants';
import styles from './ExpiryBanner.module.scss';
import { extendRequestStart } from '../../store/actions/videoActions';
import Image from '../containers/Image';
import restoringIcon from '../../assets/images/Restoring-Icon.svg';
import expiringIcon from '../../assets/images/Expiring-Icon.svg';
import expiredIcon from '../../assets/images/Expired-Icon.svg';

const ExpiryBanner = ({
  video,
  extendAction,
  extendRequest,
  locale
}) => {
  const [isExpanded, setExpanded] = React.useState(true);
  const isEnglish = (locale === localeEnCa);

  const toggleExpanded = () => {
    setExpanded(!isExpanded);
  };

  let expiryType = '';

  if (video) {
    expiryType = video.expiryType;
  }

  let iconUrl;
  let expiryClass;
  let expiryLabelClass;
  let labelCode;
  const icons = {
    restoring: restoringIcon,
    expiring: expiringIcon,
    expired: expiredIcon
  };

  const extendVideo = () => {
    if (video && video.id) {
      extendAction(video.id);
    }
  };

  if (extendRequest.isCompleted
  && (expiryType === 'expiring' || expiryType === 'expired')) {
    window.location.reload();
  }

  if (expiryType) {
    switch (expiryType) {
      case 'expired':
        iconUrl = icons.expired;
        expiryClass = styles.expired;
        expiryLabelClass = styles.expiredLabel;
        labelCode = 'expired';
        break;
      case 'expiring':
        iconUrl = icons.expiring;
        expiryClass = styles.expiring;
        expiryLabelClass = styles.expiringLabel;
        labelCode = 'expiring';
        break;
      case 'restoring':
        iconUrl = icons.restoring;
        expiryClass = styles.restoring;
        expiryLabelClass = styles.restoringLabel;
        labelCode = 'restoring';
        break;
      default:
        break;
    }
  }

  if (expiryClass) {
    return (
      <div className={`${styles.container} ${expiryClass}`}>
        <Image className={styles.icon} altId={`expiryBadgeIcon_${labelCode}`} src={iconUrl} ariaHidden />
        <span className={`${styles.label} ${expiryLabelClass}`}>
          <FormattedMessage id={`expiryBannerLabel_${labelCode}`} />
        </span>
        {isExpanded
          ? (
            <Button
              className={styles.button}
              icon={ExpandLess}
              variant="text"
              labelId="detailsHide"
              onClick={toggleExpanded}
            />
          )
          : (
            <Button
              className={styles.button}
              icon={ExpandMore}
              variant="text"
              labelId="detailsShow"
              onClick={toggleExpanded}
            />
          )}
        <Collapse in={isExpanded}>
          <div className={styles.bodyContainer}>
            <span className={styles.infoContainer}>
              <FormattedMessage
                id={`expiryBannerBody_${labelCode}`}
                values={{
                  linkEmail: () => (
                    <TextLink src={links.email} labelId="linkEmail" />
                  ),
                  boldDate: () => (
                    <b>{video && video.expires ? moment.utc(video.expires).locale(isEnglish ? 'en' : 'fr').format(isEnglish ? 'MMM. DD, YYYY' : 'DD MMMM YYYY') : ''}</b>
                  )
                }}
              />
            </span>
            <Button
              className={styles.buttonExtend}
              variant="primary"
              labelId="extendDate"
              onClick={() => extendVideo()}
              disabled={extendRequest.isRequested}
            />
            <Button
              className={styles.buttonRestore}
              variant="primary"
              labelId="restoreWebcast"
              onClick={() => extendVideo()}
              disabled={extendRequest.isRequested}
            />
          </div>
        </Collapse>
      </div>
    );
  }

  return (
    <div />
  );
};

ExpiryBanner.propTypes = {
  video: videoType,
  extendAction: PropTypes.func.isRequired,
  extendRequest: extendRequestType.isRequired,
  locale: PropTypes.string.isRequired
};

ExpiryBanner.defaultProps = {
  video: undefined
};

const mapStateToProps = (state) => ({
  extendRequest: state.video.extendRequest,
  locale: state.locale.locale
});

const mapDispatchToProps = {
  extendAction: extendRequestStart
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpiryBanner);
