export default {
  /**
   * Splits a given string into the given number of lines. Returns an array of strings - one string per line. If the
   * string cannot be split evenly, the odd word will be appended to the last line.
   *
   * @param str
   * @param numLines
   * @returns {[]}
   */
  splitToLines(str, numLines) {
    if (!str) {
      return [str];
    }
    const words = str.split(' ');
    const lines = [];

    const wordsPerLine = Math.floor(words.length / numLines);
    const remainder = words.length % numLines;

    let currentLine = 0;
    let wordsInCurrentLine = 0;
    words.forEach((word, wordIndex) => {
      if (lines[currentLine] === undefined) {
        lines[currentLine] = '';
      }
      lines[currentLine] += `${word} `;
      wordsInCurrentLine += 1;
      if ((wordsInCurrentLine >= wordsPerLine)
        && (remainder === 0 || wordIndex < words.length - 2)) {
        currentLine += 1;
        wordsInCurrentLine = 0;
      }
    });

    return lines;
  },

  sizePxToNumber(sizePx) {
    try {
      const sizeStr = sizePx.replace('px', '');
      return Number(sizeStr);
    } catch (e) {
      return null;
    }
  },

  removeDuplicates(list) {
    return Array.from(new Set(list));
  }
};

export const truncateText = (text, maxLength) => {
  const isTruncated = (maxLength && text.length > maxLength);
  const ellipsisLength = 3;
  return isTruncated
    ? `${text.substring(0, maxLength - ellipsisLength)}...`
    : text;
};
