import React from 'react';
import PropTypes from 'prop-types';
import { searchParamsType } from 'types';
import searchUtils from 'utils/searchUtils';
import { routes } from '../../constants';
import Button from './Button';

/**
 * Renders a button that redirects to a search results page based on the given search object.
 *
 * @param labelId
 * @param searchParams
 * @returns {*}
 * @constructor
 */
const SearchRedirectButton = ({ labelId, searchParams, className }) => {
  const url = routes.results + searchUtils.searchParamsToUrl(searchParams);
  return (
    <Button to={url} labelId={labelId} className={className} />
  );
};

SearchRedirectButton.propTypes = {
  labelId: PropTypes.string.isRequired,
  searchParams: searchParamsType.isRequired,
  className: PropTypes.string
};

SearchRedirectButton.defaultProps = {
  className: ''
};

export default SearchRedirectButton;
