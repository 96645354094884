import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { intlType } from '../../types';
import { truncateText } from '../../utils/stringUtils';
import { maxLength } from '../../constants';

const Image = ({
  src,
  className,
  altId,
  altValues,
  ariaHidden,
  width,
  height,
  intl
}) => (
  <img
    className={className}
    alt={
      truncateText(intl.formatMessage(
        { id: altId },
        altValues
      ), maxLength.altText)
    }
    src={src}
    width={width}
    height={height}
    aria-hidden={ariaHidden}
  />
);

Image.propTypes = {
  src: PropTypes.string.isRequired,
  altId: PropTypes.string.isRequired,
  altValues: PropTypes.shape({}),
  ariaHidden: PropTypes.bool,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  intl: intlType.isRequired
};

Image.defaultProps = {
  className: '',
  width: null,
  height: null,
  altValues: {},
  ariaHidden: undefined
};

export default injectIntl(Image);
