import urlUtils, { encodeUrlValue, decodeUrlValue } from './urlUtils';
import objectUtils from './objectUtils';
import { filterSeparator, filterValueSeparator } from '../constants';
import { dateToString } from './dateUtils';

/*
encodeUrlValue converts special chars to HTML entities - used to get rid of special characters in filter values like
channel names
 */
const encodeFilterValue = (value) => (encodeUrlValue(value));

const decodeFilterValue = (value) => (decodeUrlValue(value));

/*
Note: If a filter value contains the separator character, it will break the MT logic so it cannot be used. URL encoding
is not an option, since Azure decodes everything before the value gets to the MT. The only solution is to use a
separator that will never appear in any filter value. The MT should ensure that.
*/
const checkFilterValue = (value) => {
  if (typeof value === 'string' && value.indexOf(filterValueSeparator) >= 0) {
    throw new Error(`Filter value contains special character "${filterValueSeparator}"`);
  }
};

/*
Make sure the FE URL consistent with the MT search URL. That way the URL <-> String
conversion functions can be reused for FE and the MT urls (searchService)

MT Format:
- ?query=<keyword>&filters=presenters:Dr. Jones:Dr. Smith&order=scheduledStart
- filter format is <fieldName>:value,<fieldName>:value,<fieldName:Value:Value:etc>
 */
export default {
  filterValueToString(value) {
    return ((value instanceof Date) ? dateToString(value) : value);
  },

  /**
   * Adds filter value to existing filters.
   *
   * @param filters
   * @param code
   * @param value
   */
  addFilterValue(filters, code, value) {
    checkFilterValue(value);
    const newFilters = { ...filters };
    const valueStr = this.filterValueToString(value);

    if (!newFilters[code]) {
      newFilters[code] = [];
    }
    if (newFilters[code].indexOf(valueStr) < 0) {
      newFilters[code].push(valueStr);
    }

    return newFilters;
  },

  /**
   * Removes filter value from existing filters.
   *
   * @param filters
   * @param code
   * @param value
   */
  removeFilterValue(filters, code, value) {
    checkFilterValue(value);
    const newFilters = { ...filters };
    const valueStr = this.filterValueToString(value);

    const valueCodeIndex = filters[code].indexOf(valueStr);
    if (valueCodeIndex >= 0) {
      newFilters[code].splice(valueCodeIndex, 1);
    }
    if (newFilters[code].length === 0) {
      delete newFilters[code];
    }

    return newFilters;
  },

  /**
   * @param urlFiltersStr I.e. type:archived,presenters:Dr. Jones:Dr. Smith
   * @returns {{}}
   */
  urlFiltersToObject(urlFiltersStr) {
    const filtersObject = {};
    if (urlFiltersStr) {
      const filters = urlFiltersStr.split(filterSeparator);
      filters.forEach((filter) => {
        const splitFilter = filter.split(filterValueSeparator);
        const [fieldName, ...fieldValues] = splitFilter;
        const decodedFieldValues = fieldValues.map((value) => decodeFilterValue(value));
        filtersObject[fieldName] = decodedFieldValues;
      });
    }
    return filtersObject;
  },

  filtersObjectToUrl(filtersObject) {
    if (typeof filtersObject !== 'object') {
      throw new Error('Invalid param type.');
    }
    let url = '';
    if (filtersObject) {
      Object.entries(filtersObject).forEach(([fieldName, fieldValues]) => {
        // Date strings have dashes and dots, so they should not be encoded
        const encodeValues = (fieldName !== 'scheduledStartMin' && fieldName !== 'scheduledStartMax');
        const encodedFieldValues = (encodeValues)
          ? fieldValues.map((value) => encodeFilterValue(value))
          : fieldValues;
        const joinedFieldValues = encodedFieldValues.join(filterValueSeparator);
        if (joinedFieldValues) {
          url += `${fieldName}${filterValueSeparator}${joinedFieldValues}${filterSeparator}`;
        }
      });
    }
    return (url.length > 0) ? url.substring(0, url.length - 1) : null;
  },

  /**
   * Converts a URL to a common "searchParams" object with the fields query, filters, order
   *
   * @param urlParamsStr
   * @returns The common "search" object. To be renamed to "searchParams"
   */
  urlToSearchParams(urlParamsStr) {
    const urlParams = urlUtils.parseParams(urlParamsStr);

    return {
      ...urlParams,
      query: urlParams.query,
      filters: this.urlFiltersToObject(urlParams.filters)
    };
  },

  /**
   * Converts a "searchParams" object to a URL param string. The exact opposite of urlToSearchParams().
   *
   * @param searchParams The common "search" object. To be renamed to "searchParams"
   * @returns {string}
   */
  searchParamsToUrl(searchParams) {
    const {
      query,
      filters,
      order,
      page,
      pageSize
    } = searchParams;

    // TODO: Test this thoroughly, then refactor. Could use {filter, ...rest} when destructuring searchParams
    const params = [];
    params.push(`query=${query ? encodeURIComponent(query) : ''}`);
    if (filters && !objectUtils.isEmptyObject(filters)) {
      params.push(`filters=${encodeURIComponent(this.filtersObjectToUrl(filters))}`);
    }
    if (order) {
      params.push(`order=${order}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (pageSize) {
      params.push(`pageSize=${pageSize}`);
    }

    let paramsStr = '';
    if (params.length > 0) {
      params.forEach((param) => {
        paramsStr += (paramsStr ? '&' : '?') + param;
      });
    }

    return paramsStr;
  },

  /**
   * Set the order in the given URL string and return the new URL string.
   *
   * @param urlParamsStr
   * @param fieldName
   * @returns {string}
   */
  setOrder(urlParamsStr, fieldName) {
    const searchParams = this.urlToSearchParams(urlParamsStr);
    return this.searchParamsToUrl({
      ...searchParams,
      order: fieldName
    });
  },

  /*
  Parses suggestion looking for <em> tags. Returns an array of the shape {text, isEmphasized}
   */
  suggestionParseText(text) {
    const splitText = text.split(/<\/?em>/);
    let isEmphasized = text.indexOf('<em>') === 0;
    const parsed = [];
    splitText.forEach((value) => {
      if (value) {
        parsed.push({
          value,
          isEmphasized
        });
        isEmphasized = !isEmphasized;
      }
    });
    return parsed;
  }
};

export const suggestionRemoveTags = (suggestion) => (
  (suggestion && suggestion.replace)
    ? suggestion
      .replaceAll('<em>', '')
      .replaceAll('</em>', '')
    : suggestion
);

/*
Converts typeahead suggestsions to an array with 2 sections: 1 with unique matches and 1 with list of videos.
 */
export const parseSuggestions = (suggestions) => {
  const uniqueMatches = [];
  const videos = [];

  suggestions.forEach((suggestion) => {
    const text = suggestion['@search.text'];
    if (uniqueMatches.indexOf(text) < 0) {
      uniqueMatches.push(text);
    }
    videos.push({
      id: suggestion.id,
      description: suggestion.description,
      text
    });
  });

  const parsedSuggestions = [
    ...uniqueMatches.map((match) => ({ text: match })),
    ...videos
  ];

  return parsedSuggestions;
};

export const isValueInFacets = (facets, fieldName, value) => {
  if (!facets || !facets[fieldName]) {
    return false;
  }
  let exists = false;
  facets[fieldName].forEach((facet) => {
    if (facet.value.toLowerCase() === value.toLowerCase()) {
      exists = true;
    }
  });
  return exists;
};

const isFacetSelected = (facetName, filters) => !!(filters[facetName] && filters[facetName].length > 0);

const isDateFilter = (filterName) => (filterName === 'scheduledStartMin' || filterName === 'scheduledStartMax');

/**
 * filters.keywords is an array of strings;
 * facets.keywords is an array of objects {count: 1, value: 'blah'}
 * returns an new facets object, adding keywords from the filters
 */
export const combineFacetsAndFilters = (facets, filters) => {
  const newFacets = {
    ...facets
  };

  // Adds filters to facets that are selected but have 0 matches, so they appear on the filters UI
  if (filters) {
    Object.entries(filters).forEach(([filterName, filterValues]) => {
      filterValues.forEach((filterValue) => {
        if (!isDateFilter(filterName) && !isValueInFacets(newFacets, filterName, filterValue)) {
          if (!newFacets[filterName]) {
            newFacets[filterName] = [];
          }
          newFacets[filterName].push({
            value: filterValue,
            count: 0
          });
        }
      });
    });
  }

  // Filter out facets that have only one value, and that value is not selected
  if (facets && filters) {
    Object.entries(newFacets).forEach(([facetName, facetValues]) => {
      if (facetValues.length <= 1 && !isFacetSelected(facetName, filters)) {
        delete newFacets[facetName];
      }
    });
  }

  return newFacets;
};
