import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import JavascriptTimeAgo from 'javascript-time-ago';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { create } from 'jss';
import en from 'javascript-time-ago/locale/en';
import frCa from 'javascript-time-ago/locale/fr-CA';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import objectFitImages from 'object-fit-images';
import { Helmet } from 'react-helmet';
import Header from './components/header/Header';
import Notifications from './components/notifications/Notifications';
import Watch from './containers/Watch';
import SearchResults from './containers/SearchResults';
import Home from './containers/Home';
import UserInterface from './containers/UserInterface';
import ErrorBoundary from './components/error/ErrorBoundary';
import { googleAnalyticsCode, locales } from './constants';
import styles from './App.module.scss';
import LoginCallback from './containers/LoginCallback';
import NotFound from './containers/NotFound';
import Edit from './containers/Edit';
import UserProfile from './containers/UserProfile';
import localStorageUtils from './utils/localStorageUtils';
import { fetchUser } from './store/actions/userActions';
import Share from './containers/Share';
import Footer from './components/footer/Footer';
import ScrollToTop from './components/utils/ScrollToTop';
import About from './containers/About';

/*
This defines the insertion point of MUI styles, such that they appear before our styles in the DOM and are always
overridden by our styles. Details: https://material-ui.com/styles/advanced/
 */
const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point')
});

JavascriptTimeAgo.addLocale(en);
JavascriptTimeAgo.addLocale(frCa);

const getLocale = (code) => (locales.filter((locale) => (locale.code === code))[0]);

objectFitImages();

ReactGA.initialize(googleAnalyticsCode);
ReactGA.pageview(window.location.pathname + window.location.search);

const App = ({ locale, fetchUserAction }) => {
  const token = localStorageUtils.getUserToken();
  if (token) {
    fetchUserAction(token);
  }

  return (
    <StylesProvider jss={jss}>
      <IntlProvider key={locale} locale={locale} messages={getLocale(locale).messages}>
        <BrowserRouter>
          <ErrorBoundary>
            <Helmet>
              <title>Webcasting Centre</title>
              <meta name="description" content="Discover an extensive library of thousands of ongoing and previously broadcasted health professional events, powered by Ontario Health (OTN)." />
              <meta name="robots" content="index, follow" />
            </Helmet>
            <ScrollToTop />
            <Header />
            <main role="main" className={styles.container} aria-live="polite">
              <Switch>
                <Route path="/videos/:videoId/edit" component={Edit} />
                <Route path="/videos/:videoId/share" component={Share} />
                <Route path="/videos/:videoId/extend" component={Home} />
                <Route path="/videos/:videoId" component={Watch} />
                <Route path="/results" component={SearchResults} />
                <Route path="/ui" component={UserInterface} />
                <Route path="/login_callback" component={LoginCallback} />
                <Route path="/users/me" component={UserProfile} />
                <Route path="/about" component={About} />
                <Route path="/" component={Home} exact />
                <Route component={NotFound} />
              </Switch>
            </main>
            <Footer />
            <Notifications />
          </ErrorBoundary>
        </BrowserRouter>
      </IntlProvider>
    </StylesProvider>
  );
};

App.propTypes = {
  locale: PropTypes.string.isRequired,
  fetchUserAction: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  locale: state.locale.locale
});

const mapDispatchToProps = {
  fetchUserAction: fetchUser
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
