import { useLayoutEffect, useState } from 'react';

/**
 * React Hooks
 *
 * https://reactjs.org/docs/hooks-intro.html
 */
export default {
  /**
   * Listens for window resize events, updates the size.
   *
   * @returns {number[]} Array of the form [width, height]
   */
  useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
};
