import React from 'react';
import Paper from '@material-ui/core/Paper';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { channelType } from '../../types';
import stringUtils from '../../utils/stringUtils';
import styles from './Channel.module.scss';

const Channel = ({
  channel: {
    url,
    name
  }
}) => (
  <Link to={url} className={styles.container}>
    <Paper elevation={3} classes={{ root: styles.paper }}>
      <h3 className={styles.title}>
        {stringUtils.splitToLines(name, 2).map((line) => (
          <span key={line} className={styles.line}>{line}</span>
        ))}
      </h3>
    </Paper>
  </Link>
);

Channel.propTypes = {
  channel: channelType.isRequired
};

export default injectIntl(Channel);
