import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import TuneRoundedIcon from '@material-ui/icons/TuneRounded';
import PropTypes from 'prop-types';
import objectUtils from 'utils/objectUtils';
import searchUtils from 'utils/searchUtils';
import { executeSearch, searchVideoNextPage } from 'store/actions/searchActions';
import { searchParamsType, searchResultsType } from 'types';
import Drawer from '@material-ui/core/Drawer';
import SearchFilters from '../components/search/SearchFilters';
import SearchResultsHeader from '../components/search/SearchResultsHeader';
import SearchResultsList from '../components/search/SearchResultsList';
import IconButton from '../components/buttons/IconButton';
import styles from './SearchResults.module.scss';

const SearchResults = ({
  searchResults,
  storeSearchParams,
  urlSearchParams,
  executeSearchAction
}) => {
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  // Initial Load: If URL and Redux store state don't match, use the URL as the source of truth
  const fetchDataIfNeeded = () => {
    if (!objectUtils.isEqual(storeSearchParams, urlSearchParams)) {
      // TODO: This also triggers the typeahead suggestion search which is not ideal
      executeSearchAction(urlSearchParams);
    }
  };

  useEffect(() => {
    fetchDataIfNeeded();
  });

  // TODO: With this logic SearchFilters will be added to the DOM twice, which is not ideal for performance. Optimize.
  return (
    <div className={styles.container}>
      <div className={`${styles.sidePanel} ${styles.desktopOnly}`}>
        <SearchFilters />
        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={() => setDrawerOpen(false)}
          className={`${styles.mobileOnly}`}
          classes={{
            paper: styles.drawer
          }}
        >
          <SearchFilters
            mobile
            onApply={() => setDrawerOpen(false)}
          />
        </Drawer>
      </div>
      <div className={styles.mainPanel}>
        <div className={styles.mobileHeader}>
          <IconButton icon={TuneRoundedIcon} className={styles.mobileFilters} labelId="filters" onClick={() => setDrawerOpen(true)} />
        </div>
        <SearchResultsHeader resultsCount={searchResults.count} searchQuery={storeSearchParams.query} />
        <SearchResultsList searchResults={searchResults} />
      </div>
    </div>
  );
};

SearchResults.propTypes = {
  searchResults: searchResultsType.isRequired,
  storeSearchParams: searchParamsType.isRequired,
  urlSearchParams: searchParamsType.isRequired,
  executeSearchAction: PropTypes.func.isRequired
};

// Extract search params from URL, pass down to props
const mapStateToProps = (state, ownProps) => {
  const { search: { results, searchParams } } = state;
  const { location } = ownProps;

  return {
    searchResults: results,
    storeSearchParams: searchParams,
    urlSearchParams: searchUtils.urlToSearchParams(location.search)
  };
};

const mapDispatchToProps = {
  executeSearchAction: executeSearch,
  searchVideoNextPageAction: searchVideoNextPage
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
