import React from 'react';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { authProviders, authProviderOtn } from '../../constants';
import Image from '../containers/Image';
import { signIn } from '../../utils/userUtils';
import styles from './SignInDialog.module.scss';

const SignInListItem = ({ labelId, logoUrl, onClick }) => (
  <ListItem classes={{ root: styles.button }} button onClick={onClick}>
    {logoUrl && (
      <ListItemIcon>
        <Image altId={labelId} src={logoUrl} className={styles.logo} />
      </ListItemIcon>
    )}
    <ListItemText classes={{ root: styles.textContainer }}>
      <FormattedMessage id={labelId} />
    </ListItemText>
  </ListItem>
);

SignInListItem.propTypes = {
  labelId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  logoUrl: PropTypes.string
};

SignInListItem.defaultProps = {
  logoUrl: null
};

const SignInDialog = ({
  onClose,
  open,
  onGuestSignIn,
  privateWebcast
}) => {
  const location = useLocation();

  const handleClose = () => {
    onClose();
  };

  const handleProviderClick = (authProvider) => {
    signIn(authProvider, location);
    onClose(authProvider);
  };

  return (
    <Dialog classes={{ paper: styles.container }} onClose={handleClose} aria-labelledby="signin-dialog-title" open={open}>
      <DialogTitle classes={{ root: styles.heading }} id="signin-dialog-title">
        <FormattedMessage id="selectSignInProvider" />
      </DialogTitle>
      {privateWebcast ? (
        <>
          <p className={styles.emphasized}><FormattedMessage id="privateWebcast_signInMessage" /></p>
          <p><FormattedMessage id="privateWebcast_signInDetails" /></p>
        </>
      ) : (
        <p><FormattedMessage id="signInMessage" /></p>
      )}
      <List>
        <SignInListItem
          labelId={authProviderOtn.labelId}
          logoUrl={authProviderOtn.logoUrl}
          onClick={() => handleProviderClick(authProviderOtn.code)}
        />
        <ListItem classes={{ root: styles.divider }}>
          <p><FormattedMessage id="signInOr" /></p>
        </ListItem>
        {authProviders.map(({
          code,
          labelId,
          logoUrl
        }) => (
          <SignInListItem
            key={code}
            labelId={labelId}
            logoUrl={logoUrl}
            onClick={() => handleProviderClick(code)}
          />
        ))}
        {onGuestSignIn && (
          <SignInListItem
            labelId="signIn_guestOption"
            onClick={onGuestSignIn}
          />
        )}
      </List>
    </Dialog>
  );
};

SignInDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onGuestSignIn: PropTypes.func,
  onClose: PropTypes.func,
  privateWebcast: PropTypes.bool
};

SignInDialog.defaultProps = {
  onGuestSignIn: null,
  onClose: () => {},
  privateWebcast: false
};

export default SignInDialog;
