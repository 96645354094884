import {
  SEARCH_VIDEO_CHANGE_QUERY,
  SEARCH_VIDEO_CHANGE_FILTER,
  SEARCH_VIDEO_APPLY_FILTERS,
  SEARCH_VIDEO_CLEAR_FILTERS,
  SEARCH_VIDEO_FAILURE,
  SEARCH_VIDEO,
  SEARCH_VIDEO_SUCCESS,
  SEARCH_VIDEO_NEXT_PAGE,
  SEARCH_VIDEO_NEXT_PAGE_SUCCESS,
  SEARCH_VIDEO_NEXT_PAGE_FAILURE,
  SEARCH_VIDEO_SUGGESTIONS_SUCCESS,
  SEARCH_VIDEO_SUGGESTIONS_FAILURE,
  SEARCH_VIDEO_ORDER,
  SEARCH_VIDEO_FACET_ADD,
  SEARCH_VIDEO_FACET_REMOVE,
  SEARCH_VIDEO_APPLY_QUERY
} from 'store/actionTypes';
import searchUtils from '../../utils/searchUtils';
import { cloneDeep } from '../../utils/objectUtils';

const initialPage = {
  isLoading: false,
  error: null,
  list: null
};

const initialState = {
  searchParamsForm: {
    query: '',
    filters: {}
  },
  searchParams: {
    query: null,
    filters: {}
  },
  results: {
    pages: [initialPage],
    loadedCount: null,
    count: null,
    facets: null
  },
  suggestions: []
};

const applySearchVideoChangeQuery = (state, action) => ({
  ...state,
  searchParamsForm: {
    ...state.searchParamsForm,
    query: action.payload.query
  }
});

const applySearchVideoApplyQuery = (state) => {
  const searchParamsForm = {
    query: state.searchParamsForm.query,
    filters: {}
  };

  return {
    ...state,
    searchParamsForm,
    searchParams: cloneDeep(searchParamsForm)
  };
};

const applySearchVideoSuggestionsSuccess = (state, action) => {
  const { query, results } = action.payload;

  // Ensure suggestions match the current query, in case of network delays/inconsistencies
  if (query !== state.searchParamsForm.query) {
    return { ...state };
  }

  return {
    ...state,
    suggestions: [...results]
  };
};

const applySearchVideoSuggestionsFailure = (state) => ({
  ...state,
  suggestions: []
});

const applySearchVideoChangeFilter = (state, action) => {
  const { fieldName, value } = action.payload;

  return {
    ...state,
    searchParamsForm: {
      ...state.searchParamsForm,
      filters: {
        ...state.searchParamsForm.filters,
        [fieldName]: [value]
      }
    }
  };
};

/**
 * Copies filters with the given field names from searchParamsForm to searchParams
 *
 * @param state
 * @param action
 * @returns {{searchParams: {filters}}}
 */
const applySearchVideoApplyFilters = (state, action) => {
  const { fieldNames } = action.payload;

  const newFilters = {
    ...state.searchParams.filters
  };

  const isEmpty = (array) => (
    !array || array.length === 0 || (array.length === 1 && !array[0])
  );

  fieldNames.forEach((fieldName) => {
    const filterValues = state.searchParamsForm.filters[fieldName];
    if (!isEmpty(filterValues)) {
      // TODO: filterValueToString is not needed, since DateFilter already converts the value to string before calling searchVideoChangeFilterAction
      newFilters[fieldName] = filterValues.map((value) => searchUtils.filterValueToString(value));
    } else {
      delete newFilters[fieldName];
    }
  });

  return {
    ...state,
    searchParams: {
      ...state.searchParams,
      filters: newFilters
    }
  };
};

const applySearchVideoClearFilters = (state) => ({
  ...state,
  searchParamsForm: {
    ...state.searchParamsForm,
    filters: {}
  },
  searchParams: {
    ...state.searchParams,
    filters: {}
  }
});

const applySearchVideo = (state, action) => {
  const { searchParams } = action.payload;

  return {
    ...state,
    searchParamsForm: cloneDeep(searchParams),
    searchParams,
    results: {
      ...initialState.results,
      pages: [{
        ...initialPage,
        isLoading: true
      }],
      facets: {
        ...state.results.facets
      }
    }
  };
};

const applySearchVideoSuccess = (state, action) => {
  const { results } = action.payload;

  return {
    ...state,
    results: {
      pages: [{
        list: results.value,
        isLoading: false,
        error: null
      }],
      loadedCount: results.value.length,
      count: results['@odata.count'],
      facets: results['@search.facets']
    }
  };
};

const applySearchVideoFailure = (state, action) => ({
  ...state,
  results: {
    ...initialState.results,
    pages: [{
      isLoading: false,
      error: action.payload.error
    }]
  }
});

const applySearchVideoNextPage = (state) => ({
  ...state,
  results: {
    ...state.results,
    pages: [...state.results.pages, {
      ...initialPage,
      isLoading: true
    }]
  }
});

const updateLastPage = (pages, lastPage) => {
  const newPages = [...pages];
  newPages[newPages.length - 1] = lastPage;
  return newPages;
};

const applySearchVideoNextPageSuccess = (state, action) => ({
  ...state,
  results: {
    ...state.results,
    pages: updateLastPage(state.results.pages, {
      list: action.payload.results.value,
      isLoading: false,
      error: null
    }),
    loadedCount: state.results.loadedCount + action.payload.results.value.length
  }
});

const applySearchVideoNextPageFailure = (state, action) => ({
  ...state,
  results: {
    ...state.results,
    pages: updateLastPage(state.results.pages, {
      isLoading: false,
      error: action.payload.error
    })
  }
});

const applySearchVideoOrder = (state, action) => ({
  ...state,
  searchParams: {
    ...state.searchParams,
    order: action.payload.fieldName
  }
});

const applySearchVideoFacetAdd = (state, action) => ({
  ...state,
  searchParamsForm: {
    ...state.searchParamsForm,
    filters: searchUtils.addFilterValue(
      state.searchParamsForm.filters,
      action.payload.fieldName,
      action.payload.value
    )
  }
});

const applySearchVideoFacetRemove = (state, action) => ({
  ...state,
  searchParamsForm: {
    ...state.searchParamsForm,
    filters: searchUtils.removeFilterValue(
      state.searchParamsForm.filters,
      action.payload.fieldName,
      action.payload.value
    )
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_VIDEO_CHANGE_QUERY:
      return applySearchVideoChangeQuery(state, action);
    case SEARCH_VIDEO_APPLY_QUERY:
      return applySearchVideoApplyQuery(state, action);
    case SEARCH_VIDEO_SUGGESTIONS_SUCCESS:
      return applySearchVideoSuggestionsSuccess(state, action);
    case SEARCH_VIDEO_SUGGESTIONS_FAILURE:
      return applySearchVideoSuggestionsFailure(state);
    case SEARCH_VIDEO_CHANGE_FILTER:
      return applySearchVideoChangeFilter(state, action);
    case SEARCH_VIDEO_APPLY_FILTERS:
      return applySearchVideoApplyFilters(state, action);
    case SEARCH_VIDEO_CLEAR_FILTERS:
      return applySearchVideoClearFilters(state);
    case SEARCH_VIDEO:
      return applySearchVideo(state, action);
    case SEARCH_VIDEO_SUCCESS:
      return applySearchVideoSuccess(state, action);
    case SEARCH_VIDEO_FAILURE:
      return applySearchVideoFailure(state, action);
    case SEARCH_VIDEO_NEXT_PAGE:
      return applySearchVideoNextPage(state);
    case SEARCH_VIDEO_NEXT_PAGE_SUCCESS:
      return applySearchVideoNextPageSuccess(state, action);
    case SEARCH_VIDEO_NEXT_PAGE_FAILURE:
      return applySearchVideoNextPageFailure(state, action);
    case SEARCH_VIDEO_ORDER:
      return applySearchVideoOrder(state, action);
    case SEARCH_VIDEO_FACET_ADD:
      return applySearchVideoFacetAdd(state, action);
    case SEARCH_VIDEO_FACET_REMOVE:
      return applySearchVideoFacetRemove(state, action);
    default:
      return state;
  }
};
