import React from 'react';
import PropTypes from 'prop-types';
import ConditionalWrapper from '../utils/ConditionalWrapper';
import { truncateText } from '../../utils/stringUtils';

const Text = ({ value, maxLength, className }) => {
  const truncatedValue = truncateText(value, maxLength);
  const isTruncated = truncatedValue !== value;
  const title = isTruncated ? value : undefined;

  return (
    <ConditionalWrapper
      condition={title || className}
      wrapper={(children) => <span title={title} aria-label={title} className={className}>{children}</span>}
    >
      {truncatedValue}
    </ConditionalWrapper>
  );
};

Text.propTypes = {
  value: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  className: PropTypes.string
};

Text.defaultProps = {
  maxLength: null,
  className: ''
};

export default Text;
