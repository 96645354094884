import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { signOut } from '../../utils/userUtils';
import styles from './SignOutDialog.module.scss';

const SignOutListItem = ({ labelId, onClick }) => (
  <ListItem classes={{ root: styles.button }} button onClick={onClick}>
    <ListItemText classes={{ root: styles.textContainer }}>
      <FormattedMessage id={labelId} />
    </ListItemText>
  </ListItem>
);

SignOutListItem.propTypes = {
  labelId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

const SignOutDialog = ({
  onClose,
  open
}) => (
  <Dialog classes={{ paper: styles.container }} onClose={onClose} aria-labelledby="signin-dialog-title" open={open}>
    <DialogTitle classes={{ root: styles.heading }} id="signin-dialog-title">
      <FormattedMessage id="userSignOut" />
    </DialogTitle>
    <List>
      <SignOutListItem
        labelId="userSignOut_webcast"
        onClick={() => signOut(false)}
      />
      <SignOutListItem
        labelId="userSignOut_idp"
        onClick={() => signOut(true)}
      />
    </List>
    <p className={styles.footer}>
      <FormattedMessage
        id="userSignOutDetails"
        values={{
          signOut: <b><FormattedMessage id="userSignOutDetails_signOut" /></b>
        }}
      />
    </p>
  </Dialog>
);

SignOutDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func
};

SignOutDialog.defaultProps = {
  onClose: () => {}
};

export default SignOutDialog;
