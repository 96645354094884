import React from 'react';
import { injectIntl } from 'react-intl';
import { getHighlights, parseDescriptionPrefix } from '../../utils/videoUtils';
import { intlType, videoType } from '../../types';
import TextWithEm from '../containers/TextWithEm';

const VideoDescription = ({ video, intl }) => {
  const descriptionEm = getHighlights(video, 'description');

  /*
  WEBCAST-290 - If the video type is prepended to the description (i.e. starts with 'live_archived|' or 'live|' or
  'archived|'), extract the prefix, translate it and prepend to the description with a space and dash.
   */
  const parsedDescription = parseDescriptionPrefix(descriptionEm);
  let description;
  if (parsedDescription.prefix) {
    const translatedPrefix = intl.formatMessage({
      id: `videoType_${parsedDescription.prefix}`,
      defaultMessage: parsedDescription.prefix
    });
    description = `${translatedPrefix} - ${parsedDescription.text}`;
  } else {
    description = parsedDescription.text;
  }

  return (
    <TextWithEm value={description} />
  );
};

VideoDescription.propTypes = {
  video: videoType.isRequired,
  intl: intlType.isRequired
};

export default injectIntl(VideoDescription);
