import { apiGet, apiPost } from './apiService';
import { apiUrls } from '../../constants';

export default {
  fetchVideo: (videoId) => apiGet(`${apiUrls.root}video/${videoId}`),
  fetchSimilar: (videoId) => apiGet(`${apiUrls.root}video/${videoId}/similar`),
  fetchHotTopics: () => apiGet(`${apiUrls.root}video/hottopics`),
  fetchPopularPresenters: () => apiGet(`${apiUrls.root}video/popular/presenter`),

  /**
   * Call to increment the view view count.
   *
   * @param videoId
   * @param viewId - A unique id distinguishing this video view session (WEBCAST-338)
   * @returns {Promise | Promise<unknown>}
   */
  logViewStart: (videoId, viewId) => apiPost(`${apiUrls.root}video/${videoId}/statistics`, {
    viewId,
    view: true
  }),

  logViewDuration: (videoId, durationMins, viewId) => apiPost(`${apiUrls.root}video/${videoId}/statistics`, {
    viewId,
    durationMins
  }),

  logViewFullscreen: (videoId, isFullScreen, viewId) => apiPost(`${apiUrls.root}video/${videoId}/statistics`, {
    viewId,
    fullScreenView: isFullScreen
  }),

  /**
   * Call to send an email to the organizer on behalf of the user to request access to the video.
   *
   * @param videoId
   * @returns {Promise | Promise<unknown>}
   */
  requestAccess: (videoId) => apiPost(`${apiUrls.root}video/${videoId}/requestaccess`, {}),

  extend: (videoId) => apiPost(`${apiUrls.root}video/${videoId}/extend`, {})
};
