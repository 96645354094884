import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import Paper from '@material-ui/core/Paper';
import { intlType, userContainerType } from '../types';
import LoadingWrapper from '../components/loading/LoadingWrapper';
import LabelValue from '../components/containers/LabelValue';
import styles from './UserProfile.module.scss';
import timeUtils from '../utils/timeUtils';
import { getParsedToken } from '../utils/userUtils';

/*
TODO: Currently this is just a test page that displays the user as JSON.
 For Phase 1, remove the link to it from the UserSection.
 */
const UserProfile = ({ userContainer, intl }) => {
  const { user } = userContainer;
  const tokenData = getParsedToken();
  const [now, setNow] = React.useState(new Date());

  if (!user && !userContainer.isLoading && !userContainer.error) {
    return <Redirect to="/" />;
  }

  useEffect(() => {
    const interval = setInterval(() => setNow(new Date()), 1000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  const userObject = user || {};
  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'userProfile' })}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Paper elevation={3} classes={{ root: styles.container }}>
        <LoadingWrapper isLoading={userContainer.isLoading} error={userContainer.error} errorMessageId="signInError">
          <h2 className={styles.heading}>{`${userObject.firstName} ${userObject.lastName}`}</h2>
          <p className={styles.email}>{userObject.email}</p>
          <LabelValue labelId="channels" value={userObject.channels} />
          <LabelValue labelId="admin" value={!!userObject.isAdmin} />
          <LabelValue labelId="producer" value={!!userObject.isProducer} />

          {/* TODO: P1: Hide these fields after the token refresh logic is done and tested */}
          {tokenData && (
            <>
              <p className={styles.muted}>{`Token expires in: ${timeUtils.formatDuration(tokenData.expDate.getTime() - now.getTime())}`}</p>
              <p className={styles.muted}>{`Token issued: ${tokenData.iatDate.toISOString()}`}</p>
              <p className={styles.muted}>{`Token expiry: ${tokenData.expDate.toISOString()}`}</p>
            </>
          )}
        </LoadingWrapper>
      </Paper>
    </>
  );
};

UserProfile.propTypes = {
  userContainer: userContainerType,
  intl: intlType.isRequired
};

UserProfile.defaultProps = {
  userContainer: {}
};

const mapStateToProps = (state) => ({
  userContainer: state.user
});

export default connect(mapStateToProps, {})(injectIntl(UserProfile));
