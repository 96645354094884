export const contains = (array, obj) => {
  if (!array) {
    return false;
  }
  return array.indexOf(obj) >= 0;
};

export const isEmpty = (array) => (!array || array.length === 0);

export const isSameValues = (array1, array2) => {
  if (!array1 && !array2) {
    return true;
  }
  if (!array1 || !array2) {
    return false;
  }
  if (array1.length !== array2.length) {
    return false;
  }
  return [...array1].sort().toString() === [...array2].sort().toString();
};
