// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import urlUtils from './urlUtils';
import searchUtils from './searchUtils';
import { apiUrls, routes } from '../constants';
import { logMessage } from './logUtils';
import localStorageUtils from './localStorageUtils';
import { utcSecondsToDate } from './dateUtils';
import { msToMin } from './timeUtils';

export const getParsedToken = () => {
  const token = localStorageUtils.getUserToken();
  if (!token) {
    return undefined;
  }
  const tokenData = jwt_decode(token);
  const iatDate = utcSecondsToDate(tokenData.iat);
  const expDate = utcSecondsToDate(tokenData.exp);

  return {
    ...tokenData,
    iatDate,
    expDate
  };
};

export const isGuestToken = () => {
  const tokenData = getParsedToken();
  return tokenData ? !tokenData.idp : undefined;
};

export const getChannelsFromToken = () => {
  const tokenData = getParsedToken();
  return tokenData ? tokenData.channels : [];
};

export const getTokenLifetimeMin = () => {
  const tokenData = getParsedToken();
  if (!tokenData) {
    return undefined;
  }
  const { expDate, iatDate } = tokenData;
  return msToMin(expDate.getTime() - iatDate.getTime());
};

const getDefaultAuthProvider = () => {
  const tokenData = getParsedToken();
  return tokenData ? tokenData.idp : undefined;
};

const getDefaultLocation = () => (
  window
    ? window.location
    : { pathname: '/', search: '' }
);

/*
Flow
=============
- Redirect user to MT with authProvider and redirectURL in params
- MT redirect to appropriate login page for the auth provider
- Login page redirects back to MT
- MT gets the auth token, and redirects to FE redirectURL with token in the URL params
- This token can be used to authenticate API calls

Call with no params to redirect to a sign in flow based on the current token.
 */
export const signIn = (authProvider = getDefaultAuthProvider(), location = getDefaultLocation()) => {
  const { loginUrlRoot } = apiUrls;
  const state = btoa(location.pathname + location.search);
  const redirectUri = `${window.location.origin}/login_callback?appstate=${state}`;

  // Note: To test this, the redirect URI must be https. Start the server with: "set HTTPS=true&&npm start"
  if (!urlUtils.isHttps(redirectUri)) {
    throw new Error('Auth cannot work if redirectUri is not HTTPS');
  }
  if (!loginUrlRoot) {
    throw new Error('Login URL not defined. Check environment variables.');
  }

  const redirectUriEnc = encodeURIComponent(redirectUri);
  const loginUrl = `${loginUrlRoot}/${authProvider}?post_login_redirect_url=${redirectUriEnc}`;

  logMessage(`Redirecting to loginUrl: ${loginUrl}`);
  window.location = loginUrl;
};

/**
 * Redirect to the sign out flow.
 *
 * @param token The current user token
 * @param logoutIdp Set to true to log out of the IDP entirely, false to just log out of Webcasting Centre
 */
export const signOut = (logoutIdp) => {
  const token = localStorageUtils.getUserToken();
  const { logoutUrlRoot } = apiUrls;
  const redirectUri = window.location.origin;

  if (!urlUtils.isHttps(redirectUri)) {
    throw new Error('Auth cannot work if redirectUri is not HTTPS');
  }
  if (!logoutUrlRoot) {
    throw new Error('Logout URL not defined. Check environment variables.');
  }

  const redirectUriEnc = encodeURIComponent(redirectUri);
  const logoutUrl = `${logoutUrlRoot}/?token=${token}&post_logout_redirect_url=${redirectUriEnc}&logout_idp=${!!logoutIdp}`;

  // Clear token before sign-out, so App.js does not try to fetch user upon redirect back to Webcasting Centre
  localStorageUtils.clearUserToken();
  logMessage(`Redirecting to logoutUrl: ${logoutUrl}`);
  window.location = logoutUrl;
};

/**
 * Returns true if a user has successfully signed in, is not a guest user, and has no errors.
 *
 * @param userContainer
 * @returns {boolean|undefined}
 */
export const isUserSignedIn = (userContainer) => {
  if (!userContainer || userContainer.isLoading) {
    return undefined;
  }
  return !!(userContainer.user && !userContainer.user.isGuest && !userContainer.error);
};

const channelToSearchUrl = (channel) => (
  routes.results + searchUtils.searchParamsToUrl({
    filters: {
      audience: [channel]
    }
  })
);

export const getOwnedChannelsList = (user) => (user.ownedChannels || []).map((channel) => channel.id);

// Returns an array containing both channels I own (ownedChannels) and channels shared with me (channels)
export const getAllUserChannels = (user) => {
  if (!user) {
    return [];
  }
  const channels = user.channels || [];
  const ownedChannels = getOwnedChannelsList(user);
  // Remove duplicates
  const allChannelsSet = new Set([
    ...channels,
    ...ownedChannels
  ]);
  return [...allChannelsSet];
};

export const userHasChannels = (user) => (getAllUserChannels(user).length > 0);

export const userChannelsToCardData = (user) => (
  getAllUserChannels(user).map((channel) => ({
    name: channel,
    url: channelToSearchUrl(channel)
  }))
);
