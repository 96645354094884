import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Lock } from '@material-ui/icons';
import { connect } from 'react-redux';
import SignInButton from '../user/SignInButton';
import Button from '../buttons/Button';
import styles from './VideoRequestAccess.module.scss';
import { requestAccess } from '../../store/actions/videoActions';
import { accessRequestType } from '../../types';
import InlineError from '../error/InlineError';

const VideoRequestAccess = ({
  videoId,
  accessRequest,
  isUserSignedIn,
  requestAccessAction
}) => (
  <div className={styles.container}>
    <Lock className={styles.lockIcon} />
    <h3 className={styles.heading}><FormattedMessage id="privateWebcast" /></h3>
    {(isUserSignedIn) ? (
      <>
        <p><FormattedMessage id="privateWebcast_signedIn" /></p>
        {(accessRequest.isSent) && (
          <p><FormattedMessage id="requestAccess_sent" /></p>
        )}
        {(accessRequest.error) && (
          <InlineError messageId="requestAccess_error" error={accessRequest.error} />
        )}
        {(!accessRequest.isSent && !accessRequest.error) && (
          <Button
            labelId="requestAccess"
            onClick={() => requestAccessAction(videoId)}
            disabled={accessRequest.isSending}
          />
        )}
      </>
    ) : (
      <>
        <p><FormattedMessage id="privateWebcast_signedOut" /></p>
        <SignInButton />
      </>
    )}
  </div>
);

VideoRequestAccess.propTypes = {
  videoId: PropTypes.string.isRequired,
  accessRequest: accessRequestType.isRequired,
  isUserSignedIn: PropTypes.bool,
  requestAccessAction: PropTypes.func.isRequired
};

VideoRequestAccess.defaultProps = {
  isUserSignedIn: false
};

const mapStateToProps = (state) => ({
  accessRequest: state.video.accessRequest
});

const mapDispatchToProps = {
  requestAccessAction: requestAccess
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoRequestAccess);
