import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { userContainerType } from '../../types';
import styles from './UserDetails.module.scss';
import Image from '../containers/Image';
import profileIcon from '../../assets/images/ContentCreator-Icon.svg';

const UserDetails = ({ userContainer, className }) => {
  const { user } = userContainer;
  if (!user) {
    return null;
  }
  return (
    <div className={`${styles.container} ${className}`}>
      <Image className={styles.profileIcon} src={profileIcon} altId="userProfileIcon" />
      <div className={styles.userContainer}>
        <div className={styles.userName}>
          {`${user.firstName} ${user.lastName}`}
        </div>
        <div className={styles.email}>
          {user.email}
        </div>
      </div>
    </div>
  );
};

UserDetails.propTypes = {
  userContainer: userContainerType.isRequired,
  className: PropTypes.string
};

UserDetails.defaultProps = {
  className: ''
};

const mapStateToProps = (state) => ({
  userContainer: state.user
});

export default connect(mapStateToProps, {})(UserDetails);
