import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import SearchBox from 'components/search/SearchBox';
import LanguageSelector from 'components/language/LanguageSelector';
import { links } from '../../constants';
import IconButton from '../buttons/IconButton';
import TextLink from '../containers/TextLink';
import Image from '../containers/Image';
import otnlogo from '../../assets/images/otn-logo.svg';
import SignOutButton from '../user/SignOutButton';
import UserMenu from '../user/UserMenu';
import SignInButton from '../user/SignInButton';
import UserDetails from '../user/UserDetails';
import styles from './Header.module.scss';

const Header = () => {
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);
  // TODO: The dark treatment of the header is no longer used. If we 100% won't ever need it, refactor it out entirely.
  const isDark = false; // isDarkHeader(location);
  const theme = isDark ? 'dark' : 'normal';

  return (
    <header role="banner" className={`${styles.container} ${isDark ? styles.containerDark : ''}`}>
      <a href="/" className={styles.homeLink}>
        <Image src={otnlogo} altId="otnLogo" width="86" height="33" />
      </a>
      <div className={`${styles.searchContainer} ${styles.desktopOnly}`}>
        <SearchBox theme={theme} />
      </div>
      <TextLink
        className={`${styles.menuItem} ${styles.desktopOnly}`}
        src={links.help}
        srcFr={links.helpFr}
        labelId="menuHelp"
        newTab
      />
      <LanguageSelector className={`${styles.menuItem} ${styles.desktopOnly}`} theme={theme} />
      <div className={`${styles.userMenuItem} ${styles.desktopOnly}`}>
        <UserMenu theme={theme} />
      </div>

      <div className={`${styles.menuItem} ${styles.mobileOnly}`}>
        <IconButton icon={MenuIcon} padding="none" titleId="menu" onClick={() => setDrawerOpen(true)} />
      </div>

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
        className={`${styles.mobileOnly}`}
        classes={{
          paper: styles.drawer
        }}
      >
        <div className={`${styles.headerRowMobile} ${styles.closeContainer}`}>
          <IconButton icon={CloseIcon} padding="none" titleId="menuClose" onClick={() => setDrawerOpen(false)} />
        </div>
        <UserDetails className={styles.headerRowMobile} />
        <SearchBox className={styles.searchBoxMobile} onSearch={() => setDrawerOpen(false)} />
        <SignInButton className={styles.signInMobile} variant="outlined" />
        <TextLink className={styles.headerRowMobile} src="/" labelId="menuHelp" />
        <LanguageSelector className={styles.headerRowMobile} showTooltip={false} />
        <SignOutButton className={styles.headerRowMobile} />
      </Drawer>
    </header>
  );
};

export default Header;
