import {
  LOCALE_SWITCH
} from '../actionTypes';
import localStorageUtils from '../../utils/localStorageUtils';

const setLocaleAction = (locale) => ({
  type: LOCALE_SWITCH,
  payload: {
    locale
  }
});

// eslint-disable-next-line import/prefer-default-export
export const setLocale = (locale) => (dispatch) => {
  localStorageUtils.setLocale(locale);
  dispatch(setLocaleAction(locale));
};
