import React from 'react';
import PropTypes from 'prop-types';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import Slider from 'react-slick';
import IconButton from '../buttons/IconButton';
// CSS import order matters. Our styles must override the default ones
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './Carousel.module.scss';

const ArrowButton = ({ variant, className, onClick }) => {
  const isNext = variant === 'next';
  const icon = isNext ? KeyboardArrowRight : KeyboardArrowLeft;
  const btnClassName = isNext ? styles.btnNext : styles.btnPrev;
  const titleId = isNext ? 'carouselNext' : 'carouselPrevious';
  const isDisabled = className ? className.indexOf('slick-disabled') >= 0 : false;
  return (
    <IconButton
      icon={icon}
      className={btnClassName}
      titleId={titleId}
      onClick={onClick}
      disabled={isDisabled}
      variant="text"
      tabIndex="-1"
      ariaHidden
    />
  );
};

ArrowButton.propTypes = {
  variant: PropTypes.oneOf(['prev', 'next']).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string
};

ArrowButton.defaultProps = {
  onClick: undefined,
  className: undefined
};

const Carousel = ({ children, childrenPerPage }) => (
  <Slider
    arrows
    dots
    infinite={false}
    className={styles.container}
    dotsClass={`slick-dots ${styles.dots}`}
    slidesToShow={childrenPerPage}
    slidesToScroll={childrenPerPage}
    prevArrow={<ArrowButton variant="prev" />}
    nextArrow={<ArrowButton variant="next" />}
    customPaging={(i) => (<button type="button" tabIndex="-1" aria-hidden>{i}</button>)}
  >
    {children}
  </Slider>
);

Carousel.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  childrenPerPage: PropTypes.number
};

Carousel.defaultProps = {
  childrenPerPage: 4
};

export default Carousel;
