import React, { Component } from 'react';
import { childrenType } from '../../types';
import InlineError from './InlineError';

/**
 * Error Boundaries: https://reactjs.org/docs/error-boundaries.html
 *
 * Note: In Development an Error Overlay will be shown with the stacktrace. To see this ErrorBoundary, press Esc
 */
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null
    };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo
    });
    // You can also log error messages to an error reporting service here
  }

  // TODO: Provide options for the user (like clicking a link to go back or to the Home page)
  render() {
    const { children } = this.props;
    const { error, errorInfo } = this.state;

    return error
      ? <InlineError messageId="errorGeneric" error={error} errorInfo={errorInfo} />
      : children;
  }
}

ErrorBoundary.propTypes = {
  children: childrenType.isRequired
};

export default ErrorBoundary;
