import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Presenter from './Presenter';
import styles from './PresenterSection.module.scss';
import { presentersContainerType } from '../../types';
import LoadingWrapper from '../loading/LoadingWrapper';

const PresenterSection = ({
  displayStyle,
  headingId,
  presenterContainer,
  max
}) => {
  const {
    isLoading,
    error,
    list
  } = presenterContainer;

  let backgroundClass;
  if (displayStyle === 'light') {
    backgroundClass = styles.light;
  } else if (displayStyle === 'dark') {
    backgroundClass = styles.dark;
  }

  return (
    <section className={`${styles.container} ${backgroundClass}`}>
      <div className={styles.innerContainer}>
        <div className={styles.headingContainer}>
          <h2><FormattedMessage id={headingId} /></h2>
        </div>
        <LoadingWrapper isLoading={isLoading} error={error} errorMessageId="presentersError">
          <div className={styles.cardContainer}>
            {list && list.slice(0, max).map((presenter) => (
              <Presenter key={presenter.presenter} presenter={presenter} />
            ))}
          </div>
        </LoadingWrapper>
      </div>
    </section>
  );
};

PresenterSection.propTypes = {
  displayStyle: PropTypes.oneOf(['light', 'dark']),
  headingId: PropTypes.string.isRequired,
  presenterContainer: presentersContainerType.isRequired,
  max: PropTypes.number
};

PresenterSection.defaultProps = {
  displayStyle: 'light',
  max: null
};

export default PresenterSection;
