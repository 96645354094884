import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { facetValueType, filtersType } from 'types';
import { contains, isSameValues } from 'utils/arrayUtils';
import { searchVideoAddFacetFilter, searchVideoRemoveFacetFilter, searchVideoApplyFilters } from 'store/actions/searchActions';
import FacetFilterValue from './FacetFilterValue';
import FilterContainer from './FilterContainer';

const FacetFilter = ({
  fieldName,
  values,
  filters,
  formFilters,
  addFacetAction,
  removeFacetAction,
  searchVideoApplyFiltersAction,
  onApply
}) => {
  const history = useHistory();
  const setValues = formFilters[fieldName];
  const isAnyValueSet = !!(setValues && setValues.length > 0);

  const onChangeFilter = (event, value) => {
    if (event.target.checked) {
      addFacetAction(fieldName, value);
    } else {
      removeFacetAction(fieldName, value);
    }
  };

  if (values.length === 0) {
    return null;
  }

  const isValueChanged = () => (
    !isSameValues(formFilters[fieldName], filters[fieldName])
  );

  const handleApply = () => {
    searchVideoApplyFiltersAction([fieldName], history);
    onApply();
  };

  // Sort facet values by count first, then alphabetically
  const sortValues = (toSort) => toSort.sort((el1, el2) => (
    (el1.count !== el2.count)
      ? el1.count < el2.count
      : el1.value < el2.value
  ));

  const validFacet = () => {
    if (fieldName !== 'expiryType') {
      return (
        <FilterContainer
          fieldName={fieldName}
          defaultExpanded={isAnyValueSet}
          showApply={isValueChanged()}
          onApply={handleApply}
        >
          {sortValues(values).map((facetValue) => (
            <FacetFilterValue
              key={facetValue.value}
              facetKey={fieldName}
              facetValue={facetValue}
              onChange={(event) => onChangeFilter(event, facetValue.value)}
              isSelected={contains(formFilters[fieldName], facetValue.value)}
            />
          ))}
        </FilterContainer>
      );
    }

    return null;
  };

  return (
    validFacet()
  );
};

FacetFilter.propTypes = {
  fieldName: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(facetValueType).isRequired,
  filters: filtersType.isRequired,
  formFilters: filtersType.isRequired,
  addFacetAction: PropTypes.func.isRequired,
  removeFacetAction: PropTypes.func.isRequired,
  searchVideoApplyFiltersAction: PropTypes.func.isRequired,
  onApply: PropTypes.func
};

FacetFilter.defaultProps = {
  onApply: () => {}
};

const mapStateToProps = (state) => {
  const { searchParams, searchParamsForm } = state.search;
  return {
    filters: searchParams.filters,
    formFilters: searchParamsForm.filters
  };
};

const mapDispatchToProps = {
  addFacetAction: searchVideoAddFacetFilter,
  removeFacetAction: searchVideoRemoveFacetFilter,
  searchVideoApplyFiltersAction: searchVideoApplyFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(FacetFilter);
