import * as dashCloneDeep from 'lodash/cloneDeep';

export default {
  isEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  },

  isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  },

  /**
   * Filters an object's properties, returning only the properties with the given names
   *
   * @param obj The object to filter
   * @param propNames List of property names to keep in the object
   * @returns {{}}
   */
  filterProps(obj, propNames) {
    const filtered = {};
    propNames.forEach((prop) => {
      if (obj[prop]) {
        filtered[prop] = obj[prop];
      }
    });
    return filtered;
  }
};

export const isContainerNotInitialized = (container) => (
  !container || (
    container.list === null
    && !container.isLoading
    && container.error === null
  )
);

export const cloneDeep = (obj) => dashCloneDeep(obj);
