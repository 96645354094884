import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import styles from './Badge.module.scss';
import Image from '../containers/Image';
import liveIcon from '../../assets/images/LiveOnly-Event-Icon.svg';
import liveArchivedIcon from '../../assets/images/LiveArchived-Event-Icon.svg';
import lockClosedIcon from '../../assets/images/Lock-Closed-Icon.svg';
import lockOpenIcon from '../../assets/images/Lock-Open-Icon.svg';
import cancelledIcon from '../../assets/images/Cancelled-Event-Icon.svg';

const Badge = ({ type }) => {
  const icons = {
    live: liveIcon,
    live_archived: liveArchivedIcon,
    cancelled: cancelledIcon,
    privateLocked: lockClosedIcon,
    privateUnlocked: lockOpenIcon
  };
  const iconUrl = icons[type];
  if (!iconUrl) {
    return null;
  }
  const labelCode = (type === 'privateLocked' || type === 'privateUnlocked') ? 'private' : type;

  return (
    <div className={styles.container}>
      <Image className={styles.icon} altId={`badgeIcon_${labelCode}`} src={iconUrl} ariaHidden />
      <span className={styles.label}><FormattedMessage id={`badgeLabel_${labelCode}`} /></span>
    </div>
  );
};

Badge.propTypes = {
  type: PropTypes.oneOf(['live', 'live_archived', 'privateLocked', 'privateUnlocked', 'cancelled']).isRequired
};

export default Badge;
