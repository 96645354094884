import axios from 'axios';
import localStorageUtils from '../../utils/localStorageUtils';
import { logError, logMessage } from '../../utils/logUtils';
import { apiUrls, tokenRefreshThresholdMin } from '../../constants';
import { getParsedToken } from '../../utils/userUtils';
import { minToMs } from '../../utils/timeUtils';

/**
 * @param url
 * @param urlParams
 * @returns {Promise<unknown>}
 */
export const apiGet = (url, urlParams) => new Promise((resolve, reject) => {
  const config = {
    params: urlParams
  };

  axios.get(url, config)
    .then((res) => {
      logMessage(`API success. URL: ${url}`, res);
      resolve(res.data);
    })
    .catch((err) => {
      logError(`API error. URL: ${url}`, err);
      reject(err);
    });
});

export const apiPost = (url, data) => new Promise((resolve, reject) => {
  const config = {};

  axios.post(url, data, config)
    .then((res) => {
      logMessage(`API success. URL: ${url}`, res);
      resolve(res.data);
    })
    .catch((err) => {
      logError(`API error. URL: ${url}`, err);
      reject(err);
    });
});

let isRefreshingToken = false;

const refreshToken = () => {
  isRefreshingToken = true;
  apiGet(`${apiUrls.root}authentication/refresh_token`)
    .then(({ token }) => {
      logMessage('Token refreshed');
      localStorageUtils.setUserToken(token);
      isRefreshingToken = false;
    })
    .catch((err) => {
      logError('Token refresh failed:', err);
      localStorageUtils.clearUserToken();
      isRefreshingToken = false;
    });
};

const refreshTokenIfNeeded = () => {
  const tokenData = getParsedToken();
  const isAboutToExpire = (tokenData && tokenData.expDate)
    ? ((tokenData.expDate.getTime() - new Date().getTime()) < minToMs(tokenRefreshThresholdMin))
    : false;
  if (isAboutToExpire && !isRefreshingToken) {
    refreshToken();
  }
};

const isLogViewStartRequest = (config) => (
  config
  && config.method === 'post'
  && config.url.indexOf('/api/video/') >= 0
  && config.url.indexOf('/statistics') >= 0
  && config.data
  && config.data.view === true
);

axios.interceptors.request.use(
  (config) => {
    const token = localStorageUtils.getUserToken();
    const newConfig = {
      ...config
    };
    if (token) {
      newConfig.headers.otn_webcast_token = token;
      /*
      If user starts video, force a token refresh. That way the token refresh requests as you watch will always happen
       before the token expires. WEBCAST-400
       */
      if (isLogViewStartRequest(config)) {
        refreshToken();
      } else {
        refreshTokenIfNeeded();
      }
    }
    return newConfig;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    if (response && response.status === 401 && response.data === 'invalid token') {
      // Don't reload multiple times in the case of multiple simultaneous requests
      if (localStorageUtils.getUserToken()) {
        localStorageUtils.clearUserToken();
        window.location.reload();
      }
      // TODO: P1: This is a bad hack to prevent the error from flashing on the page before it has time to reload. Fix properly.
      return new Promise((resolve, reject) => {
        setTimeout(() => reject(error), 3000);
      });
    }
    return Promise.reject(error);
  }
);
