import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as MuiTooltip } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { intlType } from '../../types';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '14px'
      }
    }
  }
});

const Tooltip = ({
  titleId,
  open,
  children,
  intl,
  placement
}) => (
  <MuiThemeProvider theme={theme}>
    <MuiTooltip
      title={intl.formatMessage({ id: titleId })}
      open={open}
      placement={placement}
    >
      {children}
    </MuiTooltip>
  </MuiThemeProvider>
);

Tooltip.propTypes = {
  titleId: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]).isRequired,
  open: PropTypes.bool,
  intl: intlType.isRequired,
  placement: PropTypes.string
};

Tooltip.defaultProps = {
  open: undefined,
  placement: 'bottom'
};

export default injectIntl(Tooltip);
