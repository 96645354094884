import React from 'react';
import PropTypes from 'prop-types';
import Image from '../containers/Image';
import { selectThumbnail } from '../../utils/videoUtils';

// TODO: Consider a fallback url in case the thumbnail fails to load
const VideoThumbnail = ({
  srcList,
  className,
  videoName
}) => (
  <Image
    src={selectThumbnail(srcList)}
    altId="videoThumbnail"
    altValues={{ videoName }}
    className={className}
    ariaHidden
  />
);

VideoThumbnail.propTypes = {
  srcList: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  videoName: PropTypes.string.isRequired
};

VideoThumbnail.defaultProps = {
  className: ''
};

export default VideoThumbnail;
