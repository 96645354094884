import React from 'react';
import { FormattedMessage } from 'react-intl';
import heroImg from '../../assets/images/Hero-image.jpg';
import styles from './MarketingBanner.module.scss';
import Image from '../containers/Image';

const MarketingBanner = () => (
  <section className={styles.container}>
    <Image className={styles.heroImg} src={heroImg} altId="heroAlt" />
    <div className={styles.textContainer}>
      <div className={styles.bodyContainer}>
        <h1><FormattedMessage id="marketingBannerHeading" /></h1>
        <p><FormattedMessage id="marketingBannerBody" /></p>
      </div>
    </div>
  </section>
);

export default MarketingBanner;
