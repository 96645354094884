import {
  NOTIFICATION_ADD,
  NOTIFICATION_HIDE,
  NOTIFICATION_REMOVE
} from 'store/actionTypes';

const initialState = {
  lastId: 0,
  notifications: []
};

const applyNotificationAdd = (state, action) => {
  const { lastId } = state;
  const { messageId, severity } = action.payload;
  const newLastId = lastId + 1;

  return {
    ...state,
    lastId: newLastId,
    notifications: [...state.notifications, {
      id: newLastId,
      isOpen: true,
      messageId,
      severity
    }]
  };
};

const applyNotificationHide = (state, action) => {
  const { id } = action.payload;
  const newNotifications = state.notifications.map((notification) => (
    (notification.id !== id)
      ? notification
      : {
        ...notification,
        isOpen: false
      }));

  return {
    ...state,
    notifications: newNotifications
  };
};

const applyNotificationRemove = (state, action) => {
  const { id } = action.payload;

  return {
    ...state,
    notifications: state.notifications.filter((notification) => notification.id !== id)
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_ADD:
      return applyNotificationAdd(state, action);
    case NOTIFICATION_HIDE:
      return applyNotificationHide(state, action);
    case NOTIFICATION_REMOVE:
      return applyNotificationRemove(state, action);
    default:
      return state;
  }
};
