import React, { Component } from 'react';
import { FileCopyRounded } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { addNotification } from 'store/actions/notificationsActions';
import { connect } from 'react-redux';
import IconButton from '../buttons/IconButton';
import styles from './ErrorDetails.module.scss';
import { errorInfoType, errorType } from '../../types';
import AxiosError from './AxiosError';
import { logError } from '../../utils/logUtils';

class ErrorDetails extends Component {
  copyDetails() {
    const { error, errorInfo, addNotificationAction } = this.props;
    const data = JSON.stringify({
      error,
      errorInfo
    });

    navigator.clipboard.writeText(data).then(() => {
      addNotificationAction('copySuccess', 'success');
    }).catch((err) => {
      logError('Failed to copy text to clipboard.', err);
      addNotificationAction('copyFailure', 'error');
    });
  }

  render() {
    const { error, errorInfo } = this.props;

    return (
      <>
        <div className={styles.container}>
          <div className={styles.header}>
            <IconButton
              icon={FileCopyRounded}
              size="small"
              padding="none"
              onClick={() => this.copyDetails()}
              titleId="copy"
            />
          </div>
          <div className={styles.body}>
            {error && (
              <p>{error.toString()}</p>
            )}
            {error && error.isAxiosError && (
              <AxiosError error={error} />
            )}
            {errorInfo && (
              <pre>{errorInfo.componentStack}</pre>
            )}
          </div>
        </div>
      </>
    );
  }
}

ErrorDetails.propTypes = {
  error: errorType.isRequired,
  errorInfo: errorInfoType, // Passed in by ErrorBoundary in case of unexpected error
  addNotificationAction: PropTypes.func.isRequired
};

ErrorDetails.defaultProps = {
  errorInfo: undefined
};

export default connect(null, { addNotificationAction: addNotification })(ErrorDetails);
