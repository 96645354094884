import React from 'react';
import { errorTypeAxios } from '../../types';
import urlUtils from '../../utils/urlUtils';

const AxiosError = ({ error }) => (
  <>
    <pre>{`Request: ${error.config.method} ${error.config.url}${urlUtils.paramsToString(error.config.params)}`}</pre>
    <pre>{`Headers: ${JSON.stringify(error.config.headers)}`}</pre>
    <pre>{error.stack}</pre>
  </>
);

AxiosError.propTypes = {
  error: errorTypeAxios.isRequired
};

export default AxiosError;
