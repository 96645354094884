import React from 'react';
import { connect } from 'react-redux';
import { matchType, userType, videoType } from '../../types';
import { isEditAllowed, isQuestionAllowed, isShareAllowed } from '../../utils/videoUtils';
import Button from '../buttons/Button';
import { buildMailToLink } from '../../utils/urlUtils';
import { links } from '../../constants';
import ShareButton from '../buttons/ShareButton';
import styles from './VideoButtons.module.scss';

const VideoButtons = ({ video, user, match }) => {
  const { askAQuestionEmail, name } = video;
  return (
    <div className={styles.container}>
      {isQuestionAllowed(video) && (
        <Button
          variant="primary"
          labelId="askAQuestion"
          href={buildMailToLink(askAQuestionEmail, `Ask A Question: ${name}`)}
          className={styles.button}
        />
      )}
      <Button
        variant="primary"
        labelId="menuHelp"
        href={links.help}
        hrefFr={links.helpFr}
        className={styles.button}
        newTab
      />
      {isEditAllowed(video, user) && (
        <Button
          labelId="videoEdit"
          to={`${match.url}/edit`}
          className={styles.button}
        />
      )}
      {isShareAllowed(video, user) && (
        <ShareButton
          video={video}
          className={styles.button}
        />
      )}
    </div>
  );
};

VideoButtons.propTypes = {
  video: videoType.isRequired,
  user: userType,
  match: matchType.isRequired
};

VideoButtons.defaultProps = {
  user: null
};

const mapStateToProps = (state) => ({
  user: state.user.user
});

export default connect(mapStateToProps, {})(VideoButtons);
