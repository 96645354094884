import React from 'react';
import { PlayCircleOutline } from '@material-ui/icons';
import { suggestionType } from '../../types';
import { maxLength } from '../../constants';
import Text from '../containers/Text';
import TextWithEm from '../containers/TextWithEm';
import styles from './SearchOption.module.scss';

const SearchOption = ({ option }) => {
  const videoId = option.id;

  return ((videoId) ? (
    <div className={styles.videoSuggestion}>
      <PlayCircleOutline fontSize="small" className={styles.icon} />
      <span className={styles.title}><Text value={option.description} maxLength={maxLength.videoName} /></span>
      <span className={styles.details}><TextWithEm value={option.text} /></span>
    </div>
  ) : (
    <span><TextWithEm value={option.text} /></span>
  ));
};

SearchOption.propTypes = {
  option: suggestionType.isRequired
};

export default SearchOption;
