import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideAndRemoveNotification } from 'store/actions/notificationsActions';
import { notificationType } from 'types';
import Notification from './Notification';

/**
 * Shows notification "snackbars" based on Redux state. To use, dispatch an action.
 *
 * Note: Notifications are always shown one at a time per Material UI spec.
 *
 * @param notifications
 * @param hideNotificationAction
 * @returns {*}
 * @constructor
 */
const Notifications = ({ notifications, hideNotificationAction }) => (
  <>
    {notifications.notifications.map((notification) => (
      <Notification
        key={notification.id}
        open={notification.isOpen}
        messageId={notification.messageId}
        severity={notification.severity}
        onClose={() => hideNotificationAction(notification.id)}
      />
    ))}
  </>
);

Notifications.propTypes = {
  notifications: PropTypes.shape({
    notifications: PropTypes.arrayOf(notificationType).isRequired
  }).isRequired,
  hideNotificationAction: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { notifications } = state;

  return {
    notifications
  };
};

export default connect(mapStateToProps, { hideNotificationAction: hideAndRemoveNotification })(Notifications);
