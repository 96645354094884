import {
  USER_FETCH_START,
  USER_FETCH_SUCCESS,
  USER_FETCH_FAILURE,
  USER_UPDATE_START,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE
} from '../actionTypes';
import userService from '../services/userService';
import localStorageUtils from '../../utils/localStorageUtils';
import { routes } from '../../constants';
import { logError } from '../../utils/logUtils';
import { getChannelsFromToken, isGuestToken } from '../../utils/userUtils';

const fetchUserStart = () => ({
  type: USER_FETCH_START
});

const fetchUserSuccess = (user) => ({
  type: USER_FETCH_SUCCESS,
  payload: {
    user
  }
});

const fetchGuestUserSuccess = () => ({
  type: USER_FETCH_SUCCESS,
  payload: {
    user: {
      isGuest: true,
      channels: getChannelsFromToken()
    }
  }
});

const fetchUserFailure = (error) => ({
  type: USER_FETCH_FAILURE,
  payload: {
    error
  }
});

const updateUserStart = () => ({
  type: USER_UPDATE_START
});

const updateUserSuccess = (channels) => ({
  type: USER_UPDATE_SUCCESS,
  payload: {
    user: {
      channels
    }
  }
});

const updateUserFailure = (error) => ({
  type: USER_UPDATE_FAILURE,
  payload: {
    error
  }
});

/**
 * Called whenever a token changes in the app. Saves the user token in localstorage and fetches the user details using
 * this token.
 *
 * @param token
 * @returns {function(...[*]=)}
 */
export const fetchUser = (token) => (dispatch, getState) => {
  localStorageUtils.setUserToken(token);

  if (getState().user.isLoading) {
    logError('User already loading...');
    return;
  }

  if (isGuestToken()) {
    dispatch(fetchGuestUserSuccess());
    return;
  }

  dispatch(fetchUserStart());
  userService.getMyUser()
    .then((result) => dispatch(fetchUserSuccess(result)))
    .catch((err) => {
      localStorageUtils.clearUserToken();
      dispatch(fetchUserFailure(err));
    });
};

/**
 * - Adds channel to the user profile (by code)
 * - Updates the user profile with the new channels list
 * - Redirects to the video view page.
 *
 * @param channelCode
 * @param videoId
 * @param history
 * @returns {function(...[*]=)}
 */
export const addChannelToProfile = (channelCode, videoId, history) => (dispatch) => {
  dispatch(updateUserStart());
  userService.addChannelToProfile(channelCode)
    .then((result) => {
      dispatch(updateUserSuccess(result));
      history.push(`${routes.videos}/${videoId}`);
    })
    .catch((err) => dispatch(updateUserFailure(err)));
};

/**
 * - Exchanges channel code for guest access token
 * - Saves access token in local storage
 * - Sets guest user in redux state
 * - Redirects to the video view page.
 *
 * @param channelCode
 * @param videoId
 * @param history
 * @returns {function(...[*]=)}
 */
export const guestSignIn = (channelCode, videoId, history) => (dispatch) => {
  dispatch(fetchUserStart());
  userService.guestSignIn(channelCode)
    .then(({ token }) => {
      localStorageUtils.setUserToken(token);
      dispatch(fetchGuestUserSuccess());
      history.push(`${routes.videos}/${videoId}`);
    })
    .catch((err) => dispatch(fetchUserFailure(err)));
};
