import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  matchType,
  userType,
  videoContainerType
} from 'types';
import { fetchVideo } from 'store/actions/videoActions';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import { Helmet } from 'react-helmet';
import { isEditAllowed } from '../utils/videoUtils';
import VideoEditor from '../components/videoEditor/VideoEditor';
import Button from '../components/buttons/Button';
import { logMessage } from '../utils/logUtils';
import VideoDetailsFull from '../components/videos/VideoDetailsFull';
import styles from './Edit.module.scss';

class Edit extends Component {
  componentDidMount() {
    this.fetchVideoIfNeeded();
  }

  componentDidUpdate() {
    this.fetchVideoIfNeeded();
  }

  fetchVideoIfNeeded() {
    const {
      fetchVideoAction,
      match,
      video
    } = this.props;
    const urlVideoId = match.params.videoId;
    if (video.currentVideoId !== urlVideoId) {
      fetchVideoAction(urlVideoId);
    }
  }

  render() {
    const {
      video: {
        isLoading,
        currentVideo,
        error
      },
      user
    } = this.props;

    const videoId = currentVideo ? currentVideo.id : null;

    if (currentVideo && !isEditAllowed(currentVideo, user)) {
      logMessage('Edit not allowed.');
      return <Redirect to={`/videos/${videoId}`} />;
    }

    /*
    TODO: Phase 2: There is no UX for this page, so this is just a proof of concept
     Features to add:
      - Set Listed / Unlisted
      - Preview
      - Start / Stop / Cancel event
     */
    return (
      <>
        {currentVideo && (
          <Helmet>
            <title>{currentVideo.name}</title>
            <meta name="description" content={currentVideo.description} />
            <meta name="robots" content="noindex, nofollow" />
          </Helmet>
        )}

        <LoadingWrapper isLoading={isLoading} error={error} errorMessageId="videoLoadError">
          <div className={styles.detailsContainer}>
            <h2>Edit Video</h2>
            <div>
              <Button to={`/videos/${videoId}`} labelId="videoWatch" />
            </div>
            {currentVideo && (
              <>
                <VideoEditor video={currentVideo} />
                <VideoDetailsFull video={currentVideo} />
              </>
            )}
          </div>
        </LoadingWrapper>
      </>
    );
  }
}

Edit.propTypes = {
  fetchVideoAction: PropTypes.func.isRequired,
  match: matchType.isRequired,
  video: videoContainerType.isRequired,
  user: userType
};

Edit.defaultProps = {
  user: null
};

const mapStateToProps = (state) => ({
  video: state.video,
  user: state.user.user
});

export default connect(mapStateToProps, {
  fetchVideoAction: fetchVideo
})(Edit);
