import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { intlType } from 'types';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-CA';
import frLocale from 'date-fns/locale/fr-CA';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { dateFormat } from '../../constants';

const DatePicker = ({
  date,
  onChange,
  labelId,
  ariaLabelId,
  intl
}) => (
  <MuiPickersUtilsProvider locale={(intl.locale === 'en-CA') ? enLocale : frLocale} utils={DateFnsUtils}>
    <KeyboardDatePicker
      autoOk
      disableToolbar
      variant="inline"
      format={dateFormat}
      margin="normal"
      label={intl.formatMessage({ id: labelId })}
      value={date}
      onChange={onChange}
      KeyboardButtonProps={{
        'aria-label': intl.formatMessage({ id: ariaLabelId })
      }}
      inputProps={{
        'aria-label': intl.formatMessage({ id: ariaLabelId })
      }}
    />
  </MuiPickersUtilsProvider>
);

DatePicker.propTypes = {
  date: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  labelId: PropTypes.string.isRequired,
  ariaLabelId: PropTypes.string.isRequired,
  intl: intlType.isRequired
};

DatePicker.defaultProps = {
  date: null
};

export default injectIntl(DatePicker);
