import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '../containers/Tooltip';
import styles from './Loading.module.scss';

const Loading = () => (
  <div className={styles.container}>
    <Tooltip titleId="loading">
      <CircularProgress classes={{ root: styles.animation }} />
    </Tooltip>
  </div>
);

export default Loading;
