import React from 'react';
import PropTypes from 'prop-types';
import timeUtils from 'utils/timeUtils';
import { injectIntl } from 'react-intl';
import { intlType } from '../../types';
import styles from './Duration.module.scss';

const Duration = ({
  start,
  end,
  id,
  intl
}) => {
  const duration = timeUtils.getDurationStr(start, end);
  const ariaLabel = intl.formatMessage({
    id: 'durationLabel'
  }, {
    duration
  });

  return (
    <div className={styles.container} id={id} aria-label={ariaLabel}>
      {duration}
    </div>
  );
};

Duration.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  id: PropTypes.string,
  intl: intlType.isRequired
};

Duration.defaultProps = {
  id: ''
};

export default injectIntl(Duration);
