import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button as MuiButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import loadingImg from '../../assets/images/loading.gif';
import { iconType } from '../../types';
import { localeFrCa } from '../../constants';
import styles from './Button.module.scss';

/*
 * If the button should render as a link, pass in "to".
 * If it should call a function, pass in "onClick".
 */
const Button = ({
  to,
  href,
  hrefFr,
  onClick,
  variant,
  labelId,
  icon,
  className,
  visible,
  disabled,
  ariaHidden,
  newTab,
  locale,
  loading
}) => {
  if (!visible) {
    return null;
  }

  /*
  This uses "composition" to achieve the look & feel of Material UI with the functionality of a routing library.
  Details: https://material-ui.com/guides/composition/#routing-libraries
   */
  const component = to ? Link : undefined;
  const IconTag = icon;
  const isPrimary = (variant === 'primary');
  const isSecondary = (variant === 'secondary');
  let buttonClassName = className;
  let muiVariant;
  if (variant === 'primary') {
    muiVariant = 'outlined';
  } else if (variant === 'secondary') {
    muiVariant = 'secondary';
  } else {
    muiVariant = variant;
  }

  const outlinedClass = `${styles.container} ${isPrimary ? styles.primary : ''}`;
  const secondaryClass = `${styles.container} ${isSecondary ? styles.secondary : ''}`;

  if (loading) {
    buttonClassName = styles.imgBtn;
  }

  return (
    <MuiButton
      variant={muiVariant}
      onClick={onClick}
      to={to}
      href={(locale === localeFrCa && hrefFr) ? hrefFr : href}
      component={component}
      classes={{
        label: styles.label,
        outlined: outlinedClass,
        secondary: secondaryClass
      }}
      className={buttonClassName}
      disabled={disabled}
      aria-hidden={ariaHidden}
      target={newTab ? '_blank' : undefined}
      rel={newTab ? 'noopener noreferrer' : undefined}
    >
      {icon && <IconTag fontSize="small" className={styles.icon} />}
      {labelId && <FormattedMessage id={labelId} />}
      {loading && <img src={loadingImg} alt={loadingImg} className={styles.imgSize} />}
    </MuiButton>
  );
};

Button.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  hrefFr: PropTypes.string,
  onClick: PropTypes.func,
  labelId: PropTypes.string,
  icon: iconType,
  variant: PropTypes.oneOf(['primary', 'outlined', 'text', 'secondary']),
  className: PropTypes.string,
  visible: PropTypes.bool,
  disabled: PropTypes.bool,
  ariaHidden: PropTypes.bool,
  newTab: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  loading: PropTypes.bool
};

Button.defaultProps = {
  to: undefined,
  href: undefined,
  hrefFr: undefined,
  onClick: undefined,
  variant: 'outlined',
  className: '',
  icon: undefined,
  visible: true,
  disabled: false,
  ariaHidden: undefined,
  newTab: undefined,
  labelId: undefined,
  loading: undefined
};

const mapStateToProps = (state) => ({
  locale: state.locale.locale
});

export default connect(mapStateToProps, null)(injectIntl(Button));
