/* HOT TOPICS */
export const HOT_TOPICS_FETCH = 'HOT_TOPICS_FETCH';
export const HOT_TOPICS_FETCH_SUCCESS = 'HOT_TOPICS_FETCH_SUCCESS';
export const HOT_TOPICS_FETCH_FAILURE = 'HOT_TOPICS_FETCH_FAILURE';

/* LOCALE */
export const LOCALE_SWITCH = 'LOCALE_SWITCH';

/* NOTIFICATIONS */
export const NOTIFICATION_ADD = 'NOTIFICATION_ADD';
export const NOTIFICATION_HIDE = 'NOTIFICATION_HIDE';
export const NOTIFICATION_REMOVE = 'NOTIFICATION_REMOVE';

/* SEARCH */
// TODO Consider renaming prefix from SEARCH_VIDEO to SEARCH
export const SEARCH_VIDEO_CHANGE_QUERY = 'SEARCH_VIDEO_CHANGE_QUERY';
export const SEARCH_VIDEO_APPLY_QUERY = 'SEARCH_VIDEO_APPLY_QUERY';
export const SEARCH_VIDEO_SUGGESTIONS_SUCCESS = 'SEARCH_VIDEO_SUGGESTIONS_SUCCESS';
export const SEARCH_VIDEO_SUGGESTIONS_FAILURE = 'SEARCH_VIDEO_SUGGESTIONS_FAILURE';
export const SEARCH_VIDEO_CHANGE_FILTER = 'SEARCH_VIDEO_CHANGE_FILTER';
export const SEARCH_VIDEO_APPLY_FILTERS = 'SEARCH_VIDEO_APPLY_FILTERS';
export const SEARCH_VIDEO_CLEAR_FILTERS = 'SEARCH_VIDEO_CLEAR_FILTERS';
export const SEARCH_VIDEO = 'SEARCH_VIDEO';
export const SEARCH_VIDEO_SUCCESS = 'SEARCH_VIDEO_SUCCESS';
export const SEARCH_VIDEO_FAILURE = 'SEARCH_VIDEO_FAILURE';
export const SEARCH_VIDEO_NEXT_PAGE = 'SEARCH_VIDEO_NEXT_PAGE';
export const SEARCH_VIDEO_NEXT_PAGE_SUCCESS = 'SEARCH_VIDEO_NEXT_PAGE_SUCCESS';
export const SEARCH_VIDEO_NEXT_PAGE_FAILURE = 'SEARCH_VIDEO_NEXT_PAGE_FAILURE';
export const SEARCH_VIDEO_ORDER = 'SEARCH_VIDEO_ORDER';
export const SEARCH_VIDEO_FACET_ADD = 'SEARCH_VIDEO_FACET_ADD';
export const SEARCH_VIDEO_FACET_REMOVE = 'SEARCH_VIDEO_FACET_REMOVE';

/* POPULAR_PRESENTERS */
export const POPULAR_PRESENTERS_FETCH = 'POPULAR_PRESENTERS_FETCH';
export const POPULAR_PRESENTERS_FETCH_SUCCESS = 'POPULAR_PRESENTERS_FETCH_SUCCESS';
export const POPULAR_PRESENTERS_FETCH_FAILURE = 'POPULAR_PRESENTERS_FETCH_FAILURE';

/* SUGGESTIONS */
export const SUGGESTIONS_FETCH = 'SUGGESTIONS_FETCH';
export const SUGGESTIONS_FETCH_SUCCESS = 'SUGGESTIONS_FETCH_SUCCESS';
export const SUGGESTIONS_FETCH_FAILURE = 'SUGGESTIONS_FETCH_FAILURE';

/* USER */
export const USER_FETCH_START = 'USER_FETCH_START';
export const USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS';
export const USER_FETCH_FAILURE = 'USER_FETCH_FAILURE';
export const USER_UPDATE_START = 'USER_UPDATE_START';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';

/* VIDEO */
export const VIDEO_FETCH = 'VIDEO_FETCH';
export const VIDEO_FETCH_SUCCESS = 'VIDEO_FETCH_SUCCESS';
export const VIDEO_FETCH_FAILURE = 'VIDEO_FETCH_FAILURE';
export const VIDEO_UPDATE = 'VIDEO_UPDATE';
export const VIDEO_UPDATE_SUCCESS = 'VIDEO_UPDATE_SUCCESS';
export const VIDEO_UPDATE_FAILURE = 'VIDEO_UPDATE_FAILURE';
export const VIDEO_SIMILAR_FETCH = 'VIDEO_SIMILAR_FETCH';
export const VIDEO_SIMILAR_FETCH_SUCCESS = 'VIDEO_SIMILAR_FETCH_SUCCESS';
export const VIDEO_SIMILAR_FETCH_FAILURE = 'VIDEO_SIMILAR_FETCH_FAILURE';
export const VIDEO_REQUEST_ACCESS = 'VIDEO_REQUEST_ACCESS';
export const VIDEO_REQUEST_ACCESS_SUCCESS = 'VIDEO_REQUEST_ACCESS_SUCCESS';
export const VIDEO_REQUEST_ACCESS_FAILURE = 'VIDEO_REQUEST_ACCESS_FAILURE';
export const VIDEO_EXTEND = 'VIDEO_EXTEND';
export const VIDEO_EXTEND_REQUESTED = 'VIDEO_EXTEND_REQUESTED';
export const VIDEO_EXTEND_SUCCESS = 'VIDEO_EXTEND_SUCCESS';
export const VIDEO_EXTEND_FAILURE = 'VIDEO_EXTEND_FAILURE';
export const VIDEO_EXTEND_CANCEL = 'VIDEO_EXTEND_CANCEL';
export const VIDEO_EXTEND_COMPLETE = 'VIDEO_EXTEND_COMPLETE';
export const VIDEO_FETCH_ACCESS_TIER_STATUS = 'VIDEO_FETCH_ACCESS_TIER_STATUS';
export const VIDEO_FETCH_ACCESS_TIER_STATUS_SUCCESS = 'VIDEO_FETCH_ACCESS_TIER_STATUS_SUCCESS';
export const VIDEO_FETCH_ACCESS_TIER_STATUS_FAILURE = 'VIDEO_FETCH_ACCESS_TIER_STATUS_FAILURE';
