import React from 'react';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Collapse } from '@material-ui/core';
import { videoType } from '../../types';
import LabelValue from '../containers/LabelValue';
import VideoDetails from './VideoDetails';
import Button from '../buttons/Button';
import LinkArray from '../containers/LinkArray';
import styles from './VideoDetailsFull.module.scss';

const VideoDetailsFull = ({ video }) => {
  const [isExpanded, setExpanded] = React.useState(false);
  const {
    id,
    hostSite,
    hostCity,
    theraputicAreaOfCare,
    participantFee,
    additionalInstructions,
    eventAttachments
  } = video;

  return (
    <>
      <VideoDetails video={video} />
      <Collapse in={isExpanded}>
        <div className={styles.container}>
          <LabelValue labelId="eventId" value={id} />
          <LabelValue labelId="hostSite" value={hostSite} />
          <LabelValue labelId="hostCity" value={hostCity} />
          <LabelValue labelId="therapeuticArea" value={theraputicAreaOfCare} />
          <LabelValue labelId="participantFee" value={participantFee} />
          <LabelValue labelId="registrationInstructions" value={additionalInstructions} />
          <LabelValue
            labelId="supportingMaterials"
            value={(eventAttachments && eventAttachments.length > 0) ? <LinkArray array={eventAttachments} /> : null}
          />
        </div>
      </Collapse>
      {isExpanded
        ? (
          <Button
            variant="text"
            labelId="seeLess"
            icon={ExpandLess}
            onClick={() => setExpanded(false)}
          />
        )
        : (
          <Button
            variant="text"
            labelId="seeMore"
            icon={ExpandMore}
            onClick={() => setExpanded(true)}
          />
        )}
    </>
  );
};

VideoDetailsFull.propTypes = {
  video: videoType.isRequired
};

export default VideoDetailsFull;
