import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  intlType,
  userContainerType,
  videoContainerType,
  extendRequestType
} from 'types';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import ExpiryError from 'components/error/ExpiryError';
import AzureMP from '../azureMp/AzureMP';
import { getTokenLifetimeMin, isUserSignedIn } from '../../utils/userUtils';
import {
  localeEnCa,
  videoStatIntervalMin,
  videoTokenRefreshThresholdMin
} from '../../constants';
import VideoThumbnailOverlay from './VideoThumbnailOverlay';
import VideoCountdown from './VideoCountdown';
import {
  addTokenToStreamSource,
  buildTextTracks,
  isCountdownShown,
  isPlaceholderShown,
  isLive,
  getMsToStart,
  selectThumbnail
} from '../../utils/videoUtils';
import { stringToDate } from '../../utils/dateUtils';
import VideoRequestAccess from './VideoRequestAccess';
import VideoThumbnail from './VideoThumbnail';
import { updateCurrentVideo } from '../../store/actions/videoActions';
import { minToSec } from '../../utils/timeUtils';
import styles from './VideoPlayer.module.scss';
import VideoExtend from './VideoExtend';

/*
Process:
 - >15 mins before the start of the event, show the thumbnail in place of the video player
 - <=15 mins before the start of the event, show this countdown and the video player
  -> Before the event start we won’t have a valid stream (startBroadcast boolean will be false)
  -> The stream in the video object will be a generic OTN commercial
  -> While startBroadcast is false, poll the GET /video/{id}, and check if startBroadcast became true
  -> As soon as it's true, update the video player source with the new sources and start playing it (if possible)

- TODO: P2: Note AMP also supports the notion of a "Poster" feed it a URL for the thumbnail and AMP will render it
 in the player before the user hits Play.
 */
const VideoPlayer = ({
  videoContainer,
  userContainer,
  currentTime,
  onStart,
  onResume,
  onPlaybackState,
  onFullScreenState,
  onDurationChanged,
  onTimeUpdate,
  locale,
  updateCurrentVideoAction,
  intl,
  extendRequest
}) => {
  const video = videoContainer.currentVideo;
  const [now, setNow] = React.useState(new Date());
  // eslint-disable-next-line no-unused-vars
  const [streamNotReady, setStreamNotReady] = React.useState(null);

  if (!video) {
    return null;
  }

  const { accessLevel } = video;
  const noAccess = !accessLevel || accessLevel === 'readDescription' || accessLevel === 'none';

  const updateTimeAndVideo = () => {
    setNow(new Date());
    const msToStart = getMsToStart(video);
    if (isCountdownShown(video, msToStart)) {
      updateCurrentVideoAction();
    }
  };

  useEffect(() => {
    const interval = (isLive(video) && !video.startBroadCast && !noAccess)
      ? setInterval(updateTimeAndVideo, 1000)
      : null;

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  const {
    id,
    streamSource,
    contentToken,
    thumbnailSource,
    name,
    closedCaptionsVtt,
    adUrl,
    skipAdOffset,
    language,
    type,
    expiryType
  } = video;

  const extendRequestParam = extendRequest;

  if (extendRequestParam.isRequested) {
    return (
      <VideoThumbnailOverlay videoName={name} thumbnailSource={thumbnailSource}>
        <VideoExtend
          videoId={id}
          extendDate={moment().add(2, 'years').format()}
          expiryType={expiryType}
        />
      </VideoThumbnailOverlay>
    );
  }

  if (extendRequestParam.error) {
    return (
      <ExpiryError />
    );
  }

  if (expiryType === 'expired' || expiryType === 'restoring') {
    return (
      <VideoThumbnail
        srcList={thumbnailSource}
        videoName={name}
        className={styles.thumbnail}
      />
    );
  }

  if (noAccess && type !== 'cancelled') {
    return (
      <VideoThumbnailOverlay videoName={name} thumbnailSource={thumbnailSource}>
        <VideoRequestAccess
          videoId={id}
          isUserSignedIn={isUserSignedIn(userContainer)}
        />
      </VideoThumbnailOverlay>
    );
  }

  /*
  If we're in countdown mode, show the thumbnail. Once done, show the player.
   */
  const startDate = stringToDate(video.scheduledStart);
  const msToStart = startDate.getTime() - now.getTime();
  if (isPlaceholderShown(video) || streamNotReady) {
    return (
      (isCountdownShown(video, msToStart) || streamNotReady) ? (
        <VideoThumbnailOverlay videoName={name} thumbnailSource={thumbnailSource}>
          <VideoCountdown
            startDate={startDate}
            msToStart={msToStart}
          />
        </VideoThumbnailOverlay>
      ) : (
        <VideoThumbnail
          srcList={thumbnailSource}
          videoName={name}
          className={styles.thumbnail}
        />
      )
    );
  }

  const src = addTokenToStreamSource(streamSource, contentToken);
  const videoLanguage = language || 'en';
  const captionLabelId = (videoLanguage === 'en') ? 'languageEnglish' : 'languageFrench';
  const captionLabel = intl.formatMessage({ id: captionLabelId });
  const textTracks = buildTextTracks(closedCaptionsVtt, videoLanguage, captionLabel);
  const playerLanguage = (locale === localeEnCa) ? 'en' : 'fr';
  const options = {
    autoplay: true,
    poster: selectThumbnail(thumbnailSource),
    playbackSpeed: {
      enabled: true,
      initialSpeed: 1.0,
      speedLevels: [
        { name: 'x2.0', value: 2.0 },
        { name: 'x1.5', value: 1.5 },
        { name: 'x1.0', value: 1.0 },
        { name: 'x0.5', value: 0.5 }
      ]
    }
  };

  let srcProp;
  if (adUrl) {
    options.ampAds = {
      preRoll: {
        sourceUri: adUrl,
        options: {
          skipAd: {
            enabled: true,
            offset: skipAdOffset || 1
          }
        }
      },
      mainProgram: {
        source: src,
        tracks: textTracks
      }
    };
  } else {
    srcProp = src;
  }

  if (window && window.innerHeight) {
    // Cannot control AMP with relative width and height; Hence height is set as absolute;
    // TODO: See if it's possible to update this on resize
    const height = Math.max(300, window.innerHeight - 250);
    options.height = `${height}px`;
  }

  // Periodic calls to the MT to keep the token alive
  let timeUpdateIntervalMin;
  if (onTimeUpdate) {
    // Note: Token will be refreshed as soon as video plays, so an update interval based on the token lifetime works (WEBCAST-400)
    const tokenLifetimeMin = getTokenLifetimeMin();
    timeUpdateIntervalMin = (tokenLifetimeMin)
      ? Math.max(1, (tokenLifetimeMin - videoTokenRefreshThresholdMin))
      : videoStatIntervalMin;
  }

  /*
  WEBCAST-396: If the stream is not yet ready, AMP may throw an error, where err.message contains "no chunks in the
  live manifest". If this is the case, set "streamNotReady" to true to show the loading indicator again and retry later.
   */
  const onError = (err) => {
    console.error('AMP: Error event: ', err);
    /*
    TODO: P1: This was removed, because it was causing the retry logic at the end for a live event, when a stream became
    invalid again. Need to revisit this when there's time to test.
     */
    /*
    if (err.message && err.message.indexOf('no chunks in the live manifest' >= 0)) {
      setStreamNotReady(true);
      setTimeout(() => setStreamNotReady(null), streamErrorRetryInterval);
    }
     */
  };

  return (
    <div className={styles.container}>
      <AzureMP
        src={srcProp}
        textTracks={textTracks}
        currentTime={currentTime}
        onStart={onStart}
        onResume={onResume}
        onError={onError}
        onPlaybackState={onPlaybackState}
        onFullScreenState={onFullScreenState}
        onDurationChanged={onDurationChanged}
        onTimeUpdate={onTimeUpdate}
        timeUpdateIntervalSec={minToSec(timeUpdateIntervalMin)}
        language={playerLanguage}
        options={options}
      />
    </div>
  );
};

VideoPlayer.propTypes = {
  videoContainer: videoContainerType,
  userContainer: userContainerType,
  currentTime: PropTypes.number,
  onStart: PropTypes.func,
  onResume: PropTypes.func,
  onPlaybackState: PropTypes.func,
  onFullScreenState: PropTypes.func,
  onDurationChanged: PropTypes.func,
  onTimeUpdate: PropTypes.func,
  locale: PropTypes.string.isRequired,
  updateCurrentVideoAction: PropTypes.func.isRequired,
  intl: intlType.isRequired,
  extendRequest: extendRequestType.isRequired
};

VideoPlayer.defaultProps = {
  videoContainer: {},
  userContainer: {},
  currentTime: undefined,
  onStart: undefined,
  onResume: undefined,
  onPlaybackState: undefined,
  onFullScreenState: undefined,
  onDurationChanged: undefined,
  onTimeUpdate: undefined
};

const mapStateToProps = (state) => ({
  locale: state.locale.locale,
  videoContainer: state.video,
  userContainer: state.user,
  extendRequest: state.video.extendRequest
});

const mapDispatchToProps = {
  updateCurrentVideoAction: updateCurrentVideo
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(VideoPlayer));
