import React from 'react';
import PropTypes from 'prop-types';
import { channelType } from 'types';
import Carousel from 'components/containers/Carousel';
import Channel from './Channel';

const ChannelCarousel = ({ channelList, channelsPerPage }) => (
  <Carousel childrenPerPage={channelsPerPage}>
    {channelList && channelList.map((channel) => (
      <Channel
        key={channel.name}
        channel={channel}
      />
    ))}
  </Carousel>
);

ChannelCarousel.propTypes = {
  channelList: PropTypes.arrayOf(channelType).isRequired,
  channelsPerPage: PropTypes.number.isRequired
};

export default ChannelCarousel;
