import {
  LOCALE_SWITCH
} from 'store/actionTypes';
import { getPreferredLocale } from '../../utils/localeUtils';

const initialState = {
  locale: getPreferredLocale()
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOCALE_SWITCH:
      return {
        ...state,
        locale: action.payload.locale
      };
    default:
      return state;
  }
};
