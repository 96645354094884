import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { channelType } from '../../types';
import { isEmpty } from '../../utils/arrayUtils';
import ChannelCarousel from './ChannelCarousel';
import hooks from '../../hooks';
import stringUtils from '../../utils/stringUtils';
import { cssVariables } from '../../constants';
import styles from './ChannelSection.module.scss';

const ChannelSection = ({ headingId, dataList, hideIfEmpty }) => {
  const [width] = hooks.useWindowSize();

  if (hideIfEmpty && isEmpty(dataList)) {
    return null;
  }

  const screenSizeSmall = stringUtils.sizePxToNumber(cssVariables.screenSizeSmall);
  const screenSizeMedium = stringUtils.sizePxToNumber(cssVariables.screenSizeMedium);
  const screenSizeLarge = stringUtils.sizePxToNumber(cssVariables.screenSizeLarge);

  let channelsPerPage = 4;
  if (width < screenSizeSmall) {
    channelsPerPage = 1;
  } else if (width < screenSizeMedium) {
    channelsPerPage = 2;
  } else if (width < screenSizeLarge) {
    channelsPerPage = 3;
  }

  const sortedDataList = [...dataList];
  sortedDataList.sort((a, b) => ((`${a.name}`).localeCompare(b.name)));

  return (
    <section className={`${styles.container}`}>
      <h2 className={styles.heading}>
        <FormattedMessage id={headingId} />
      </h2>
      <ChannelCarousel channelList={sortedDataList} channelsPerPage={channelsPerPage} />
    </section>
  );
};

ChannelSection.propTypes = {
  headingId: PropTypes.string.isRequired,
  dataList: PropTypes.arrayOf(channelType).isRequired,
  hideIfEmpty: PropTypes.bool
};

ChannelSection.defaultProps = {
  hideIfEmpty: false
};

export default ChannelSection;
