import messagesEnCa from 'messages/en-CA.json';
import messagesFrCa from 'messages/fr-CA.json';
import otnLogo from '../assets/images/otn-logo.svg';
import googleLogo from '../assets/images/google-logo.svg';
import microsoftLogo from '../assets/images/microsoft-logo.svg';
import linkedinLogo from '../assets/images/linkedin-logo.svg';

/*
API Paths:
 - If we're running a development build, we use the relative api path (defined in /public/config.js) which is proxied by
  create-react-app per config in package.json. (https://create-react-app.dev/docs/proxying-api-requests-in-development/)
 - If we're running a prod build, we use the absolute api path (config.js will be overwritten on the server at deploy
  time) with CORS on the MT set to accept all requests. This is done since currently the FE is deployed under a different
  domain than the api.

TODO: Ultimately, we need to deploy the FE and API under the same domain, and use the relative path for both scenarios.
 */
const getWindowEnv = () => ((window && window.ENV) ? window.ENV : {});

export const apiUrls = {
  root: getWindowEnv().API_PATH,
  loginUrlRoot: getWindowEnv().LOGIN_URL,
  logoutUrlRoot: getWindowEnv().LOGOUT_URL
};

export const allSortOptions = [{
  fieldName: null,
  labelId: 'sortOrder_relevance'
}, {
  fieldName: 'scheduledStart asc',
  labelId: 'sortOrder_scheduledStartAsc'
}, {
  fieldName: 'scheduledStart desc',
  labelId: 'sortOrder_scheduledStartDesc'
}, {
  fieldName: 'name asc',
  labelId: 'sortOrder_title'
}, {
  fieldName: 'viewCount desc',
  labelId: 'sortOrder_viewCount'
}];

export const apiThrottleDelay = 500;

export const authProviderOtn = {
  code: 'otn',
  labelId: 'signIn_otn',
  logoUrl: otnLogo
};

export const authProviders = [{
  code: 'google',
  labelId: 'signIn_google',
  logoUrl: googleLogo
}, {
  code: 'aad',
  labelId: 'signIn_microsoft',
  logoUrl: microsoftLogo
}, {
  code: 'linkedin',
  labelId: 'signIn_linkedIn',
  logoUrl: linkedinLogo
}];

/*
TODO: P2: This should be imported from variables.scss, but it has stopped working (likely after update to
 react-scripts 4.0.0). Need to fix it properly. Meanwhile this is a temporary solution.

 Used to work using: "import variables from '../../assets/variables.scss';"
 */
export const cssVariables = {
  screenSizeLarge: '933px',
  screenSizeMedium: '768px',
  screenSizeSmall: '600px'
};

export const dateFormat = 'dd/MM/yyyy';

export const defaultPageSize = 10;
export const defaultNumSuggestions = 8;

export const filterSeparator = ',';
export const filterValueSeparator = '|';

export const googleAnalyticsCode = 'UA-48074808-4';

export const homeMaxCards = 4;

export const links = {
  help: 'https://otn.ca/webcasting-help',
  helpFr: 'https://otn.ca/fr/page-daide-du-centre-de-webdiffusion/',
  otnCorporate: 'https://otn.ca',
  otnHub: 'https://otnhub.ca/',
  policy: 'https://support.otn.ca/sites/default/files/webcasting_policy.pdf',
  policyFr: 'https://support.otn.ca/sites/default/files/webcasting_policy_fr.pdf',
  acceptableUse: 'https://support.otn.ca/sites/default/files/webcasting_acceptable_use.pdf',
  acceptableUseFr: 'https://support.otn.ca/sites/default/files/webcasting_acceptable_use_fr.pdf',
  email: 'mailto: webcasting@otn.ca'
};

export const locales = [{
  code: 'en-CA',
  nameId: 'languageEnglish',
  messages: messagesEnCa
}, {
  code: 'fr-CA',
  nameId: 'languageFrench',
  messages: messagesFrCa
}];

export const localeEnCa = locales[0].code;
export const localeFrCa = locales[1].code;

export const maxLength = {
  altText: 100,
  facetValue: 23,
  presenters: 50,
  searchQuery: 70,
  videoName: 40,
  videoNameLong: 60
};

export const ontarioHealthTwitter = '@OntarioHealthOH';

export const routes = {
  results: '/results',
  videos: '/videos'
};

export const severityOptions = ['error', 'warning', 'info', 'success'];

export const showCountdownMinFromStart = 15;

export const streamErrorRetryInterval = 10000;

export const suggestionIds = {
  upcomingLiveEvents: 'upcomingLiveEvents',
  myPrivateWebcasts: 'myPrivateWebcasts',
  topWebcasts: 'topWebcasts'
};

// Refresh token if API request is made within X minutes before expiry
export const tokenRefreshThresholdMin = 5;

// List of facet keys that have their values translated. All others will be displayed as-is.
export const translatedFacetKeys = ['accredited', 'type'];

export const translatedFacetValues = ['expiring', 'expired'];

// When watching a video, send view stats at this interval
export const videoStatIntervalMin = 15;

// When watching a video, refresh token with N mins till expiry. This overrides videoStatIntervalMin and must be less than tokenRefreshThresholdMin
export const videoTokenRefreshThresholdMin = 2;
